import React from "react";
// Components
import VerifySession from "../../../config/verifySession";
import AdminValidation from "../../../config/adminValidation";
import IntermediateManagement from "../../../components/hospitalsManagement/sections/intermediateController";

const IntermediateManagementView = () => (
  <>
    {VerifySession()}
    {AdminValidation()}
    <IntermediateManagement />
  </>
);

export default IntermediateManagementView;
