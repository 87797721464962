import React from "react";
// Components
import VerifySession from "../../../config/verifySession";
import AdminValidation from "../../../config/adminValidation";
import StationsManagement from "../../../components/hospitalsManagement/sections/stationController";

const CliniciansManagementView = () => (
  <>
    {VerifySession()}
    {AdminValidation()}
    <StationsManagement />
  </>
);

export default CliniciansManagementView;
