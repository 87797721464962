import styled from "styled-components";

export const GraphicContainer = styled.div`
  margin: 20px auto;
  padding: 20px;
  width: ${({ width }) => width};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 5px 15px rgb(0 0 0 / 10%);
  transition: all 0.5s ease-in-out;
  @media (max-width: 910px) {
    width: 85%;
    padding: 10px;
  }
`;
export const GraphicRotateDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: all 0.5s ease-in-out;
`;
export const GraphicTitle = styled.p`
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 600;
  color: #000;
  text-align: center;
  @media (max-width: 910px) {
    font-size: 14px;
  }
  @media (max-width: 490px) {
    font-size: 12px;
  }
`;
export const AxisAndGraphicDiv = styled.div`
  width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const AxisYSizeDiv = styled.div`
  margin-left: 5px;
  width: 18px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;
export const AxisY = styled.p`
  width: 200px;
  font-size: 14px;
  color: #666;
  text-align: center;
  transform: rotate(-90deg);
  position: absolute;
`;
export const GraphicSizeDiv = styled.div`
  width: 95%;
  height: 100%;
  @media (max-width: 800px) {
    width: 92%;
  }
  @media (max-width: 500px) {
    width: 88%;
  }
`;
export const AxisX = styled.p`
  margin-top: 10px;
  padding-left: 28px;
  width: 100%;
  font-size: 14px;
  color: #666;
  display: flex;
  justify-content: center;
`;
export const FilterAxisYDiv = styled.div`
  width: 330px;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
`;
export const FilterAxisYInput = styled.input`
  margin: 0 10px;
`;
