import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
// Constants
import { AdherenceColorLow, AdherenceColorMedium, AdherenceColorHigh, adherenceLevel } from "../../../config/constants";
// Components
import { AdherenceProgressBarDiv, Title, Value } from "./styles";

const AdherenceProgressBar = ({ percentage, prescribedData, trainedData, prescribedDataTitle, trainedDataTitle }) => {
  let formattedPercentage = 0;

  if (percentage) {
    formattedPercentage = isNaN(percentage) ? 0 : Math.round(percentage);
  }

  const backgroundAdherence = () => {
    if (Number(formattedPercentage) < adherenceLevel.lowToMedium) {
      return AdherenceColorLow;
    }
    if (Number(formattedPercentage) >= adherenceLevel.lowToMedium && Number(formattedPercentage) <= adherenceLevel.MediumToHigh) {
      return AdherenceColorMedium;
    }
    if (Number(formattedPercentage) > adherenceLevel.MediumToHigh) {
      return AdherenceColorHigh;
    }
  };

  return (
    <AdherenceProgressBarDiv>
      <CircularProgressbar
        value={formattedPercentage}
        text={`${formattedPercentage}%`}
        styles={buildStyles({
          pathColor: backgroundAdherence(),
          textColor: backgroundAdherence(),
          trailColor: "#d6d6d6",
        })}
      />
      <Title margin="15px 0 0 0">
        {prescribedDataTitle}: <Value>{prescribedData}</Value>
      </Title>
      <Title>
        {trainedDataTitle}: <Value>{trainedData}</Value>
      </Title>
    </AdherenceProgressBarDiv>
  );
};

export default AdherenceProgressBar;
