import React from "react";
// Components
import VerifySession from "../../../../config/verifySession";
import AdminValidation from "../../../../config/adminValidation";
import CreateStationComponent from "../../../../components/hospitalsManagement/sections/stationController/sections/createStation";

const CreateClinicianView = () => (
  <>
    {VerifySession()}
    {AdminValidation()}
    <CreateStationComponent />
  </>
);

export default CreateClinicianView;
