import React from "react";
// Components
import VerifySession from "../../config/verifySession";
import PatientData from "../../components/patientData";

const PatientDataView = () => (
  <>
    {VerifySession()}
    <PatientData />
  </>
);

export default PatientDataView;
