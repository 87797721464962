import styled from "styled-components";
import { primaryColor, primaryColorHover, errorColor, bodyColor } from "../../../../../../config/constants";

export const Title = styled.h2`
  margin: 0 0 20px;
  font-size: 26px;
  text-align: center;
`;
export const ClinicianInfoDiv = styled.div`
  margin: auto;
  width: 100%;
  max-width: 860px;
  padding: 15px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 5px 15px rgb(0 0 0 / 10%);
`;
export const DataDiv = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
  @media (max-width: 910px) {
    flex-direction: column;
    align-items: center;
  }
`;
export const ColumnDataDiv = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 910px) {
    width: 220px;
  }
`;
export const InputDataDiv = styled.div`
  margin-bottom: 10px;
  display: flex;
  align-items: baseline;
  justify-content: flex-end;
  position: relative;
  @media (max-width: 910px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;
export const Label = styled.label`
  margin-right: 15px;
  font-size: 14px;
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : "normal")};
`;
export const Input = styled.input`
  padding: ${({ padding }) => (padding ? padding : "5px 10px")};
  width: 200px;
  border: ${({ error }) => (error ? `1px solid ${errorColor}` : `1px solid ${bodyColor}`)};
  outline: none;
  border-radius: 5px;
  font-size: 14px;
`;
export const Select = styled.select`
  width: 200px;
  height: 30px;
  font-size: 16px;
  border-radius: 5px;
  border: ${({ error }) => (error ? `1px solid ${errorColor}` : `1px solid #000`)};
  outline: none;
  cursor: pointer;
`;
export const RelativeButton = styled.button`
  width: 23px;
  height: 23px;
  position: absolute;
  top: 3px;
  right: 5px;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  z-index: 10;
  @media (max-width: 910px) {
    top: 21px;
    right: 26px;
  }
`;
export const GeneratePasswordButton = styled.button`
  width: 23px;
  height: 23px;
  position: absolute;
  top: 3px;
  right: -30px;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  z-index: 10;
  @media (max-width: 910px) {
    top: 21px;
    right: 26px;
  }
`;
export const SubmitButtonDiv = styled.div`
  margin: 10px 0 0;
  width: 100%;
  display: flex;
  justify-content: center;
`;
export const SubmitButton = styled.button`
  padding: 10px 30px;
  color: #fff;
  background-color: ${primaryColor};
  outline: none;
  border: none;
  text-decoration: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.15s ease-in-out;
  &:hover {
    background-color: ${primaryColorHover};
  }
`;
