import React from "react";
// Components
import VerifySession from "../../config/verifySession";
import Graphics from "../../components/statistics";

const GraphicsView = () => (
  <>
    {VerifySession()}
    <Graphics />
  </>
);

export default GraphicsView;
