// Main path on server
export const pathHomePage = "";

export const WEB_SERVICE_DASHBOARD_ROUTE = `https://rgsweb.eodyne.com/rgsmims/backend/dashboard/src`;

// Mims Colors
export const primaryColor = "#26A9B6";
export const primaryColorHover = "#1A737B";
export const bodyColor = "#E6E6E6";
export const errorColor = "#D9434E";
export const patientCreationPeriodColor = "#FEB416";
export const colorsArray = [
  "#FDF201",
  "#55BA4C",
  "#004FA2",
  "#FF0E4B",
  "#FEB416",
  "#5D2D91",
  "#00AEF0",
  "#00A66C",
  "#F37520",
  "#303092",
  "#EB0089",
  "#A6CE3B",
  "#0072BB",
  "#E8371F",
  "#00A99E",
  "#92278F",
  "#FF6633",
  "#1AFF33",
  "#6666FF",
  "#E6B333",
  "#FF3399",
  "#00CC66",
  "#990099",
  "#FF9966",
  "#0099CC",
  "#CC6699",
  "#33FFCC",
  "#660066",
  "#FFCC33",
  "#339999",
  "#CC3366",
  "#990000",
  "#336699",
  "#CC3300",
  "#FF9999",
  "#66CCFF",
  "#990066",
  "#663366",
  "#FF9966",
  "#CC6600",
  "#009966",
  "#CCCC33",
  "#996666",
  "#FF3366",
  "#66FFCC",
  "#FF6600",
  "#006633",
  "#CCCC00",
  "#666633",
  "#FF3300",
  "#66CC00",
  "#663300",
  "#FF3300",
  "#009933",
  "#993366",
  "#FF3333",
  "#99CC00",
  "#006666",
  "#FF6633",
  "#33CC99",
  "#990033",
];

// Applications
export const applications = {
  clinicId: 1,
  homeId: 2,
  webId: 3,
  appId: 4,
  wearId: 5,
  icuId: 6,
  plusId: 7,
};

// Protocols
export const protocols = {
  clinicAndHome: {
    SPHEROIDS_ID: 10,
    PINBALL_ID: 40,
    HOCKEY_ID: 50,
    CLEAN_THE_TABLE_ID: 60,
    GRASP_AND_PLACE_ID: 90,
    FOLLOW_THE_LINE_ID: 100,
    ROAD_TRIP_ID: 110,
    BRICKS_ID: 120,
    BOAT_ID: 140,
    BUBBLES_ID: 151,
    DEMOLITION_ID: 152,
    CONSTELLATIONS_ID: 80,
    TWISTER_BUDDY: 309,
    GENTLE_GIANT_ID: 311,
  },
  app: {
    basic: {
      MEMOSEQ_APP_ID: 201,
      PLACE_IT_ID: 204,
      GUESS_WHAT_ID: 202,
      PIANOSEQ_ID: 203,
      BLOBS_ID: 214,
      SHOPPING_ID: 215,
      POPURRI_ID: 222,
      TWISTER_ID: 223,
      MEMORY_ID: 224,
      CLEANING_ID: 216,
      CONSTELLATIONS: 229,
      BALLOONS_BASIC: 231,
      QUALITY_CONTROL_BASIC: 232,
    },
    tensorFlow: {
      ARMANOID_ID: 211,
      DRIVING_ID: 217,
    },
    ar: {
      TRAINING_ID: 200,
      SHELVES_ID: 208,
      BUFFET_ID: 210,
      ALPHABET_ID: 205,
      BALLOONS_ID: 206,
      DUCKS_ID: 209,
      SPHEROIDS_AR_ID: 218,
      HOCKEY_AR_ID: 219,
      CIRCLE_AR_ID: 220,
      WHAC_A_MOLE_AR_ID: 221,
      TUBES_ID: 226,
      CLEANING_AR_ID: 225,
      CLEANING_AR_VERTICAL_ID: 227,
      CIRCLE_HORIZONTAL_ID: 228,
      QUALITY_CONTROL_ID: 230,
      CONSTELLATIONS_AR: 233,
    },
  },
  web: {
    MEMOSEQ_WEB_ID: 301,
    MIRROR_WEB_ID: 302,
    RECYCLING_WEB_ID: 303,
    WHACK_A_MOLE_WEB_ID: 304,
    FULL_BODY_ID: 305,
    BALANCE_ID: 306,
    SEQUENCES_ID: 307,
    WORDS_ID: 308,
    TWISTER_BUDDY_ID: 309,
    COSTUME_PARTY_ID: 310,
    GENTLE_GIANT_ID: 311,
  },
};

// Adherence
export const AdherenceColorLow = "#ed1c24";
export const AdherenceColorMedium = "#fbb03b";
export const AdherenceColorHigh = "#39b54a";
export const adherenceLevel = {
  lowToMedium: 50,
  MediumToHigh: 74,
};

// Strack project
export const monthsToCheckPatients = 5; // The deal in Strack is 6 months, but this variable is 5 because of moment.js since this library need una number less
