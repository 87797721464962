import styled from "styled-components";
import { primaryColor, primaryColorHover, bodyColor } from "../../config/constants";

export const LoginContainer = styled.div`
  padding-top: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
`;
export const LoginForm = styled.div`
  width: 290px;
  height: 255px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.15);
  @media (max-width: 690px) {
    margin: 25px auto;
  }
`;
export const LogoMimsImg = styled.img`
  margin: 30px auto 0;
`;
export const LogoSeparator = styled.div`
  margin: 25px 0;
  width: 100%;
  height: 2px;
  background-color: ${bodyColor};
`;
export const Input = styled.input`
  margin-top: 5px;
  padding: 0 10px;
  width: 100%;
  height: 30px;
  font-size: 14px;
  outline: none;
  border: none;
  border-radius: 5px;
  background-color: ${bodyColor};
  -webkit-text-security: disc;
`;
export const Form = styled.form`
  margin: 0 auto;
  width: 70%;
  &:input:focus {
    outline: none;
  }
`;
export const Label = styled.label``;
export const ButtonSubmit = styled.button`
  margin: 20px 0 10px;
  padding: 10px 20px;
  width: 90px;
  border: none;
  font-size: 14px;
  color: #fff;
  background-color: ${primaryColor};
  border-radius: 5px;
  outline: none;
  cursor: pointer;
  transition: all 0.15s ease-in-out;
  &:hover {
    background-color: ${primaryColorHover};
  }
`;
