import styled from "styled-components";
import { primaryColor } from "../../config/constants";

export const AdherenceDiv = styled.div`
  margin: 20px auto;
  padding: 20px;
  width: 100%;
  max-width: 860px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 5px 15px rgb(0 0 0 / 10%);
  transition: all 0.5s ease-in-out;
  @media (max-width: 910px) {
    width: 85%;
    padding: 10px;
  }
`;
export const PositionerTimeButtonsDiv = styled.div`
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media (max-width: 490px) {
    flex-direction: column;
    justify-content: center;
  }
`;
export const TimeButtonsDiv = styled.div`
  width: 250px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const TimeButton = styled.button`
  font-size: 14px;
  font-weight: ${({ active }) => active && "bold"};
  color: ${({ active }) => (active ? "#808080" : primaryColor)};
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
  @media (max-width: 490px) {
    font-size: 12px;
  }
`;
export const SeparatorLine = styled.div`
  width: 2px;
  height: 80%;
  background-color: #e6e6e6;
`;
export const Title = styled.p`
  font-size: 16px;
  color: #000;
  font-weight: 600;
  text-align: center;
  @media (max-width: 910px) {
    font-size: 14px;
  }
  @media (max-width: 490px) {
    font-size: 12px;
  }
`;
export const GraphicsDiv = styled.div`
  margin: 20px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  @media (max-width: 840px) {
    flex-direction: column;
  }
`;
export const DataShowedDescriptionText = styled.p`
  color: #cdcdcd;
  text-align: center;
  @media (max-width: 840px) {
    font-size: 14px;
  }
`;
