import React, { useState, useEffect } from "react";
import moment from "moment";
// Redux
import { useSelector } from "react-redux";
// Constants - Functions
import { WEB_SERVICE_DASHBOARD_ROUTE, applications } from "../../config/constants";
import { setProtocolTitle, setMonth, renderMonthsOptions, renderYearsOptions, setCurrentPeriodOfMonths } from "../../config/functions";
// Components
import {
  Title,
  SoftwareFilterDiv,
  EachSoftwareFilterDiv,
  CheckboxInput,
  CheckboxLabel,
  Select,
  Option,
  PeriodContainer,
  EachPeriodFilterDiv,
  Table,
  TableHospitalTitle,
  TableColumnDataButton,
  HospitalsContainer,
  HospitalContainer,
  HospitalName,
  HospitalMonth,
} from "./styles";
import Header from "../header";
import Loader from "../loader";
import UseFetch from "../../config/useFetch";

const Hospitals = () => {
  const [isLoadingHospitals, setIsLoadingHospitals] = useState(true);
  const [hospitals, setHospitals] = useState([]);
  const [filteredHospitals, setFilteredHospitals] = useState([]);
  const [monthToFilter, setMonthToFilter] = useState(0);
  const [yearToFilter, setYearToFilter] = useState(0);
  const [currentFilterByMonth, setCurrentFilterByMonth] = useState("none");
  const [currentFilterByPeriod, setCurrentFilterByPeriod] = useState("none");
  const [isClinicChecked, setIsClinicChecked] = useState(false);
  const [isHomeChecked, setIsHomeChecked] = useState(false);
  const [isWebChecked, setIsWebChecked] = useState(false);
  const [isAppChecked, setIsAppChecked] = useState(false);
  const [isUkrainianHospitalsChecked, setIsUkrainianHospitalsChecked] = useState(false);
  const [softwareProtocols, setSoftwareProtocols] = useState([]);
  const [isProtocolListDisabled, setIsProtocolListDisabled] = useState(true);
  const [currentProtocol, setCurrentProtocol] = useState(0);
  const [filterStartMonth, setFilterStartMonth] = useState(0);
  const [filterStartYear, setFilterStartYear] = useState(0);
  const [filterEndMonth, setFilterEndMonth] = useState(0);
  const [filterEndYear, setFilterEndYear] = useState(0);
  const [currentPeriodFiltered, setCurrentPeriodFiltered] = useState([]);
  const isLoggedIn = useSelector(({ user }) => user.isLoggedIn);
  const environment = useSelector(({ database }) => database.environment);

  // Fetching hospitals data
  useEffect(() => {
    setIsLoadingHospitals(true);
    const getHospitalsSignal = new AbortController();

    if (isLoggedIn && environment) {
      const body = {
        addUkrainianHospitals: isUkrainianHospitalsChecked,
      };
      UseFetch(
        "post",
        `${WEB_SERVICE_DASHBOARD_ROUTE}/hospital/get-hospital-and-patients-sessions/${environment}`,
        getHospitalsSignal,
        body
      )
        .then(({ data }) => {
          setHospitals(data);
          setIsLoadingHospitals(false);
        })
        .catch((error) => {
          console.log("Get hospitals: ", error);
        });
    }

    return () => {
      setHospitals([]);
      setIsLoadingHospitals(true);
      if (getHospitalsSignal) {
        getHospitalsSignal.abort();
      }
    };
  }, [environment, isLoggedIn, isUkrainianHospitalsChecked]);

  // Setting date filter
  useEffect(() => {
    const initialNumberOfMonthsAgo = 5;
    setFilterStartMonth(Number(moment().subtract(initialNumberOfMonthsAgo, "months").format("MM")));
    setFilterStartYear(Number(moment().subtract(initialNumberOfMonthsAgo, "months").format("YYYY")));
    setFilterEndMonth(Number(moment().format("MM")));
    setFilterEndYear(Number(new Date().getFullYear()));
  }, []);

  // Fetching protocols by software
  useEffect(() => {
    const getProtocolsSignal = new AbortController();

    if (isLoggedIn && environment) {
      setCurrentProtocol(0);
      const activeFilters = [];
      if (isClinicChecked) activeFilters.push(applications.clinicId);
      if (isHomeChecked) activeFilters.push(applications.homeId);
      if (isWebChecked) activeFilters.push(applications.webId);
      if (isAppChecked) activeFilters.push(applications.appId);

      if (activeFilters.length === 1) {
        UseFetch("get", `${WEB_SERVICE_DASHBOARD_ROUTE}/protocol/by-software/${environment}/${activeFilters[0]}`, getProtocolsSignal)
          .then(({ data }) => {
            data.forEach((protocol) => {
              setProtocolTitle(protocol);
            });
            setSoftwareProtocols(data);
          })
          .catch((error) => {
            console.log("Get protocols: ", error);
          });
        setIsProtocolListDisabled(false);
      } else {
        setSoftwareProtocols([]);
        setIsProtocolListDisabled(true);
      }
    }

    return () => {
      setSoftwareProtocols([]);
      setIsProtocolListDisabled(true);
      if (getProtocolsSignal) {
        getProtocolsSignal.abort();
      }
    };
  }, [isLoggedIn, isClinicChecked, isHomeChecked, isWebChecked, isAppChecked, environment]);

  // Setting current period of months and years
  useEffect(() => {
    setCurrentPeriodOfMonths(filterStartMonth, filterStartYear, filterEndMonth, filterEndYear, setCurrentPeriodFiltered);
  }, [filterEndMonth, filterEndYear, filterStartMonth, filterStartYear]);

  // Filtering hospitals data by software and protocol
  useEffect(() => {
    const clonedHospitals = JSON.parse(JSON.stringify(hospitals));
    clonedHospitals.forEach((hospital) => {
      let concatenatedSessions = [];
      let concatenatedPatients = [];

      const handleSetConcatenatedSessionsAndPatients = (software) => {
        const sessions = hospital.sessionsData.filter((session) => session.SOFTWARE === software);
        concatenatedSessions.push(...sessions);
        const patients = hospital.patientsWithSessions.filter((patient) => patient.SOFTWARE === software);
        concatenatedPatients.push(...patients);
      };

      if (isClinicChecked) handleSetConcatenatedSessionsAndPatients("clinic");
      if (isHomeChecked) handleSetConcatenatedSessionsAndPatients("home");
      if (isWebChecked) handleSetConcatenatedSessionsAndPatients("web");
      if (isAppChecked) handleSetConcatenatedSessionsAndPatients("app");

      hospital.sessionsData = concatenatedSessions;
      hospital.patientsWithSessions = concatenatedPatients;
    });

    if (currentProtocol !== 0) {
      clonedHospitals.forEach((hospital) => {
        hospital.sessionsData = hospital.sessionsData.filter((session) => session.PROTOCOL_ID === currentProtocol);
        hospital.patientsWithSessions = hospital.patientsWithSessions.filter((patient) => patient.PROTOCOL_ID === currentProtocol);
      });
    }

    setFilteredHospitals(clonedHospitals);
  }, [isLoggedIn, hospitals, isAppChecked, isClinicChecked, isHomeChecked, isWebChecked, currentProtocol]);

  const isSomeSoftwareFilterActive = () => {
    return isClinicChecked || isHomeChecked || isWebChecked || isAppChecked;
  };

  const renderFiltersByApplication = () => {
    const renderProtocolsByApplication = () => {
      return (
        <Select value={currentProtocol} onChange={(e) => setCurrentProtocol(Number(e.target.value))} disabled={isProtocolListDisabled}>
          <Option value={0}></Option>
          {softwareProtocols.map((protocol) => {
            return (
              <Option key={protocol.PROTOCOL_ID} value={protocol.PROTOCOL_ID}>
                {protocol.NAME}
              </Option>
            );
          })}
        </Select>
      );
    };
    return (
      <SoftwareFilterDiv>
        <EachSoftwareFilterDiv>
          <CheckboxInput
            type="checkbox"
            id="clinicCheckbox"
            checked={isClinicChecked}
            onChange={() => setIsClinicChecked(!isClinicChecked)}
          />
          <CheckboxLabel htmlFor="clinicCheckbox">Clinic</CheckboxLabel>
        </EachSoftwareFilterDiv>
        <EachSoftwareFilterDiv>
          <CheckboxInput type="checkbox" id="homeCheckbox" checked={isHomeChecked} onChange={() => setIsHomeChecked(!isHomeChecked)} />
          <CheckboxLabel htmlFor="homeCheckbox">Home</CheckboxLabel>
        </EachSoftwareFilterDiv>
        <EachSoftwareFilterDiv>
          <CheckboxInput type="checkbox" id="webCheckbox" checked={isWebChecked} onChange={() => setIsWebChecked(!isWebChecked)} />
          <CheckboxLabel htmlFor="webCheckbox">Web</CheckboxLabel>
        </EachSoftwareFilterDiv>
        <EachSoftwareFilterDiv>
          <CheckboxInput type="checkbox" id="appCheckbox" checked={isAppChecked} onChange={() => setIsAppChecked(!isAppChecked)} />
          <CheckboxLabel htmlFor="appCheckbox">App</CheckboxLabel>
        </EachSoftwareFilterDiv>
        {renderProtocolsByApplication()}
      </SoftwareFilterDiv>
    );
  };

  const renderAddUkrainianHospitalsCheckbox = () => {
    const setUkrainianHospitals = () => {
      setIsUkrainianHospitalsChecked(!isUkrainianHospitalsChecked);
    };

    return (
      <SoftwareFilterDiv>
        <CheckboxInput
          type="checkbox"
          id="addUkrainianHospitalsCheckbox"
          checked={isUkrainianHospitalsChecked}
          onChange={setUkrainianHospitals}
        />
        <CheckboxLabel htmlFor="addUkrainianHospitalsCheckbox">Add Ukrainian hospitals</CheckboxLabel>
      </SoftwareFilterDiv>
    );
  };

  const renderPeriodFilter = () => {
    return (
      <PeriodContainer>
        <div>
          <p>From</p>
          <div>
            <Select value={filterStartMonth} onChange={(e) => setFilterStartMonth(Number(e.target.value))}>
              {renderMonthsOptions()}
            </Select>
            <Select value={filterStartYear} onChange={(e) => setFilterStartYear(Number(e.target.value))}>
              {renderYearsOptions()}
            </Select>
          </div>
        </div>
        <EachPeriodFilterDiv>
          <p>To</p>
          <div>
            <Select value={filterEndMonth} onChange={(e) => setFilterEndMonth(Number(e.target.value))}>
              {renderMonthsOptions()}
            </Select>
            <Select value={filterEndYear} onChange={(e) => setFilterEndYear(Number(e.target.value))}>
              {renderYearsOptions()}
            </Select>
          </div>
        </EachPeriodFilterDiv>
      </PeriodContainer>
    );
  };

  const handleRemoveFilterMonthWithMostMinutes = () => {
    setMonthToFilter(0);
    setYearToFilter(0);
    setCurrentFilterByMonth("none");
  };

  const renderFilteredMonths = () => {
    const setCurrentFilterByDate = (month, year) => {
      setCurrentFilterByPeriod("none");
      setMonthToFilter(month);
      setYearToFilter(year);
      if (month === monthToFilter && year === yearToFilter && currentFilterByMonth === "normal") {
        setCurrentFilterByMonth("reverse");
      } else {
        setCurrentFilterByMonth("normal");
      }
    };
    return currentPeriodFiltered.map((date, i) => {
      let month = setMonth(date.month);
      const isMarked = date.month === monthToFilter && date.year === yearToFilter && currentFilterByMonth;
      return (
        <TableColumnDataButton key={i} isMarked={isMarked} onClick={() => setCurrentFilterByDate(date.month, date.year)}>
          {month} {date.year}
        </TableColumnDataButton>
      );
    });
  };

  const renderPeriodColumn = () => {
    const handleSetPeriodFilter = () => {
      handleRemoveFilterMonthWithMostMinutes();
      currentFilterByPeriod === "normal" ? setCurrentFilterByPeriod("reverse") : setCurrentFilterByPeriod("normal");
    };

    const isMarked = currentFilterByPeriod !== "none";
    return (
      <TableColumnDataButton isMarked={isMarked} onClick={handleSetPeriodFilter}>
        Period
      </TableColumnDataButton>
    );
  };

  const renderHospitals = () => {
    let targetedHospitals = isSomeSoftwareFilterActive() ? filteredHospitals : hospitals;
    // Filtering by most minutes in a month
    if (currentFilterByMonth !== "none") {
      let clonedHospitals = JSON.parse(JSON.stringify(targetedHospitals));
      clonedHospitals.sort((a, b) => {
        let hospitalSeconds1 = 0;
        let hospitalSeconds2 = 0;
        a.sessionsData.forEach((session) => {
          if (session.SESSION_MONTH === monthToFilter && session.SESSION_YEAR === yearToFilter) {
            hospitalSeconds1 += session.SESSION_SECONDS;
          }
        });
        b.sessionsData.forEach((session) => {
          if (session.SESSION_MONTH === monthToFilter && session.SESSION_YEAR === yearToFilter) {
            hospitalSeconds2 += session.SESSION_SECONDS;
          }
        });
        return hospitalSeconds1 > hospitalSeconds2 ? -1 : 1;
      });
      if (currentFilterByMonth === "normal") {
        targetedHospitals = clonedHospitals;
      } else {
        targetedHospitals = clonedHospitals.reverse();
      }
    }

    // Filtering by most minutes in a period
    if (currentFilterByPeriod !== "none") {
      let clonedHospitals = JSON.parse(JSON.stringify(targetedHospitals));
      clonedHospitals.sort((a, b) => {
        let hospitalPeriodSeconds1 = 0;
        let hospitalPeriodSeconds2 = 0;
        currentPeriodFiltered.forEach((date) => {
          a.sessionsData.forEach((session) => {
            if (session.SESSION_MONTH === date.month && session.SESSION_YEAR === date.year) {
              hospitalPeriodSeconds1 += session.SESSION_SECONDS;
            }
          });
          b.sessionsData.forEach((session) => {
            if (session.SESSION_MONTH === date.month && session.SESSION_YEAR === date.year) {
              hospitalPeriodSeconds2 += session.SESSION_SECONDS;
            }
          });
        });
        return hospitalPeriodSeconds1 > hospitalPeriodSeconds2 ? -1 : 1;
      });
      if (currentFilterByPeriod === "normal") {
        targetedHospitals = clonedHospitals;
      } else {
        targetedHospitals = clonedHospitals.reverse();
      }
    }

    const totalData = {
      trainedMinutesPerMonth: [],
      totalTrainedMinutes: 0,
      totalPatientsPerMonth: [],
      totalPatients: new Set(),
    };

    currentPeriodFiltered.forEach((date) => {
      totalData.trainedMinutesPerMonth.push({ month: date.month, year: date.year, trainedMinutes: 0, patientsWithSessions: new Set() });
    });

    targetedHospitals.forEach((hospital) => {
      hospital.sessionsData.forEach((session) => {
        const sessionDate = currentPeriodFiltered.find(
          (date) => date.month === session.SESSION_MONTH && date.year === session.SESSION_YEAR
        );
        if (sessionDate) {
          const totalMonthData = totalData.trainedMinutesPerMonth.find(
            (m) => m.month === session.SESSION_MONTH && m.year === session.SESSION_YEAR
          );
          totalMonthData.trainedMinutes += session.SESSION_SECONDS / 60;
        }
      });

      hospital.patientsWithSessions.forEach((patient) => {
        const sessionDate = currentPeriodFiltered.find(
          (date) => date.month === patient.SESSION_MONTH && date.year === patient.SESSION_YEAR
        );
        if (sessionDate) {
          const totalMonthData = totalData.trainedMinutesPerMonth.find(
            (m) => m.month === patient.SESSION_MONTH && m.year === patient.SESSION_YEAR
          );
          totalMonthData.patientsWithSessions.add(patient.PATIENT_ID);
        }
      });

      // Sumar totales de todo el periodo
      hospital.sessionsData.forEach((session) => {
        let sessionDate = new Date(session.SESSION_YEAR, session.SESSION_MONTH - 1);
        if (sessionDate >= new Date(filterStartYear, filterStartMonth - 1) && sessionDate < new Date(filterEndYear, filterEndMonth)) {
          totalData.totalTrainedMinutes += session.SESSION_SECONDS / 60;
        }
      });

      hospital.patientsWithSessions.forEach((patient) => {
        let patientDate = new Date(patient.SESSION_YEAR, patient.SESSION_MONTH - 1);
        if (patientDate >= new Date(filterStartYear, filterStartMonth - 1) && patientDate < new Date(filterEndYear, filterEndMonth)) {
          totalData.totalPatients.add(patient.PATIENT_ID);
        }
      });
    });

    return (
      <>
        <HospitalContainer key="total">
          <HospitalName fontWeight="bold">TOTAL</HospitalName>
          {totalData.trainedMinutesPerMonth.map((month, i) => (
            <HospitalMonth key={i}>
              {parseInt(month.trainedMinutes)} - {month.patientsWithSessions.size}
            </HospitalMonth>
          ))}
          <HospitalMonth fontWeight="bold">
            {parseInt(totalData.totalTrainedMinutes)} - {totalData.totalPatients.size}
          </HospitalMonth>
        </HospitalContainer>

        {targetedHospitals.map((hospital) => {
          let sessionsDataPerMonth = [];
          currentPeriodFiltered.forEach((date) => {
            const sessionTrainedSeconds = hospital.sessionsData.filter(
              (session) => session.SESSION_MONTH === date.month && session.SESSION_YEAR === date.year
            );
            let trainedSeconds = 0;
            sessionTrainedSeconds.forEach((session) => {
              trainedSeconds += session.SESSION_SECONDS;
            });

            const patientsData = hospital.patientsWithSessions.filter(
              (patient) => patient.SESSION_MONTH === date.month && patient.SESSION_YEAR === date.year
            );
            const filteredPatients = new Set();
            patientsData.forEach((patient) => {
              filteredPatients.add(patient.PATIENT_ID);
            });

            sessionsDataPerMonth.push({ trainedMinutes: parseInt(trainedSeconds / 60), patientsWithSessions: filteredPatients.size });
          });

          let secondsPerFilteredPeriod = 0;
          let patientsPerFilteredPeriod = new Set();

          let initialPeriodDate = new Date(filterStartYear, filterStartMonth - 1);
          let endPeriodDate = new Date(filterEndYear, filterEndMonth);

          const filteredMinutes = hospital.sessionsData.filter((session) => {
            let currentSessionDate = new Date(session.SESSION_YEAR, session.SESSION_MONTH - 1);
            return currentSessionDate >= initialPeriodDate && currentSessionDate < endPeriodDate;
          });

          filteredMinutes.forEach((session) => {
            secondsPerFilteredPeriod += session.SESSION_SECONDS;
          });

          hospital.patientsWithSessions.forEach((patient) => {
            let patientDate = new Date(patient.SESSION_YEAR, patient.SESSION_MONTH - 1);
            if (patientDate >= initialPeriodDate && patientDate < endPeriodDate) {
              patientsPerFilteredPeriod.add(patient.PATIENT_ID);
            }
          });

          return (
            <HospitalContainer key={hospital.HOSPITAL_ID}>
              <HospitalName>{hospital.NAME}</HospitalName>
              {sessionsDataPerMonth.map((month, i) => (
                <HospitalMonth key={i}>
                  {month.trainedMinutes} - {month.patientsWithSessions}
                </HospitalMonth>
              ))}
              <HospitalMonth fontWeight="bold">
                {parseInt(secondsPerFilteredPeriod / 60)} - {patientsPerFilteredPeriod.size}
              </HospitalMonth>
            </HospitalContainer>
          );
        })}
      </>
    );
  };

  return (
    <>
      <Header />
      <Title>Hospitals</Title>
      {renderFiltersByApplication()}
      {renderAddUkrainianHospitalsCheckbox()}
      {renderPeriodFilter()}
      {isLoadingHospitals ? (
        <Loader />
      ) : (
        <>
          {!hospitals.length ? (
            <Title>No hay hospitales registrados</Title>
          ) : (
            <>
              <HospitalsContainer>
                <Table>
                  <TableHospitalTitle onClick={handleRemoveFilterMonthWithMostMinutes}>Hospital</TableHospitalTitle>
                  {renderFilteredMonths()}
                  {renderPeriodColumn()}
                </Table>
                {renderHospitals()}
              </HospitalsContainer>
            </>
          )}
        </>
      )}
    </>
  );
};

export default Hospitals;
