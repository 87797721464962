import styled from "styled-components";
import { primaryColor } from "../../../../config/constants";
import { Link } from "react-router-dom";

export const Title = styled.h2`
  margin: 0 0 30px;
  font-size: 26px;
  text-align: center;
`;
export const LinksDiv = styled.div`
  margin: 10px auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const ManageLink = styled(Link)`
  margin: 0 20px;
  width: 140px;
  padding: 10px;
  font-weight: 500;
  color: #000;
  background: transparent;
  outline: none;
  border: 1px solid ${primaryColor};
  text-decoration: none;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.15s ease-in-out;
  &:hover {
    background-color: ${primaryColor};
    color: #fff;
  }
`;
