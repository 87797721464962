import React from "react";
// Components
import VerifySession from "../../../config/verifySession";
import AdminValidation from "../../../config/adminValidation";
import CreateHospitalComponent from "../../../components/hospitalsManagement/sections/hospitalController/createHospital";

const CreateHospitalView = () => (
  <>
    {VerifySession()}
    {AdminValidation()}
    <CreateHospitalComponent />
  </>
);

export default CreateHospitalView;
