import React, { useState, useEffect } from "react";
import { useParams, Redirect } from "react-router-dom";
import { toast } from "react-toastify";
// Redux
import { useSelector } from "react-redux";
// Constants
import { pathHomePage, WEB_SERVICE_DASHBOARD_ROUTE, applications } from "../../../../../../config/constants";
// Components
import { Title, StationInfoDiv, DataDiv, ColumnDataDiv, InputDataDiv, Label, Select, SubmitButton, SubmitButtonDiv } from "./styles";
import GoBack from "../../../../../goBack";
import Header from "../../../../../header";
import Axios from "../../../../../../config/axios";

const CreateClinician = () => {
  const [stationId, setStationId] = useState(1);
  const [application, setApplication] = useState("");
  const [currentVersion, setCurrentVersion] = useState("");
  const [targetVersion, setTargetVersion] = useState("");
  const [redirectToStationsManagement, setRedirectToStationsManagement] = useState(false);
  const { hospitalId } = useParams();
  const environment = useSelector(({ database }) => database.environment);
  const hospitalName = useSelector(({ hospital }) => hospital.currentName);

  // Fetching last id of the table "station" in database
  useEffect(() => {
    Axios("get", `${WEB_SERVICE_DASHBOARD_ROUTE}/station/get-last-id/${environment}`)
      .then(({ data }) => {
        setStationId(data[0].STATION_ID + 1);
      })
      .catch((error) => {
        console.log("Endpoint to get the last id from the table station: ", error);
      });
  }, [environment, hospitalId]);

  const createStation = (e) => {
    e.preventDefault();
    if (!application) return toast.error("Debe ingresar una aplicación");
    if (!currentVersion) return toast.error("Debe seleccionar una version actual");
    if (!targetVersion) return toast.error("Debe seleccionar una version actual");

    const stationData = {
      STATION_ID: stationId,
      SOFTWARE_ID: application,
      HOSPITAL_ID: hospitalId,
      TAG: hospitalName,
      CURRENT_VERSION: currentVersion,
      TARGET_VERSION: targetVersion,
    };
    Axios("post", `${WEB_SERVICE_DASHBOARD_ROUTE}/station/add/${environment}`, stationData)
      .then(({ data }) => {
        if (data.stationAdded) {
          toast.success("Estación creada correctamente");
          setRedirectToStationsManagement(true);
        }
      })
      .catch((error) => {
        console.log("Endpoint to add station: ", error);
      });
  };

  if (redirectToStationsManagement) {
    return <Redirect to={`${pathHomePage}/stations-by-hospital/${hospitalId}`} />;
  }

  const renderAddStationForm = () => {
    return (
      <StationInfoDiv>
        <DataDiv>
          <ColumnDataDiv>
            <InputDataDiv>
              <Label fontWeight="bold">Application *</Label>
              <Select value={application} onChange={(e) => setApplication(e.target.value)}>
                <option value=""></option>
                <option value={applications.clinicId}>RGS Clinic</option>
                <option value={applications.homeId}>RGS Home</option>
              </Select>
            </InputDataDiv>
            <InputDataDiv>
              <Label fontWeight="bold">Current version *</Label>
              <Select value={currentVersion} onChange={(e) => setCurrentVersion(e.target.value)}>
                <option value=""></option>
                <option value="5.0">5.0</option>
                <option value="5.1">5.1</option>
                <option value="5.2">5.2</option>
              </Select>
            </InputDataDiv>
            <InputDataDiv>
              <Label fontWeight="bold">Target version *</Label>
              <Select value={targetVersion} onChange={(e) => setTargetVersion(e.target.value)}>
                <option value=""></option>
                <option value="5.0">5.0</option>
                <option value="5.1">5.1</option>
                <option value="5.2">5.2</option>
              </Select>
            </InputDataDiv>
          </ColumnDataDiv>
        </DataDiv>
        <SubmitButtonDiv>
          <SubmitButton onClick={createStation}>Guardar</SubmitButton>
        </SubmitButtonDiv>
      </StationInfoDiv>
    );
  };

  return (
    <>
      <Header />
      <GoBack path={`${pathHomePage}/stations-by-hospital/${hospitalId}`} />
      <Title>Crear nueva estación</Title>
      {renderAddStationForm()}
    </>
  );
};

export default CreateClinician;
