import styled from "styled-components";
import { primaryColor, primaryColorHover } from "../../config/constants";
import { Link } from "react-router-dom";

export const LinksContainer = styled.div`
  padding: 10px 0;
  width: 100%;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  box-shadow: rgb(0 0 0 / 15%) 0px 5px 15px;
`;
export const ProjectLink = styled(Link)`
  margin: 0 20px;
  padding: 10px;
  color: #fff;
  background-color: ${primaryColor};
  outline: none;
  border: none;
  text-decoration: none;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.15s ease-in-out;
  box-shadow: 0 5px 15px rgb(0 0 0 / 10%);
  &:hover {
    background-color: ${primaryColorHover};
  }
`;
