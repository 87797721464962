import styled from "styled-components";
import { Link } from "react-router-dom";

export const PatientsAligner = styled.div`
  margin: 0 auto 20px;
  width: 800px;
  display: flex;
  align-items: flex-start;
`;
export const PatientsContainer = styled.div`
  padding: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 5px 15px rgb(0 0 0 / 10%);
`;
export const TableContainer = styled.div`
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;
export const TableTitle = styled.p`
  width: ${({ width }) => (width ? width : "200px")};
  font-size: 14px;
  font-weight: bold;
  text-align: ${({ align }) => (align ? align : "center")};
`;
export const PatientContainer = styled.div`
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
`;
export const PatientInfo = styled.p`
  width: ${({ width }) => (width ? width : "200px")};
  font-size: 14px;
  text-align: ${({ align }) => (align ? align : "center")};
`;
export const PatientLink = styled(Link)`
  padding: 0 15px;
  width: ${({ width }) => (width ? width : "200px")};
  display: flex;
  justify-content: space-between;
  outline: none;
  text-decoration: none;
  cursor: pointer;
`;
export const AdherencePercentage = styled.p`
  font-size: 14px;
  font-weight: bold;
  color: ${({ color }) => color && color};
`;
export const ExternalLinkImg = styled.img`
  width: 20px;
  height: 20px;
`;
