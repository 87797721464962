import React from "react";
// Components
import PatientsDevices from "../../components/patientsDevices";
import VerifySession from "../../config/verifySession";

const PatientsDevicesView = () => (
  <>
    {VerifySession()}
    <PatientsDevices />
  </>
);

export default PatientsDevicesView;
