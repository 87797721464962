import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
// Redux
import { useSelector } from "react-redux";
// Constants
import { pathHomePage, WEB_SERVICE_DASHBOARD_ROUTE } from "../../../../config/constants";
// Components
import { Title, TableContainer, TableTitle } from "./styles";
import Header from "../../../header";
import GoBack from "../../../goBack";
import Loader from "../../../loader";
import UseFetch from "../../../../config/useFetch";
import RenderHospitalData from "../renderHospitalData";

const HospitalsData = () => {
  const [isLoadingHospitals, setIsLoadingHospitals] = useState(true);
  const [patientsNumber, setPatientsNumber] = useState(0);
  const [hospitals, setHospitals] = useState([]);
  const [MIMS_ROUTES] = useState({
    global_production: "https://rgsmims.eodyne.com/home",
    global_beta: "https://rgsmims-beta.eodyne.com/home",
    global_development: "https://rgsmims-dev.eodyne.com/home",
    strack_production: "https://rgsmims-strack.eodyne.com/home",
    strack_development: "https://rgsmims-strack-dev.eodyne.com/home",
  });
  const [hospitalsByProject] = useState({
    PHRASE: [
      "Sant Joan de Deu",
      "IRF La Salle",
      "Limoges",
      "INA memory center",
      "Adacen",
      "Hospital Universitario Joan XXIII de Tarragona",
      "RoNeuro (Institute for Neurological Research and Diagnostic)",
    ],
    AISN: [
      "AISN San Camillo IRCCS",
      "AISN CHUL",
      "AISN Sant Joan de Deu",
      "AISN RoNeuro (Institute for Neurological Research and Diagnostic)",
    ],
    ROMANIAN_CAMPAIGN: ["Romanian Campaign"],
  });
  const [feasibilityHospitals] = useState(["Sant Joan de Deu", "IRF La Salle", "Limoges", "INA memory center", "Adacen"]);
  const [RCTHospitals] = useState([
    "Hospital Universitario Joan XXIII de Tarragona",
    "RoNeuro (Institute for Neurological Research and Diagnostic)",
  ]);

  const { project } = useParams();
  const isLoggedIn = useSelector(({ user }) => user.isLoggedIn);
  const environment = useSelector(({ database }) => database.environment);

  // Fetching hospitals by project
  useEffect(() => {
    const getHospitalsSignal = new AbortController();

    if (isLoggedIn && environment) {
      const hospitalObj = {
        hospitals: hospitalsByProject[project],
      };
      UseFetch("post", `${WEB_SERVICE_DASHBOARD_ROUTE}/hospital/get-hospitals-by-project/${environment}`, getHospitalsSignal, hospitalObj)
        .then(({ data }) => {
          const hospitalsPatients = data.reduce((totalPatients, hospital) => totalPatients + hospital.patients.length, 0);
          setPatientsNumber(hospitalsPatients);
          setHospitals(data);
          setIsLoadingHospitals(false);
        })
        .catch((error) => {
          console.log("Get hospitals by project: ", error);
        });
    }

    return () => {
      setIsLoadingHospitals(true);
      setPatientsNumber(0);
      setHospitals([]);
      if (getHospitalsSignal) {
        getHospitalsSignal.abort();
      }
    };
  }, [isLoggedIn, environment, hospitalsByProject, project]);

  const renderProjectTitle = () => {
    if (project === "ROMANIAN_CAMPAIGN") return "Romanian Campaign";
    return project;
  };

  const renderTableHeader = () => {
    return (
      <TableContainer>
        <TableTitle width="200px">Hospital</TableTitle>
        <TableTitle width="110px">Adherence</TableTitle>
        <TableTitle width="120px">Go to MIMS</TableTitle>
        <TableTitle width="130px">Nº Patients</TableTitle>
        <TableTitle width="110px">Patients list</TableTitle>
      </TableContainer>
    );
  };

  const renderHospitals = (hospitals) => {
    return hospitals.map((hospital) => {
      const urlToMims = `${MIMS_ROUTES[environment]}/${hospital.tokenToMims}`;
      return <RenderHospitalData key={hospital.HOSPITAL_ID} hospital={hospital} project={project} urlToMims={urlToMims} />;
    });
  };

  const renderHospitalsTable = () => {
    if (!hospitals.length) return <Title fontSize="20px">There are no hospitals for this project</Title>;

    const feasibilityHospitalsData = hospitals.filter((hospital) => feasibilityHospitals.includes(hospital.HOSPITAL_NAME));
    const RCTHospitalsData = hospitals.filter((hospital) => RCTHospitals.includes(hospital.HOSPITAL_NAME));

    const feasibilityPatientsNumber = feasibilityHospitalsData.reduce(
      (totalPatients, hospital) => totalPatients + hospital.patients.length,
      0
    );
    const RCTPatientsNumber = RCTHospitalsData.reduce((totalPatients, hospital) => totalPatients + hospital.patients.length, 0);

    return (
      <>
        {Boolean(project === "PHRASE") ? (
          <>
            <Title margin="30px 0 20px">{`Active patients in feasibility study: ${feasibilityPatientsNumber}`}</Title>
            {renderTableHeader()}
            {renderHospitals(feasibilityHospitalsData)}
            <Title margin="30px 0 20px">{`Active patients in RCT: ${RCTPatientsNumber}`}</Title>
            {renderTableHeader()}
            {renderHospitals(RCTHospitalsData)}
          </>
        ) : (
          <>
            {renderTableHeader()}
            {renderHospitals(hospitals)}
          </>
        )}
      </>
    );
  };

  const isLoading = isLoadingHospitals;

  return (
    <>
      <Header />
      <GoBack path={`${pathHomePage}/hospitals-by-project`} />
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Title>{`Project: ${renderProjectTitle()}`}</Title>
          {Boolean(project !== "PHRASE") && <Title margin="30px 0 20px">{`Active patients in this project: ${patientsNumber}`}</Title>}
          {renderHospitalsTable()}
        </>
      )}
    </>
  );
};

export default HospitalsData;
