import axios from "axios";

const UseFetch = async (method, url, abortController = new AbortController(), params) => {
  const signal = abortController.signal;
  const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJBRE1JTl9EQVNIQk9BUkQiOnRydWV9.43FTV0jjnXFWeEY09oNpF0RrnANZfbaPpErcUbFSCGI";
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const request = axios[method.toLowerCase()](
      url,
      ...(method.toLowerCase() === "get" ? [{ ...config, signal }] : [params, { ...config, signal }])
    );
    const response = await request;
    return response;
  } catch (error) {
    throw error;
  }
};

export default UseFetch;
