import styled from "styled-components";
import { primaryColor, primaryColorHover } from "../../../../config/constants";

export const Title = styled.h2`
  margin: 0 0 15px;
  font-size: 26px;
  text-align: center;
`;
export const ProtocolInfoDiv = styled.div`
  margin: auto;
  width: 100%;
  max-width: 860px;
  padding: 15px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 5px 15px rgb(0 0 0 / 10%);
`;
export const AppsDiv = styled.div`
  width: 70%;
  max-width: 860px;
  margin: 10px auto;
  padding: 15px;
`;
export const TitleTextDiv = styled.div`
  display: flex;
  justify-content: center;
`;
export const TitleApps = styled.p`
  font-size: 16px;
  font-weight: bold;
`;
export const DataAppsDiv = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: ${({ justifyContent }) => (justifyContent ? "flex-start" : "center")};
  flex-wrap: wrap;
  @media (max-width: 910px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;
export const InputPositioner = styled.div`
  padding-right: 100px;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media (max-width: 910px) {
    padding: 0;
    width: 150px;
  }
`;
export const InputDataAppsDiv = styled.div`
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
export const InputCheckboxDiv = styled.div`
  width: 20px;
  height: 20px;
  position: relative;
`;
export const InputCheckbox = styled.input`
  width: 15px;
  height: 15px;
  cursor: pointer;
`;
export const Label = styled.label`
  margin-right: 15px;
  font-size: 14px;
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : "normal")};
`;
export const SubmitButtonDiv = styled.div`
  margin: 10px 0 0;
  width: 100%;
  display: flex;
  justify-content: center;
`;
export const SubmitButton = styled.button`
  padding: 10px 30px;
  color: #fff;
  background-color: ${primaryColor};
  outline: none;
  border: none;
  text-decoration: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.15s ease-in-out;
  &:hover {
    background-color: ${primaryColorHover};
  }
`;
