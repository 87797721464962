// ----- State -----
const initialState = {
  currentName: "",
};

// ----- Types -----
// LOGIN
const SETTING_HOSPITAL_NAME = "SETTING_HOSPITAL_NAME";

// ----- Reducer -----
export default function reducer(state = initialState, action) {
  switch (action.type) {
    // LOGIN
    case SETTING_HOSPITAL_NAME:
      return {
        ...state,
        currentName: action.payload.currentName,
      };
    default:
      return state;
  }
}

// ----- Actions -----
// LOGIN
export const setCurrentHospitalName = (currentName) => (dispatch) => {
  dispatch({
    type: SETTING_HOSPITAL_NAME,
    payload: {
      currentName,
    },
  });
};
