import React from "react";
// Components
import VerifySession from "../../../../config/verifySession";
import AdminValidation from "../../../../config/adminValidation";
import EditClinicianComponent from "../../../../components/hospitalsManagement/sections/clinicianController/sections/editClinician";

const EditClinicianView = () => (
  <>
    {VerifySession()}
    {AdminValidation()}
    <EditClinicianComponent />
  </>
);

export default EditClinicianView;
