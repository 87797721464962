import React from "react";
// Components
import ActivePatientsComponent from "../../components/activePatients";
import VerifySession from "../../config/verifySession";

const ActivePatientsView = () => (
  <>
    {VerifySession()}
    <ActivePatientsComponent />
  </>
);

export default ActivePatientsView;
