import React, { useState, useEffect } from "react";
import moment from "moment";
import { Line } from "react-chartjs-2";
// Redux
import { useSelector } from "react-redux";
// Constants - Functions
import { WEB_SERVICE_DASHBOARD_ROUTE, primaryColor } from "../../config/constants";
import {
  setMonth,
  setCurrentPeriodOfMonthsForStrack,
  renderMonthsOptions,
  renderYearsOptions,
} from "../../config/functions";
// Components
import {
  Title,
  Select,
  PeriodContainer,
  EachPeriodFilterDiv,
  Table,
  TableHeaderField,
  ClinicianActivitiesDiv,
  ClinicianActivityDiv,
  ClinicianActivityPerMonth,
} from "./styles";
import Header from "../header";
import Loader from "../loader";
import Axios from "../../config/axios";
import Graphic from "../graphic";

const ClinicianActivity = () => {
  const [isLoadingClinicianActivity, setIsLoadingClinicianActivity] = useState(true);
  const [clinicianActivity, setClinicianActivity] = useState([]);
  const [filterStartMonth, setFilterStartMonth] = useState(0);
  const [filterStartYear, setFilterStartYear] = useState(0);
  const [filterEndMonth, setFilterEndMonth] = useState(0);
  const [filterEndYear, setFilterEndYear] = useState(0);
  const [currentPeriodFiltered, setCurrentPeriodFiltered] = useState([]);
  const [targetedPeriod, setTargetedPeriod] = useState([]);

  const isLoggedIn = useSelector(({ user }) => user.isLoggedIn);
  const environment = useSelector(({ database }) => database.environment);

  // Fetching clinician activity
  useEffect(() => {
    if (isLoggedIn && environment !== "") {
      Axios("get", `${WEB_SERVICE_DASHBOARD_ROUTE}/clinician/get-activity/${environment}`)
        .then(({ data }) => {
          setClinicianActivity(data);
          setIsLoadingClinicianActivity(false);
        })
        .catch((error) => {
          console.log("endpoint to get clinician activity data: ", error);
        });
    }
  }, [isLoggedIn, environment]);

  // Setting date filter
  useEffect(() => {
    const initialNumberOfMonthsAgo = 5;
    setFilterStartMonth(Number(moment().subtract(initialNumberOfMonthsAgo, "months").format("MM")));
    setFilterStartYear(Number(moment().subtract(initialNumberOfMonthsAgo, "months").format("YYYY")));
    setFilterEndMonth(Number(moment().format("MM")));
    setFilterEndYear(Number(new Date().getFullYear()));
  }, []);

  // Setting current period of months and years
  useEffect(() => {
    setCurrentPeriodOfMonthsForStrack(
      filterStartMonth,
      filterStartYear,
      filterEndMonth,
      filterEndYear,
      setCurrentPeriodFiltered,
      setTargetedPeriod
    );
  }, [filterEndMonth, filterEndYear, filterStartMonth, filterStartYear]);

  const renderPeriodFilter = () => {
    return (
      <PeriodContainer>
        <div>
          <p>Desde</p>
          <div>
            <Select value={filterStartMonth} onChange={(e) => setFilterStartMonth(Number(e.target.value))}>
              {renderMonthsOptions()}
            </Select>
            <Select value={filterStartYear} onChange={(e) => setFilterStartYear(Number(e.target.value))}>
              {renderYearsOptions()}
            </Select>
          </div>
        </div>
        <EachPeriodFilterDiv>
          <p>Hasta</p>
          <div>
            <Select value={filterEndMonth} onChange={(e) => setFilterEndMonth(Number(e.target.value))}>
              {renderMonthsOptions()}
            </Select>
            <Select value={filterEndYear} onChange={(e) => setFilterEndYear(Number(e.target.value))}>
              {renderYearsOptions()}
            </Select>
          </div>
        </EachPeriodFilterDiv>
      </PeriodContainer>
    );
  };

  const renderFilteredMonths = () => {
    return (
      <Table>
        <TableHeaderField width="220px">Actividad</TableHeaderField>
        {currentPeriodFiltered.map((date, i) => {
          let month = setMonth(date.month);
          return (
            <TableHeaderField key={i}>
              {month} {date.year}
            </TableHeaderField>
          );
        })}
      </Table>
    );
  };

  const renderClinicianActivity = (activityTitle, activityType) => {
    return (
      <ClinicianActivityDiv>
        <ClinicianActivityPerMonth width="220px">{activityTitle}</ClinicianActivityPerMonth>
        {currentPeriodFiltered.map((date, dateIndex) => {
          const activityNumber = clinicianActivity.filter(
            (eachActivity) =>
              eachActivity.ACTIVITY_YEAR === date.year &&
              eachActivity.ACTIVITY_MONTH === date.month &&
              eachActivity.ACTIVITY_TYPE === activityType
          ).length;
          return <ClinicianActivityPerMonth key={dateIndex}>{activityNumber}</ClinicianActivityPerMonth>;
        })}
      </ClinicianActivityDiv>
    );
  };

  // Data for graphics
  const handleSetActivityValues = (activityType, valuesArray) => {
    return currentPeriodFiltered.forEach((date) => {
      const activityNumber = clinicianActivity.filter(
        (eachActivity) =>
          eachActivity.ACTIVITY_YEAR === date.year &&
          eachActivity.ACTIVITY_MONTH === date.month &&
          eachActivity.ACTIVITY_TYPE === activityType
      ).length;
      valuesArray.push(activityNumber);
    });
  };

  const getLoginsData = () => {
    let loginsValues = [];
    handleSetActivityValues("LOGIN", loginsValues);
    return {
      labels: targetedPeriod,
      datasets: [
        {
          label: "Logins",
          data: loginsValues,
          backgroundColor: [primaryColor],
          borderColor: [primaryColor],
          fill: false,
        },
      ],
    };
  };
  const getReportsData = () => {
    let reportsValues = [];
    handleSetActivityValues("REPORT_PROTOCOL", reportsValues);
    return {
      labels: targetedPeriod,
      datasets: [
        {
          label: "Accesos a reportes",
          data: reportsValues,
          backgroundColor: [primaryColor],
          borderColor: [primaryColor],
          fill: false,
        },
      ],
    };
  };
  const getPrescriptionsActivityData = () => {
    let prescriptionsManagedValues = [];
    handleSetActivityValues("PRESCRIPTION", prescriptionsManagedValues);
    return {
      labels: targetedPeriod,
      datasets: [
        {
          label: "Prescripciones gestionadas",
          data: prescriptionsManagedValues,
          backgroundColor: [primaryColor],
          borderColor: [primaryColor],
          fill: false,
        },
      ],
    };
  };

  return (
    <>
      <Header />
      {isLoadingClinicianActivity ? (
        <Loader />
      ) : (
        <>
          <Title>Actividad de los Profesionales</Title>
          {renderPeriodFilter()}
          <ClinicianActivitiesDiv>
            {renderFilteredMonths()}
            {renderClinicianActivity("Login", "LOGIN")}
            {renderClinicianActivity("Acceso a reportes", "REPORT_PROTOCOL")}
            {renderClinicianActivity("Prescripciones gestionadas", "PRESCRIPTION")}
          </ClinicianActivitiesDiv>
          {/* Logins activity */}
          <Graphic
            width="70%"
            height="400px"
            Title={"Logins de los Profesionales"}
            Type={Line}
            data={getLoginsData()}
            axisY={"Logins"}
            axisX={"Meses"}
          />
          {/* Reports by protocol activity */}
          <Graphic
            width="70%"
            Title={"Actividad de acceso a reportes"}
            Type={Line}
            data={getReportsData()}
            axisY={"Accesos a reportes"}
            axisX={"Meses"}
          />
          {/* Prescription management activity */}
          <Graphic
            width="70%"
            Title={"Actividad de gestión de prescripciones"}
            Type={Line}
            data={getPrescriptionsActivityData()}
            axisY={"Prescripciones gestionadas"}
            axisX={"Meses"}
          />
        </>
      )}
    </>
  );
};

export default ClinicianActivity;
