import React from "react";
// Components
import VerifySession from "../../../../config/verifySession";
import AdminValidation from "../../../../config/adminValidation";
import CreateClinicianComponent from "../../../../components/hospitalsManagement/sections/clinicianController/sections/createClinician";

const CreateClinicianView = () => (
  <>
    {VerifySession()}
    {AdminValidation()}
    <CreateClinicianComponent />
  </>
);

export default CreateClinicianView;
