import React from "react";
// Components
import VerifySession from "../../config/verifySession";
import HospitalData from "../../components/hospitalData";

const HospitalDataView = () => (
  <>
    {VerifySession()}
    <HospitalData />
  </>
);

export default HospitalDataView;
