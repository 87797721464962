import React, { useState, useEffect } from "react";
import moment from "moment";
// Redux
import { useSelector } from "react-redux";
// Constants - Functions
import { WEB_SERVICE_DASHBOARD_ROUTE, applications } from "../../config/constants";
import { setProtocolTitle, setMonth, renderMonthsOptions, renderYearsOptions, setCurrentPeriodOfMonths } from "../../config/functions";
// Components
import {
  Title,
  SoftwareFilterDiv,
  EachSoftwareFilterDiv,
  CheckboxInput,
  CheckboxLabel,
  Select,
  Option,
  PeriodContainer,
  EachPeriodFilterDiv,
  Table,
  TableTitle,
  TableColumnDataButton,
  PatientsContainer,
  PatientContainer,
  PatientField,
  PatientMonth,
} from "./styles";
import Header from "../header";
import Loader from "../loader";
import Axios from "../../config/axios";
import UseFetch from "../../config/useFetch";

const Patients = () => {
  const [isLoadingPatients, setIsLoadingPatients] = useState(true);
  const [patients, setPatients] = useState([]);
  const [filteredPatients, setFilteredPatients] = useState([]);
  const [monthToFilter, setMonthToFilter] = useState(0);
  const [yearToFilter, setYearToFilter] = useState(0);
  const [currentFilterByMonth, setCurrentFilterByMonth] = useState("none");
  const [currentFilterByPeriod, setCurrentFilterByPeriod] = useState("none");
  const [isClinicChecked, setIsClinicChecked] = useState(false);
  const [isHomeChecked, setIsHomeChecked] = useState(false);
  const [isWebChecked, setIsWebChecked] = useState(false);
  const [isAppChecked, setIsAppChecked] = useState(false);
  const [isUkrainianHospitalsChecked, setIsUkrainianHospitalsChecked] = useState(false);
  const [softwareProtocols, setSoftwareProtocols] = useState([]);
  const [isProtocolListDisabled, setIsProtocolListDisabled] = useState(true);
  const [currentProtocol, setCurrentProtocol] = useState(0);
  const [filterStartMonth, setFilterStartMonth] = useState(0);
  const [filterStartYear, setFilterStartYear] = useState(0);
  const [filterEndMonth, setFilterEndMonth] = useState(0);
  const [filterEndYear, setFilterEndYear] = useState(0);
  const [currentPeriodFiltered, setCurrentPeriodFiltered] = useState([]);
  const isLoggedIn = useSelector(({ user }) => user.isLoggedIn);
  const environment = useSelector(({ database }) => database.environment);

  // Fetching patients data
  useEffect(() => {
    setIsLoadingPatients(true);
    const getPatientsSignal = new AbortController();

    if (isLoggedIn && environment) {
      const body = {
        addUkrainianHospitals: isUkrainianHospitalsChecked,
      };
      UseFetch(
        "post",
        `${WEB_SERVICE_DASHBOARD_ROUTE}/patient/get-patients-with-hospital-and-sessions/${environment}`,
        getPatientsSignal,
        body
      )
        .then(({ data }) => {
          setPatients(data);
          setIsLoadingPatients(false);
        })
        .catch((error) => {
          console.log("Get patients: ", error);
        });
    }

    return () => {
      setPatients([]);
      setIsLoadingPatients(true);
      if (getPatientsSignal) {
        getPatientsSignal.abort();
      }
    };
  }, [isLoggedIn, environment, isUkrainianHospitalsChecked]);

  // Setting date filter
  useEffect(() => {
    const initialNumberOfMonthsAgo = 5;
    setFilterStartMonth(Number(moment().subtract(initialNumberOfMonthsAgo, "months").format("MM")));
    setFilterStartYear(Number(moment().subtract(initialNumberOfMonthsAgo, "months").format("YYYY")));
    setFilterEndMonth(Number(moment().format("MM")));
    setFilterEndYear(Number(new Date().getFullYear()));
  }, []);

  // Fetching protocols by software
  useEffect(() => {
    if (isLoggedIn && environment !== "") {
      setCurrentProtocol(0);
      const activeFilters = [];
      if (isClinicChecked) activeFilters.push(applications.clinicId);
      if (isHomeChecked) activeFilters.push(applications.homeId);
      if (isWebChecked) activeFilters.push(applications.webId);
      if (isAppChecked) activeFilters.push(applications.appId);

      if (activeFilters.length === 1) {
        Axios("get", `${WEB_SERVICE_DASHBOARD_ROUTE}/protocol/by-software/${environment}/${activeFilters[0]}`)
          .then(({ data }) => {
            data.forEach((protocol) => {
              setProtocolTitle(protocol);
            });
            setSoftwareProtocols(data);
          })
          .catch((error) => {
            console.log("endpoint to get protocols: ", error);
          });
        setIsProtocolListDisabled(false);
      } else {
        setSoftwareProtocols([]);
        setIsProtocolListDisabled(true);
      }
    }
  }, [isLoggedIn, isClinicChecked, isHomeChecked, isWebChecked, isAppChecked, environment]);

  // Setting current period of months and years
  useEffect(() => {
    setCurrentPeriodOfMonths(filterStartMonth, filterStartYear, filterEndMonth, filterEndYear, setCurrentPeriodFiltered);
  }, [filterEndMonth, filterEndYear, filterStartMonth, filterStartYear]);

  // Filtering patients data by software and protocol
  useEffect(() => {
    const clonedPatients = JSON.parse(JSON.stringify(patients));
    // Filter by software
    clonedPatients.forEach((patient) => {
      let concatenatedSessions = [];

      const handleSetConcatenatedSessions = (software) => {
        const sessions = patient.sessionsData.filter((session) => session.SOFTWARE === software);
        concatenatedSessions.push(...sessions);
      };

      if (isClinicChecked) handleSetConcatenatedSessions("clinic");
      if (isHomeChecked) handleSetConcatenatedSessions("home");
      if (isWebChecked) handleSetConcatenatedSessions("web");
      if (isAppChecked) handleSetConcatenatedSessions("app");

      patient.sessionsData = concatenatedSessions;
    });

    // Filter by protocol
    if (currentProtocol !== 0) {
      clonedPatients.forEach((patient) => {
        patient.sessionsData = patient.sessionsData.filter((session) => session.PROTOCOL_ID === currentProtocol);
      });
    }

    setFilteredPatients(clonedPatients);
  }, [isLoggedIn, patients, isAppChecked, isClinicChecked, isHomeChecked, isWebChecked, currentProtocol]);

  const isSomeSoftwareFilterActive = () => {
    return isClinicChecked || isHomeChecked || isWebChecked || isAppChecked;
  };

  const renderFiltersByApplication = () => {
    const renderProtocolsByApplication = () => {
      return (
        <Select value={currentProtocol} onChange={(e) => setCurrentProtocol(Number(e.target.value))} disabled={isProtocolListDisabled}>
          <Option value={0}></Option>
          {softwareProtocols.map((protocol) => {
            return (
              <Option key={protocol.PROTOCOL_ID} value={protocol.PROTOCOL_ID}>
                {protocol.NAME}
              </Option>
            );
          })}
        </Select>
      );
    };
    return (
      <SoftwareFilterDiv>
        <EachSoftwareFilterDiv>
          <CheckboxInput
            type="checkbox"
            id="clinicCheckbox"
            checked={isClinicChecked}
            onChange={() => setIsClinicChecked(!isClinicChecked)}
          />
          <CheckboxLabel htmlFor="clinicCheckbox">Clinic</CheckboxLabel>
        </EachSoftwareFilterDiv>
        <EachSoftwareFilterDiv>
          <CheckboxInput type="checkbox" id="homeCheckbox" checked={isHomeChecked} onChange={() => setIsHomeChecked(!isHomeChecked)} />
          <CheckboxLabel htmlFor="homeCheckbox">Home</CheckboxLabel>
        </EachSoftwareFilterDiv>
        <EachSoftwareFilterDiv>
          <CheckboxInput type="checkbox" id="webCheckbox" checked={isWebChecked} onChange={() => setIsWebChecked(!isWebChecked)} />
          <CheckboxLabel htmlFor="webCheckbox">Web</CheckboxLabel>
        </EachSoftwareFilterDiv>
        <EachSoftwareFilterDiv>
          <CheckboxInput type="checkbox" id="appCheckbox" checked={isAppChecked} onChange={() => setIsAppChecked(!isAppChecked)} />
          <CheckboxLabel htmlFor="appCheckbox">App</CheckboxLabel>
        </EachSoftwareFilterDiv>
        {renderProtocolsByApplication()}
      </SoftwareFilterDiv>
    );
  };

  const renderAddUkrainianHospitalsCheckbox = () => {
    const setUkrainianHospitals = () => {
      setIsUkrainianHospitalsChecked(!isUkrainianHospitalsChecked);
    };

    return (
      <SoftwareFilterDiv>
        <CheckboxInput
          type="checkbox"
          id="addUkrainianHospitalsCheckbox"
          checked={isUkrainianHospitalsChecked}
          onChange={setUkrainianHospitals}
        />
        <CheckboxLabel htmlFor="addUkrainianHospitalsCheckbox">Add Ukrainian hospitals</CheckboxLabel>
      </SoftwareFilterDiv>
    );
  };

  const renderPeriodFilter = () => {
    return (
      <PeriodContainer>
        <div>
          <p>From</p>
          <div>
            <Select value={filterStartMonth} onChange={(e) => setFilterStartMonth(Number(e.target.value))}>
              {renderMonthsOptions()}
            </Select>
            <Select value={filterStartYear} onChange={(e) => setFilterStartYear(Number(e.target.value))}>
              {renderYearsOptions()}
            </Select>
          </div>
        </div>
        <EachPeriodFilterDiv>
          <p>To</p>
          <div>
            <Select value={filterEndMonth} onChange={(e) => setFilterEndMonth(Number(e.target.value))}>
              {renderMonthsOptions()}
            </Select>
            <Select value={filterEndYear} onChange={(e) => setFilterEndYear(Number(e.target.value))}>
              {renderYearsOptions()}
            </Select>
          </div>
        </EachPeriodFilterDiv>
      </PeriodContainer>
    );
  };

  const handleRemoveFilterMonthWithMostMinutes = () => {
    setMonthToFilter(0);
    setYearToFilter(0);
    setCurrentFilterByMonth("none");
  };

  const renderFilteredMonths = () => {
    const setCurrentFilterByDate = (month, year) => {
      setCurrentFilterByPeriod("none");
      setMonthToFilter(month);
      setYearToFilter(year);
      if (month === monthToFilter && year === yearToFilter && currentFilterByMonth === "normal") {
        setCurrentFilterByMonth("reverse");
      } else {
        setCurrentFilterByMonth("normal");
      }
    };
    return currentPeriodFiltered.map((date, i) => {
      let month = setMonth(date.month);
      const isMarked = date.month === monthToFilter && date.year === yearToFilter && currentFilterByMonth;
      return (
        <TableColumnDataButton key={i} isMarked={isMarked} onClick={() => setCurrentFilterByDate(date.month, date.year)}>
          {month} {date.year}
        </TableColumnDataButton>
      );
    });
  };

  const renderPeriodColumn = () => {
    const handleSetPeriodFilter = () => {
      handleRemoveFilterMonthWithMostMinutes();
      currentFilterByPeriod === "normal" ? setCurrentFilterByPeriod("reverse") : setCurrentFilterByPeriod("normal");
    };

    const isMarked = currentFilterByPeriod !== "none";
    return (
      <TableColumnDataButton isMarked={isMarked} onClick={handleSetPeriodFilter}>
        Period
      </TableColumnDataButton>
    );
  };

  const renderPatients = () => {
    let targetedPatients = isSomeSoftwareFilterActive() ? filteredPatients : patients;

    // Filtering by most minutes in a month
    if (currentFilterByMonth !== "none") {
      let clonedPatients = JSON.parse(JSON.stringify(targetedPatients));
      clonedPatients.sort((a, b) => {
        let patientSeconds1 = 0;
        let patientSeconds2 = 0;
        a.sessionsData.forEach((session) => {
          if (session.SESSION_MONTH === monthToFilter && session.SESSION_YEAR === yearToFilter) {
            patientSeconds1 += session.SESSION_SECONDS;
          }
        });
        b.sessionsData.forEach((session) => {
          if (session.SESSION_MONTH === monthToFilter && session.SESSION_YEAR === yearToFilter) {
            patientSeconds2 += session.SESSION_SECONDS;
          }
        });
        return patientSeconds1 > patientSeconds2 ? -1 : 1;
      });
      if (currentFilterByMonth === "normal") {
        targetedPatients = clonedPatients;
      } else {
        targetedPatients = clonedPatients.reverse();
      }
    }

    // Filtering by most minutes in a period
    if (currentFilterByPeriod !== "none") {
      let clonedPatients = JSON.parse(JSON.stringify(targetedPatients));
      clonedPatients.sort((a, b) => {
        let patientPeriodSeconds1 = 0;
        let patientPeriodSeconds2 = 0;
        currentPeriodFiltered.forEach((date) => {
          a.sessionsData.forEach((session) => {
            if (session.SESSION_MONTH === date.month && session.SESSION_YEAR === date.year) {
              patientPeriodSeconds1 += session.SESSION_SECONDS;
            }
          });
          b.sessionsData.forEach((session) => {
            if (session.SESSION_MONTH === date.month && session.SESSION_YEAR === date.year) {
              patientPeriodSeconds2 += session.SESSION_SECONDS;
            }
          });
        });
        return patientPeriodSeconds1 > patientPeriodSeconds2 ? -1 : 1;
      });
      if (currentFilterByPeriod === "normal") {
        targetedPatients = clonedPatients;
      } else {
        targetedPatients = clonedPatients.reverse();
      }
    }

    const totalData = {
      trainedMinutesPerMonth: [],
      totalTrainedMinutes: 0,
    };

    currentPeriodFiltered.forEach((date) => {
      totalData.trainedMinutesPerMonth.push({ month: date.month, year: date.year, trainedMinutes: 0 });
    });

    targetedPatients.forEach((patient) => {
      patient.sessionsData.forEach((session) => {
        const sessionDate = currentPeriodFiltered.find(
          (date) => date.month === session.SESSION_MONTH && date.year === session.SESSION_YEAR
        );
        if (sessionDate) {
          const totalMonthData = totalData.trainedMinutesPerMonth.find(
            (m) => m.month === session.SESSION_MONTH && m.year === session.SESSION_YEAR
          );
          totalMonthData.trainedMinutes += session.SESSION_SECONDS / 60;
        }
      });

      patient.sessionsData.forEach((session) => {
        let sessionDate = new Date(session.SESSION_YEAR, session.SESSION_MONTH - 1);
        if (sessionDate >= new Date(filterStartYear, filterStartMonth - 1) && sessionDate < new Date(filterEndYear, filterEndMonth)) {
          totalData.totalTrainedMinutes += session.SESSION_SECONDS / 60;
        }
      });
    });

    return (
      <>
        <PatientContainer key="total">
          <PatientField></PatientField>
          <PatientField fontWeight="bold">TOTAL</PatientField>
          {totalData.trainedMinutesPerMonth.map((month, i) => (
            <PatientMonth key={i}>{parseInt(month.trainedMinutes)}</PatientMonth>
          ))}
          <PatientMonth fontWeight="bold">{parseInt(totalData.totalTrainedMinutes)}</PatientMonth>
        </PatientContainer>

        {targetedPatients.map((patient) => {
          let sessionsDataPerMonth = [];
          currentPeriodFiltered.forEach((date) => {
            const sessionTrainedSeconds = patient.sessionsData.filter(
              (session) => session.SESSION_MONTH === date.month && session.SESSION_YEAR === date.year
            );
            let trainedSeconds = 0;
            sessionTrainedSeconds.forEach((session) => {
              trainedSeconds += session.SESSION_SECONDS;
            });
            sessionsDataPerMonth.push({ trainedMinutes: parseInt(trainedSeconds / 60) });
          });

          // Data from start and end period
          let secondsPerFilteredPeriod = 0;
          let initialPeriodDate = new Date(filterStartYear, filterStartMonth - 1);
          let endPeriodDate = new Date(filterEndYear, filterEndMonth);

          const filteredMinutes = patient.sessionsData.filter((session) => {
            let currentSessionDate = new Date(session.SESSION_YEAR, session.SESSION_MONTH - 1);
            return currentSessionDate >= initialPeriodDate && currentSessionDate < endPeriodDate;
          });
          filteredMinutes.forEach((session) => {
            secondsPerFilteredPeriod += session.SESSION_SECONDS;
          });

          return (
            <PatientContainer key={patient.PATIENT_ID}>
              <PatientField>{patient.PATIENT_USER}</PatientField>
              <PatientField>{patient.HOSPITAL_NAME}</PatientField>
              {sessionsDataPerMonth.map((month, i) => (
                <PatientMonth key={i}>{month.trainedMinutes}</PatientMonth>
              ))}
              <PatientMonth fontWeight="bold">{parseInt(secondsPerFilteredPeriod / 60)}</PatientMonth>
            </PatientContainer>
          );
        })}
      </>
    );
  };

  return (
    <>
      <Header />
      <Title>Patients</Title>
      {renderFiltersByApplication()}
      {renderAddUkrainianHospitalsCheckbox()}
      {renderPeriodFilter()}
      {isLoadingPatients ? (
        <Loader />
      ) : (
        <>
          {patients.length === 0 ? (
            <Title>No hay pacientes registrados</Title>
          ) : (
            <>
              <PatientsContainer>
                <Table>
                  <TableTitle onClick={handleRemoveFilterMonthWithMostMinutes}>Paciente</TableTitle>
                  <TableTitle onClick={handleRemoveFilterMonthWithMostMinutes}>Hospital</TableTitle>
                  {renderFilteredMonths()}
                  {renderPeriodColumn()}
                </Table>
                {renderPatients()}
              </PatientsContainer>
            </>
          )}
        </>
      )}
    </>
  );
};

export default Patients;
