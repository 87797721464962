import styled from "styled-components";

export const Title = styled.h2`
  margin: 15px 0;
  font-size: 26px;
  text-align: center;
`;
export const SoftwareFilterDiv = styled.div`
  margin: 0 auto 10px;
  width: 100%;
  max-width: 900px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;
export const EachSoftwareFilterDiv = styled.div`
  margin: 10px 10px;
`;
export const CheckboxInput = styled.input`
  cursor: pointer;
`;
export const CheckboxLabel = styled.label`
  margin: 0 0 0 10px;
  font-size: ${({ fontSize }) => fontSize};
  cursor: pointer;
`;
export const Select = styled.select`
  min-width: 150px;
  font-size: 16px;
`;
export const Option = styled.option``;
export const PeriodContainer = styled.div`
  margin: 0 auto 10px;
  width: 100%;
  max-width: 900px;
  display: flex;
  align-items: end;
`;
export const EachPeriodFilterDiv = styled.div`
  margin: 0 30px;
`;
export const HospitalCheckboxesDiv = styled.div`
  margin: 0 auto 20px;
  max-width: 95%;
  max-height: 140px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  border-radius: 5px;
  background-color: #c1c1c1;
  overflow: auto;
`;
export const ClearMarkedHospitalsDiv = styled.div`
  margin: auto;
  max-width: 95%;
`;
export const ClearMarkedHospitalsButton = styled.button`
  margin: 0 0 5px;
  padding: 10px;
  font-size: 14px;
  color: #000;
  background-color: #c1c1c1;
  outline: none;
  border: none;
  text-decoration: none;
  border-radius: 5px;
  cursor: pointer;
`;
export const SoftwareTitlesDiv = styled.div`
  margin: 0 auto;
  width: 1652px;
  display: flex;
  justify-content: center;
`;
export const TitleSectionDiv = styled.div`
  margin: 0 5px;
  width: 367px;
  display: flex;
  justify-content: center;
`;
export const SoftwareTitle = styled.p`
  font-size: 16px;
  font-weight: bold;
`;
export const ProtocolsDiv = styled.div`
  margin: 0 auto;
  width: 1652px;
  display: flex;
  justify-content: center;
`;
export const ProtocolsSectionDiv = styled.div`
  margin: 0 5px;
  padding: 10px 0 10px 10px;
  width: 367px;
  max-height: 200px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  background-color: #c1c1c1;
  overflow: auto;
`;
export const CheckAllProtocolsDiv = styled.div`
  margin-bottom: 10px;
  width: 100%;
  height: 20px;
`;
export const CheckboxAllProtocolsInput = styled.input`
  width: 20px;
  height: 20px;
  cursor: pointer;
`;
export const SoftwareProtocolsDiv = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;
export const EachProtocolDiv = styled.div`
  margin: 7px 0;
  padding-right: 2px;
  width: 50%;
  height: 18px;
`;
