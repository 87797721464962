import styled from "styled-components";
import { primaryColor, primaryColorHover } from "../../config/constants";
import { Link } from "react-router-dom";

export const ProjectsView = styled.div`
  padding-top: 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const Title = styled.h2`
  margin-bottom: 20px;
  font-size: 30px;
`;
export const ProjectsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const ProjectLink = styled(Link)`
  margin: 5px;
  padding: 15px;
  width: 240px;
  color: #fff;
  text-align: center;
  background-color: ${primaryColor};
  outline: none;
  border: none;
  text-decoration: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.15s ease-in-out;
  box-shadow: rgb(0 0 0 / 15%) 0px 5px 15px;
  &:hover {
    background-color: ${primaryColorHover};
  }
`;