import React from "react";
// Redux
import { useSelector } from "react-redux";
// Constants
import { pathHomePage } from "../../config/constants";
// Components
import { LinksContainer, ProjectLink } from "./styles";

const Header = () => {
  const userRole = useSelector(({ user }) => user.userRole);
  const environment = useSelector(({ database }) => database.environment);

  const renderGeneralHeader = () => {
    return (
      <LinksContainer>
        <ProjectLink to={`${pathHomePage}/home`}>Projects</ProjectLink>
        <ProjectLink to={`${pathHomePage}/hospitals`}>Hospitals</ProjectLink>
        <ProjectLink to={`${pathHomePage}/patients`}>Patients</ProjectLink>
        {(environment === "strack_production" || environment === "strack_development") && (
          <ProjectLink to={`${pathHomePage}/active-patients`}>Active patients per month</ProjectLink>
        )}
        <ProjectLink to={`${pathHomePage}/statistics`}>Statistics</ProjectLink>
        <ProjectLink to={`${pathHomePage}/patients-devices`}>Devices</ProjectLink>
        {userRole === "ADMIN" && (
          <>
            <ProjectLink to={`${pathHomePage}/protocol-management`}>Manage protocols</ProjectLink>
            <ProjectLink to={`${pathHomePage}/hospital-management`}>Manage hospitals</ProjectLink>
          </>
        )}
        <ProjectLink to={`${pathHomePage}/hospitals-by-project`}>Manage projects</ProjectLink>
      </LinksContainer>
    );
  };

  const renderProjectsHeader = () => {
    return (
      <LinksContainer>
        <ProjectLink to={`${pathHomePage}/hospitals-by-project`}>Manage projects</ProjectLink>
      </LinksContainer>
    );
  };

  const renderStrackHeader = () => {
    return (
      <LinksContainer>
        <ProjectLink to={`${pathHomePage}/active-patients`}>Pacientes activos por mes</ProjectLink>
        <ProjectLink to={`${pathHomePage}/clinician-activity`}>Actividad de Profesionales</ProjectLink>
      </LinksContainer>
    );
  };

  const renderHeader = () => {
    switch (userRole) {
      case "ADMIN":
        return renderGeneralHeader();
      case "GUEST":
        return renderGeneralHeader();
      case "PROJECTS":
        return renderProjectsHeader();
      case "STRACK":
        return renderStrackHeader();
      default:
        return null;
    }
  };

  return renderHeader();
};

export default Header;
