import axios from "axios";

const AxiosRequest = async (method, url, data) => {
  const token =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJBRE1JTl9EQVNIQk9BUkQiOnRydWV9.43FTV0jjnXFWeEY09oNpF0RrnANZfbaPpErcUbFSCGI";

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  switch (method.toLowerCase()) {
    case "get":
      return await axios[method](url, config);
    case "post":
      return await axios[method](url, data, config);
    case "put":
      return await axios[method](url, data, config);
    default:
      break;
  }
};

export default AxiosRequest;
