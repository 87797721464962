import React from "react";
// Components
import VerifySession from "../../../config/verifySession";
import AdminValidation from "../../../config/adminValidation";
import ApplicationsManagement from "../../../components/hospitalsManagement/sections/applicationController";

const ApplicationsManagementView = () => (
  <>
    {VerifySession()}
    {AdminValidation()}
    <ApplicationsManagement />
  </>
);

export default ApplicationsManagementView;
