import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
// Redux
import { useSelector } from "react-redux";
// Constants
import { pathHomePage, WEB_SERVICE_DASHBOARD_ROUTE } from "../../config/constants";
// Components
import Header from "../header";
import GoBack from "../goBack";
import Loader from "../loader";
import UseFetch from "../../config/useFetch";
import AdherenceWheels from "../adherenceWheels";

const PatientData = () => {
  const [isLoadingAdherenceObject, setIsLoadingAdherenceObject] = useState(true);
  const [sessionTimeRange, setSessionTimeRange] = useState("WEEK");
  const [adherenceObject, setAdherenceObject] = useState({});
  const { project, patientId } = useParams();
  const isLoggedIn = useSelector(({ user }) => user.isLoggedIn);
  const environment = useSelector(({ database }) => database.environment);

  // Fetch patient adherence
  useEffect(() => {
    setIsLoadingAdherenceObject(true);

    const getPatientAdherenceSignal = new AbortController();

    if (isLoggedIn && environment) {
      UseFetch(
        "get",
        `${WEB_SERVICE_DASHBOARD_ROUTE}/adherence/by-patient/${environment}/${patientId}/${sessionTimeRange}`,
        getPatientAdherenceSignal
      )
        .then(({ data }) => {
          setAdherenceObject(data);
          setIsLoadingAdherenceObject(false);
        })
        .catch((error) => {
          console.log("Get patient adherence: ", error);
        });
    }

    return () => {
      setAdherenceObject({});
      setIsLoadingAdherenceObject(true);
      if (getPatientAdherenceSignal) {
        getPatientAdherenceSignal.abort();
      }
    };
  }, [isLoggedIn, environment, patientId, sessionTimeRange]);

  const isLoading = isLoadingAdherenceObject;

  return (
    <>
      <Header />
      <GoBack path={`${pathHomePage}/hospitals-data/${project}`} />
      {isLoading ? (
        <Loader />
      ) : (
        <AdherenceWheels adherenceObject={adherenceObject} sessionTimeRange={sessionTimeRange} setSessionTimeRange={setSessionTimeRange} />
      )}
    </>
  );
};

export default PatientData;
