import React from "react";
// Components
import ClinicianActivityComponent from "../../components/clinicianActivity";
import VerifySession from "../../config/verifySession";

const ActivePatientsView = () => (
  <>
    {VerifySession()}
    <ClinicianActivityComponent />
  </>
);

export default ActivePatientsView;
