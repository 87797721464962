import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { toast } from "react-toastify";
// Redux
import { useSelector } from "react-redux";
// Constants
import { pathHomePage, WEB_SERVICE_DASHBOARD_ROUTE } from "../../../../config/constants";
// Components
import {
  Title,
  ProtocolInfoDiv,
  DataDiv,
  ColumnDataDiv,
  InputDataDiv,
  Label,
  Input,
  Select,
  AppsDiv,
  TitleTextDiv,
  TitleApps,
  DataAppsDiv,
  InputPositioner,
  InputDataAppsDiv,
  InputCheckbox,
  SubmitButtonDiv,
  SubmitButton,
} from "./styles";
import Header from "../../../header";
import GoBack from "../../../goBack";
import Axios from "../../../../config/axios";

const CreateProtocol = () => {
  const [protocolName, setProtocolName] = useState("");
  const [protocolNameError, setProtocolNameError] = useState(false);
  const [protocolDescription, setProtocolDescription] = useState("");
  const [protocolDescriptionError, setProtocolDescriptionError] = useState(false);
  const [protocolId, setProtocolId] = useState("");
  const [protocolIdError, setProtocolIdError] = useState(false);
  const [dbProtocolTypes, setDbProtocolTypes] = useState([]);
  const [protocolType, setProtocolType] = useState(0);
  const [protocolTypeError, setProtocolTypeError] = useState(false);
  const [dbSoftwares, setDbSoftwares] = useState([]);
  const [protocolSoftwares, setProtocolSoftwares] = useState([]);
  const [redirectToProtocolsManagement, setRedirectToProtocolsManagement] = useState(false);
  const isLoggedIn = useSelector(({ user }) => user.isLoggedIn);
  const environment = useSelector(({ database }) => database.environment);

  // Fetching protocol types
  useEffect(() => {
    if (isLoggedIn && environment !== "") {
      Axios("get", `${WEB_SERVICE_DASHBOARD_ROUTE}/protocol/types/${environment}`)
        .then(({ data }) => {
          setDbProtocolTypes(data);
        })
        .catch((error) => {
          console.log("endpoint to get protocol types: ", error);
        });
    }
  }, [isLoggedIn, environment]);

  // Fetching softwares
  useEffect(() => {
    if (isLoggedIn && environment !== "") {
      Axios("get", `${WEB_SERVICE_DASHBOARD_ROUTE}/software/list/${environment}`)
        .then(({ data }) => {
          setDbSoftwares(data);
        })
        .catch((error) => {
          console.log("endpoint to get softwares: ", error);
        });
    }
  }, [isLoggedIn, environment]);

  const handleCreateProtocol = (e) => {
    e.preventDefault();
    if (protocolName === "") {
      setProtocolNameError(true);
      return toast.error("You must enter a protocol name");
    }
    if (protocolDescription === "") {
      setProtocolDescriptionError(true);
      return toast.error("You must enter a description");
    }
    if (protocolId === "") {
      setProtocolIdError(true);
      return toast.error("You must enter a protocol id");
    }
    if (protocolType === 0) {
      setProtocolTypeError(true);
      return toast.error("You must select a protocol type");
    }
    if (protocolSoftwares.length < 1) {
      setProtocolTypeError(true);
      return toast.error("You must select at least one application");
    }
    const protocolData = {
      id: protocolId,
      name: protocolName,
      description: protocolDescription,
      protocolType,
      protocolSoftwares,
    };
    Axios("post", `${WEB_SERVICE_DASHBOARD_ROUTE}/protocol/add/${environment}`, protocolData)
      .then(({ data }) => {
        if (data.AddedProtocol) {
          toast.success("Protocol created successfully");
          setRedirectToProtocolsManagement(true);
        }
      })
      .catch((error) => {
        toast.error("Error sending data, try again");
        console.log("Add protocol: ", error);
      });
  };
  if (redirectToProtocolsManagement) {
    return <Redirect to={`${pathHomePage}/protocol-management`} />;
  }

  const onChangeInput = (e, setInput, setInputError) => {
    setInput(e.target.value);
    setInputError(false);
  };

  const onChangeSelect = (e) => {
    setProtocolType(Number(e.target.value));
    setProtocolTypeError(false);
  };

  const handleSetSoftware = (softwareId) => {
    const clonedProtocolSoftwares = JSON.parse(JSON.stringify(protocolSoftwares));
    if (clonedProtocolSoftwares.includes(softwareId)) {
      const index = clonedProtocolSoftwares.indexOf(softwareId);
      clonedProtocolSoftwares.splice(index, 1);
      setProtocolSoftwares(clonedProtocolSoftwares);
    } else {
      clonedProtocolSoftwares.push(softwareId);
      setProtocolSoftwares(clonedProtocolSoftwares);
    }
  };

  const renderAddProtocolForm = () => {
    return (
      <ProtocolInfoDiv>
        <DataDiv>
          <ColumnDataDiv>
            <InputDataDiv>
              <Label htmlFor="protocolName" fontWeight="bold">
                Name *
              </Label>
              <Input
                type="text"
                id="protocolName"
                value={protocolName}
                onChange={(e) => onChangeInput(e, setProtocolName, setProtocolNameError)}
                error={protocolNameError}
              />
            </InputDataDiv>
            <InputDataDiv>
              <Label htmlFor="protocolDescription" fontWeight="bold">
                Description *
              </Label>
              <Input
                type="text"
                id="protocolDescription"
                value={protocolDescription}
                onChange={(e) => onChangeInput(e, setProtocolDescription, setProtocolDescriptionError)}
                error={protocolDescriptionError}
              />
            </InputDataDiv>
          </ColumnDataDiv>
          <ColumnDataDiv>
            <InputDataDiv>
              <Label htmlFor="protocolId" fontWeight="bold">
                Id *
              </Label>
              <Input
                type="text"
                id="protocolId"
                value={protocolId}
                onChange={(e) => onChangeInput(e, setProtocolId, setProtocolIdError)}
                error={protocolIdError}
              />
            </InputDataDiv>
            <InputDataDiv>
              <Label htmlFor="protocolDescription" fontWeight="bold">
                Type *
              </Label>
              <Select value={protocolType} onChange={onChangeSelect} error={protocolTypeError}>
                <option value={0}></option>
                {dbProtocolTypes.map((protocolType) => {
                  return (
                    <option value={protocolType.PROTOCOL_TYPE_ID} key={protocolType.PROTOCOL_TYPE_ID}>
                      {protocolType.PROTOCOL_TYPE}
                    </option>
                  );
                })}
              </Select>
            </InputDataDiv>
          </ColumnDataDiv>
        </DataDiv>
        <AppsDiv>
          <TitleTextDiv>
            <TitleApps>RGS Applications</TitleApps>
          </TitleTextDiv>
          <DataAppsDiv justifyContent={dbSoftwares.length > 1}>
            {dbSoftwares.map((software) => {
              const softwareName = software.NAME;
              const softwareId = software.SOFTWARE_ID;
              const verifySoftware = protocolSoftwares.includes(softwareId);
              return (
                <InputPositioner key={softwareId}>
                  <InputDataAppsDiv>
                    <Label htmlFor={softwareName}>{softwareName}</Label>
                    <InputCheckbox
                      type="checkbox"
                      id={softwareName}
                      checked={verifySoftware}
                      onChange={() => handleSetSoftware(softwareId)}
                    />
                  </InputDataAppsDiv>
                </InputPositioner>
              );
            })}
          </DataAppsDiv>
        </AppsDiv>
        <SubmitButtonDiv>
          <SubmitButton onClick={handleCreateProtocol}>Save</SubmitButton>
        </SubmitButtonDiv>
      </ProtocolInfoDiv>
    );
  };

  return (
    <>
      <Header />
      <GoBack path={`${pathHomePage}/protocol-management`} />
      <Title>Create new protocol</Title>
      {renderAddProtocolForm()}
    </>
  );
};

export default CreateProtocol;
