import React from "react";
import styled from "styled-components";
import {Oval} from "react-loader-spinner";
// Constants
import { primaryColor } from "../../config/constants";

export const LoaderDiv = styled.div`
  margin: 20px auto;
  width: 100%;
  max-width: 100px;
`;

const LoaderComponent = () => (
  <LoaderDiv>
    <Oval color={primaryColor} secondaryColor={primaryColor} height={100} width={100} />
  </LoaderDiv>
);

export default LoaderComponent;
