import styled from "styled-components";

export const PatientsDiv = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const SessionsFilterDiv = styled.div`
  margin-top: 19px;
  padding: 10px;
  height: fit-content;
  width: fit-content;
  display: flex;
  border-radius: 5px;
  background-color: #c1c1c1;
`;
export const SessionsFilterText = styled.label`
  margin-right: 10px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
`;
export const SessionsFilterCheckbox = styled.input`
  width: 20px;
  height: 20px;
  cursor: pointer;
`;
export const PatientsSectionContainer = styled.div`
  display: flex;
  justify-content: center;
`;
export const PatientsSectionDiv = styled.div`
  display: flex;
  flex-direction: column;
`;
export const TitleDiv = styled.div`
  margin: 0 5px;
  width: 367px;
  display: flex;
  justify-content: center;
`;
export const Title = styled.p`
  font-size: 16px;
  font-weight: bold;
`;
export const Body = styled.div`
  margin: 0 5px;
  padding: 10px 0 10px 10px;
  width: 367px;
  max-height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 5px;
  background-color: #c1c1c1;
  overflow: auto;
`;
export const CheckAllPatientsDiv = styled.div`
  margin-bottom: 10px;
  width: 100%;
  height: 20px;
`;
export const AllPatientsCheckbox = styled.input`
  width: 20px;
  height: 20px;
  cursor: pointer;
`;
export const PatientsCheckboxDiv = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;
export const EachPatientDiv = styled.div`
  margin: 7px 0;
  padding-right: 2px;
  width: 50%;
  height: 18px;
`;
export const CheckboxInput = styled.input`
  cursor: pointer;
`;
export const CheckboxLabel = styled.label`
  margin: 0 0 0 10px;
  font-size: ${({ fontSize }) => fontSize};
  cursor: pointer;
`;
