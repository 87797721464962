import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
// Redux
import { useSelector } from "react-redux";
// Constants
import { pathHomePage, WEB_SERVICE_DASHBOARD_ROUTE } from "../../../../config/constants";
// Components
import { Title, CreateStationDiv, CreateStationLink, TableContainer, TableTitle, StationContainer, StationInfo } from "./styles";
import Header from "../../../header";
import GoBack from "../../../goBack";
import Loader from "../../../loader";
import Axios from "../../../../config/axios";

const StationController = () => {
  const [isLoadingStations, setIsLoadingStations] = useState(true);
  const [stations, setStations] = useState([]);
  const { hospitalId } = useParams();
  const environment = useSelector(({ database }) => database.environment);
  const currentHospitalName = useSelector(({ hospital }) => hospital.currentName);

  // Fetching stations by hospital
  useEffect(() => {
    Axios("get", `${WEB_SERVICE_DASHBOARD_ROUTE}/station/get/${environment}/${hospitalId}`)
      .then(({ data }) => {
        setStations(data);
        setIsLoadingStations(false);
      })
      .catch((error) => {
        console.log("Endpoint to get hospital stations: ", error);
      });
  }, [environment, hospitalId]);

  const renderNewStationButton = () => {
    return (
      <CreateStationDiv>
        <CreateStationLink to={`${pathHomePage}/create-station/${hospitalId}`}>Crear nueva estación</CreateStationLink>
      </CreateStationDiv>
    );
  };

  const renderTableHeader = () => {
    return (
      <TableContainer>
        <TableTitle>ID de Estación</TableTitle>
        <TableTitle>Aplicación</TableTitle>
        <TableTitle>Current version</TableTitle>
        <TableTitle>Target version</TableTitle>
      </TableContainer>
    );
  };

  const renderStations = () => {
    return stations.map((station, i) => {
      return (
        <StationContainer key={i}>
          <StationInfo>{station.STATION_ID}</StationInfo>
          <StationInfo>{station.SOFTWARE}</StationInfo>
          <StationInfo>{station.CURRENT_VERSION}</StationInfo>
          <StationInfo>{station.TARGET_VERSION}</StationInfo>
        </StationContainer>
      );
    });
  };

  return (
    <>
      <Header />
      <GoBack path={`${pathHomePage}/intermediate-management/${hospitalId}`} />
      {isLoadingStations ? (
        <Loader />
      ) : (
        <>
          <Title>{`Estaciones del hospital: ${currentHospitalName}`}</Title>
          {renderNewStationButton()}
          {stations.length ? (
            <>
              {renderTableHeader()}
              {renderStations()}
            </>
          ) : (
            <Title fontSize="20px">No hay estaciones registradas</Title>
          )}
        </>
      )}
    </>
  );
};

export default StationController;
