import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { toast } from "react-toastify";
// Redux
import { useDispatch } from "react-redux";
import { validateLogin } from "../../redux/userDucks";
import { setDataBaseEnvironment } from "../../redux/dbDucks";
// Constants
import { pathHomePage } from "../../config/constants";
// Credentials
import ADMIN_PIN_PATH from "../../config/credentials/admin_user.pem";
import GUEST_PIN_PATH from "../../config/credentials/guest_user.pem";
import PROJECTS_PIN_PATH from "../../config/credentials/projects_user.pem";
import STRACK_PIN_PATH from "../../config/credentials/strack_user.pem";
// Components
import { LoginContainer, LogoMimsImg, LogoSeparator, Form, LoginForm, Label, Input, ButtonSubmit } from "./styles";
// Assets
import EodyneLogo from "../../assets/logo_eodyne.svg";

const Login = () => {
  const [ADMIN_PIN, setADMIN_PIN] = useState("");
  const [GUEST_PIN, setGUEST_PIN] = useState("");
  const [PROJECTS_PIN, setPROJECTS_PIN] = useState("");
  const [STRACK_PIN, setSTRACK_PIN] = useState("");
  const [pin, setPin] = useState("");
  const [redirectToProjects, setRedirectToProjects] = useState(false);
  const [redirectToHospitalsByProjects, setRedirectToHospitalsByProjects] = useState(false);
  const [redirectToActivePatients, setRedirectToActivePatients] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const getCredential = (path, setCredential) => {
      fetch(path)
        .then((r) => r.text())
        .then((text) => setCredential(text));
    };
    getCredential(ADMIN_PIN_PATH, setADMIN_PIN);
    getCredential(GUEST_PIN_PATH, setGUEST_PIN);
    getCredential(PROJECTS_PIN_PATH, setPROJECTS_PIN);
    getCredential(STRACK_PIN_PATH, setSTRACK_PIN);
  }, []);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleLogin(e);
    }
  };

  const handleLogin = (e) => {
    e.preventDefault();
    if (pin === "") return toast.error("Debes ingresar el pin");
    if (pin !== ADMIN_PIN && pin !== GUEST_PIN && pin !== PROJECTS_PIN && pin !== STRACK_PIN) return toast.error("Pin incorrecto");
    switch (pin) {
      case ADMIN_PIN:
        dispatch(validateLogin("ADMIN"));
        break;
      case GUEST_PIN:
        dispatch(validateLogin("GUEST"));
        break;
      case PROJECTS_PIN:
        dispatch(setDataBaseEnvironment("global_production"));
        dispatch(validateLogin("PROJECTS"));
        return setRedirectToHospitalsByProjects(true);
      case STRACK_PIN:
        dispatch(setDataBaseEnvironment("strack_production"));
        dispatch(validateLogin("STRACK"));
        return setRedirectToActivePatients(true);
      default:
        dispatch(validateLogin("GUEST"));
        break;
    }
    return setRedirectToProjects(true);
  };
  if (redirectToActivePatients) {
    return <Redirect to={`${pathHomePage}/active-patients`} />;
  }
  if (redirectToHospitalsByProjects) {
    return <Redirect to={`${pathHomePage}/hospitals-by-project`} />;
  }
  if (redirectToProjects) {
    return <Redirect to={`${pathHomePage}/home`} />;
  }

  return (
    <LoginContainer>
      <LoginForm>
        <LogoMimsImg src={EodyneLogo} alt="logo-mims" />
        <LogoSeparator />
        <Form onSubmit={handleLogin} autoComplete="off">
          <Label>PIN</Label>
          <Input
            type="password"
            placeholder="****"
            value={pin}
            onChange={(e) => setPin(e.target.value)}
            onKeyPress={handleKeyPress}
            autoComplete={"new-password"}
          />
          <ButtonSubmit type="submit">Entrar</ButtonSubmit>
        </Form>
      </LoginForm>
    </LoginContainer>
  );
};

export default Login;
