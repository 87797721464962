import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
// Redux
import { useSelector } from "react-redux";
// Constants
import { pathHomePage } from "./constants";

const ValidateSession = () => {
  const [redirectToHome, setRedirectToHome] = useState(false);
  const isLoggedIn = useSelector(({ user }) => user.isLoggedIn);

  useEffect(() => {
    if (!isLoggedIn) {
      setRedirectToHome(true);
    }
  }, [isLoggedIn]);
  if (redirectToHome) {
    return <Redirect to={`${pathHomePage}`} />;
  }
};

export default ValidateSession;
