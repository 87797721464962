import React from "react";
// Components
import VerifySession from "../../../config/verifySession";
import AdminValidation from "../../../config/adminValidation";
import ProtocolsManagement from "../../../components/hospitalsManagement/sections/protocolController";

const ProtocolsManagementView = () => (
  <>
    {VerifySession()}
    {AdminValidation()}
    <ProtocolsManagement />
  </>
);

export default ProtocolsManagementView;
