import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { toast } from "react-toastify";
// Redux
import { useSelector } from "react-redux";
// Constants
import { pathHomePage, WEB_SERVICE_DASHBOARD_ROUTE } from "../../../../../config/constants";
// Components
import { Title, HospitalInfoDiv, DataDiv, ColumnDataDiv, InputDataDiv, Label, Input, SubmitButton, SubmitButtonDiv } from "./styles";
import GoBack from "../../../../goBack";
import Header from "../../../../header";
import Axios from "../../../../../config/axios";

const CreateHospital = () => {
  const [hospitalName, setHospitalName] = useState("");
  const [hospitalNameError, setHospitalNameError] = useState(false);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [contact, setContact] = useState("");
  const [address, setAddress] = useState("");
  const [isUkrainianHospital, setIsUkrainianHospital] = useState(false);
  const [redirectToHospitalsManagement, setRedirectToHospitalsManagement] = useState(false);
  const environment = useSelector(({ database }) => database.environment);

  const handleCreateHospital = (e) => {
    e.preventDefault();
    if (hospitalName === "") {
      setHospitalNameError(true);
      return toast.error("You must enter a hospital name");
    }
    const hospitalData = {
      NAME: hospitalName,
      EMAIL: email === "" ? null : email,
      PHONE: phone === "" ? null : phone,
      CONTACT: contact === "" ? null : contact,
      ADDRESS: address === "" ? null : address,
      isUkrainianHospital,
    };
    Axios("post", `${WEB_SERVICE_DASHBOARD_ROUTE}/hospital/add/${environment}`, hospitalData)
      .then(({ data }) => {
        if (data.AddedHospital) {
          toast.success("Hospital created successfully");
          setRedirectToHospitalsManagement(true);
        }
      })
      .catch((error) => {
        toast.error("Error sending data, try again");
        console.log("Add hospital: ", error);
      });
  };
  if (redirectToHospitalsManagement) {
    return <Redirect to={`${pathHomePage}/hospital-management`} />;
  }

  const onChangeHospitalName = (e) => {
    setHospitalName(e.target.value);
    setHospitalNameError(false);
  };

  const renderAddHospitalForm = () => {
    return (
      <HospitalInfoDiv>
        <DataDiv>
          <ColumnDataDiv>
            <InputDataDiv>
              <Label htmlFor="hospitalName" fontWeight="bold">
                Hospital name *
              </Label>
              <Input type="text" id="hospitalName" value={hospitalName} onChange={onChangeHospitalName} error={hospitalNameError} />
            </InputDataDiv>
            <InputDataDiv>
              <Label htmlFor="email" fontWeight="bold">
                Email
              </Label>
              <Input type="text" id="email" value={email} onChange={(e) => setEmail(e.target.value)} padding="5px 32px 5px 10px" />
            </InputDataDiv>
            <InputDataDiv>
              <Label htmlFor="phone" fontWeight="bold">
                Phone
              </Label>
              <Input type="text" id="phone" value={phone} onChange={(e) => setPhone(e.target.value)} padding="5px 32px 5px 10px" />
            </InputDataDiv>
          </ColumnDataDiv>
          <ColumnDataDiv>
            <InputDataDiv>
              <Label htmlFor="contact" fontWeight="bold">
                Contact
              </Label>
              <Input type="text" id="contact" value={contact} onChange={(e) => setContact(e.target.value)} padding="5px 32px 5px 10px" />
            </InputDataDiv>
            <InputDataDiv>
              <Label htmlFor="address" fontWeight="bold">
                Address
              </Label>
              <Input type="text" id="address" value={address} onChange={(e) => setAddress(e.target.value)} padding="5px 32px 5px 10px" />
            </InputDataDiv>
            {environment === "global_production" && (
              <InputDataDiv>
                <Label htmlFor="isUkrainianHospital" fontWeight="bold">
                  Is this a Ukrainian hospital?
                </Label>
                <input
                  type="checkbox"
                  id="isUkrainianHospital"
                  value={isUkrainianHospital}
                  onChange={() => setIsUkrainianHospital(!isUkrainianHospital)}
                  padding="5px 32px 5px 10px"
                />
              </InputDataDiv>
            )}
          </ColumnDataDiv>
        </DataDiv>
        <SubmitButtonDiv>
          <SubmitButton onClick={handleCreateHospital}>Save</SubmitButton>
        </SubmitButtonDiv>
      </HospitalInfoDiv>
    );
  };

  return (
    <>
      <Header />
      <GoBack path={`${pathHomePage}/hospital-management`} />
      <Title>Create new hospital</Title>
      {renderAddHospitalForm()}
    </>
  );
};

export default CreateHospital;
