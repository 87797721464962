// ----- State -----
const initialState = {
  isLoggedIn: false,
  userRole: "",
};

// ----- Types -----
// LOGIN
const CLINICIAN_LOGIN_DONE = "CLINICIAN_LOGIN_DONE";

// ----- Reducer -----
export default function reducer(state = initialState, action) {
  switch (action.type) {
    // LOGIN
    case CLINICIAN_LOGIN_DONE:
      return {
        ...state,
        isLoggedIn: true,
        userRole: action.payload.userRole,
      };
    default:
      return state;
  }
}

// ----- Actions -----
// LOGIN
export const validateLogin = (userRole) => (dispatch) => {
  dispatch({
    type: CLINICIAN_LOGIN_DONE,
    payload: {
      userRole,
    },
  });
};
