import React from "react";
// Redux
import { useDispatch } from "react-redux";
import { setDataBaseEnvironment } from "../../redux/dbDucks";
// Constants
import { pathHomePage } from "../../config/constants";
// Components
import { ProjectsView, Title, ProjectsContainer, ProjectLink, ProjectsSeparator } from "./styles";

const Projects = () => {
  const dispatch = useDispatch();

  const handleSetDataBaseEnvironment = (environment) => {
    dispatch(setDataBaseEnvironment(environment));
  };

  return (
    <ProjectsView>
      <Title>Projects: MIMS</Title>
      <ProjectsContainer>
        <ProjectLink
          onClick={() => {
            handleSetDataBaseEnvironment("global_production");
          }}
          to={`${pathHomePage}/hospitals`}
        >
          Global Production
        </ProjectLink>
        <ProjectLink
          onClick={() => {
            handleSetDataBaseEnvironment("global_beta");
          }}
          to={`${pathHomePage}/hospitals`}
        >
          Global Beta
        </ProjectLink>
        <ProjectLink
          onClick={() => {
            handleSetDataBaseEnvironment("global_development");
          }}
          to={`${pathHomePage}/hospitals`}
        >
          Global Development
        </ProjectLink>
        <ProjectsSeparator />
        <ProjectLink
          onClick={() => {
            handleSetDataBaseEnvironment("strack_production");
          }}
          to={`${pathHomePage}/hospitals`}
        >
          Strack Production
        </ProjectLink>
        <ProjectLink
          onClick={() => {
            handleSetDataBaseEnvironment("strack_development");
          }}
          to={`${pathHomePage}/hospitals`}
        >
          Strack Development
        </ProjectLink>
      </ProjectsContainer>
    </ProjectsView>
  );
};

export default Projects;
