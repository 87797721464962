import React from "react";
// Components
import VerifySession from "../../../config/verifySession";
import AdminValidation from "../../../config/adminValidation";
import EditHospitalComponent from "../../../components/hospitalsManagement/sections/hospitalController/editHospital";

const EditHospitalView = () => (
  <>
    {VerifySession()}
    {AdminValidation()}
    <EditHospitalComponent />
  </>
);

export default EditHospitalView;
