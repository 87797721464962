import styled from "styled-components";

export const GoBackDiv = styled.div`
  margin: 20px auto 0;
  width: 100%;
  max-width: 860px;
  display: flex;
  justify-content: flex-start;
`;
export const GoBackLink = styled.button`
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
`;
export const GoBackImg = styled.img`
  width: 30px;
  height: 30px;
`;
