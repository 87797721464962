import React from "react";
// Components
import VerifySession from "../../config/verifySession";
import AdminValidation from "../../config/adminValidation";
import HospitalsManagement from "../../components/hospitalsManagement";

const HospitalsManagementView = () => (
  <>
    {VerifySession()}
    {AdminValidation()}
    <HospitalsManagement />
  </>
);

export default HospitalsManagementView;
