import React, { useState, useEffect } from "react";
import { useParams, Redirect } from "react-router-dom";
import { toast } from "react-toastify";
// Redux
import { useSelector } from "react-redux";
// Constants
import { pathHomePage, WEB_SERVICE_DASHBOARD_ROUTE } from "../../../../config/constants";
// Components
import {
  Title,
  ProtocolInfoDiv,
  DataDiv,
  ColumnDataDiv,
  InputDataDiv,
  Label,
  Input,
  Select,
  SubmitButtonDiv,
  SubmitButton,
} from "./styles";
import Header from "../../../header";
import GoBack from "../../../goBack";
import Loader from "../../../loader";
import Axios from "../../../../config/axios";

const EditProtocol = () => {
  const [isLoadingProtocol, setIsLoadingProtocol] = useState(true);
  const [isLoadingProtocolTypes, setIsLoadingProtocolTypes] = useState(true);
  const [protocolName, setProtocolName] = useState("");
  const [protocolNameError, setProtocolNameError] = useState(false);
  const [protocolDescription, setProtocolDescription] = useState("");
  const [protocolDescriptionError, setProtocolDescriptionError] = useState(false);
  const [dbProtocolTypes, setDbProtocolTypes] = useState([]);
  const [protocolType, setProtocolType] = useState(0);
  const [protocolTypeError, setProtocolTypeError] = useState(false);
  const [redirectToProtocolsManagement, setRedirectToProtocolsManagement] = useState(false);
  const { protocolId } = useParams();
  const isLoggedIn = useSelector(({ user }) => user.isLoggedIn);
  const environment = useSelector(({ database }) => database.environment);

  // Fetching protocol data by id
  useEffect(() => {
    if (isLoggedIn && environment !== "") {
      Axios("get", `${WEB_SERVICE_DASHBOARD_ROUTE}/protocol/get-protocol-by-id/${environment}/${protocolId}`)
        .then(({ data }) => {
          const protocol = data[0];
          setProtocolName(protocol.NAME_KEY && protocol.NAME_KEY);
          setProtocolDescription(protocol.DESCRIPTION_KEY && protocol.DESCRIPTION_KEY);
          setProtocolType(protocol.PROTOCOL_TYPE_ID && protocol.PROTOCOL_TYPE_ID);
          setIsLoadingProtocol(false);
        })
        .catch((error) => {
          console.log("Get hospital by id: ", error);
        });
    }
  }, [isLoggedIn, environment, protocolId]);

  // Fetching protocol types
  useEffect(() => {
    if (isLoggedIn && environment !== "") {
      Axios("get", `${WEB_SERVICE_DASHBOARD_ROUTE}/protocol/types/${environment}`)
        .then(({ data }) => {
          setDbProtocolTypes(data);
          setIsLoadingProtocolTypes(false);
        })
        .catch((error) => {
          console.log("Get protocol types: ", error);
        });
    }
  }, [isLoggedIn, environment]);

  const handleUpdateProtocol = (e) => {
    e.preventDefault();
    if (protocolName === "") {
      setProtocolNameError(true);
      return toast.error("You must enter a protocol name");
    }
    if (protocolDescription === "") {
      setProtocolDescriptionError(true);
      return toast.error("You must enter a description");
    }
    if (protocolType === 0) {
      setProtocolTypeError(true);
      return toast.error("You must select a protocol type");
    }
    const protocolData = {
      NAME_KEY: protocolName,
      DESCRIPTION_KEY: protocolDescription,
      PROTOCOL_TYPE_ID: protocolType,
    };
    Axios("put", `${WEB_SERVICE_DASHBOARD_ROUTE}/protocol/update/${environment}/${protocolId}`, protocolData)
      .then(({ data }) => {
        if (data.updatedProtocol) {
          toast.success("Protocol updated successfully");
          setRedirectToProtocolsManagement(true);
        }
      })
      .catch((error) => {
        toast.error("Error sending data, try again");
        console.log("Update protocol: ", error);
      });
  };
  if (redirectToProtocolsManagement) {
    return <Redirect to={`${pathHomePage}/protocol-management`} />;
  }

  const onChangeInput = (e, setInput, setInputError) => {
    setInput(e.target.value);
    setInputError(false);
  };

  const onChangeSelect = (e) => {
    setProtocolType(Number(e.target.value));
    setProtocolTypeError(false);
  };

  const renderUpdateProtocolForm = () => {
    return (
      <ProtocolInfoDiv>
        <DataDiv>
          <ColumnDataDiv>
            <InputDataDiv>
              <Label htmlFor="protocolName" fontWeight="bold">
                Name *
              </Label>
              <Input
                type="text"
                id="protocolName"
                value={protocolName}
                onChange={(e) => onChangeInput(e, setProtocolName, setProtocolNameError)}
                error={protocolNameError}
              />
            </InputDataDiv>
            <InputDataDiv>
              <Label htmlFor="protocolDescription" fontWeight="bold">
                Description *
              </Label>
              <Input
                type="text"
                id="protocolDescription"
                value={protocolDescription}
                onChange={(e) => onChangeInput(e, setProtocolDescription, setProtocolDescriptionError)}
                error={protocolDescriptionError}
              />
            </InputDataDiv>
          </ColumnDataDiv>
          <ColumnDataDiv>
            <InputDataDiv>
              <Label htmlFor="protocolDescription" fontWeight="bold">
                Type *
              </Label>
              <Select value={protocolType} onChange={onChangeSelect} error={protocolTypeError}>
                <option value={0}></option>
                {dbProtocolTypes.map((protocolType) => {
                  return (
                    <option value={protocolType.PROTOCOL_TYPE_ID} key={protocolType.PROTOCOL_TYPE_ID}>
                      {protocolType.PROTOCOL_TYPE}
                    </option>
                  );
                })}
              </Select>
            </InputDataDiv>
          </ColumnDataDiv>
        </DataDiv>
        <SubmitButtonDiv>
          <SubmitButton onClick={handleUpdateProtocol}>Save</SubmitButton>
        </SubmitButtonDiv>
      </ProtocolInfoDiv>
    );
  };

  return (
    <>
      <Header />
      <GoBack path={`${pathHomePage}/protocol-management`} />
      {isLoadingProtocol || isLoadingProtocolTypes ? (
        <Loader />
      ) : (
        <>
          <Title>Update protocol</Title>
          {renderUpdateProtocolForm()}
        </>
      )}
    </>
  );
};

export default EditProtocol;
