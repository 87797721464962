import React, { useState, useEffect } from "react";
import moment from "moment";
import { Line } from "react-chartjs-2";
// Redux
import { useSelector } from "react-redux";
// Constants - Functions
import { WEB_SERVICE_DASHBOARD_ROUTE, colorsArray, applications } from "../../config/constants";
import { renderMonthsOptions, renderYearsOptions, returnProtocolTitle, setCurrentPeriodOfMonths } from "../../config/functions";
// Components
import {
  Title,
  SoftwareFilterDiv,
  EachSoftwareFilterDiv,
  CheckboxInput,
  CheckboxLabel,
  Select,
  PeriodContainer,
  EachPeriodFilterDiv,
  HospitalCheckboxesDiv,
  ClearMarkedHospitalsDiv,
  ClearMarkedHospitalsButton,
  SoftwareTitlesDiv,
  TitleSectionDiv,
  SoftwareTitle,
  ProtocolsDiv,
  ProtocolsSectionDiv,
  CheckAllProtocolsDiv,
  CheckboxAllProtocolsInput,
  SoftwareProtocolsDiv,
  EachProtocolDiv,
} from "./styles";
import Header from "../header";
import Loader from "../loader";
import UseFetch from "../../config/useFetch";
import Graphic from "../graphic";
import PatientsAdherence from "./sections/patientsAdherence";

const Statistics = () => {
  const [isLoadingHospitals, setIsLoadingHospitals] = useState(true);
  const [isLoadingPatients, setIsLoadingPatients] = useState(true);
  const [hospitals, setHospitals] = useState([]);
  const [markedHospitals, setMarkedHospitals] = useState([]);
  const [targetedHospitals, setTargetedHospitals] = useState([]);
  const [dynamicHospitalsColor, setDynamicHospitalsColor] = useState(JSON.parse(JSON.stringify(colorsArray)));
  const [patients, setPatients] = useState([]);

  const [clinicProtocols, setClinicProtocols] = useState([]);
  const [homeProtocols, setHomeProtocols] = useState([]);
  const [webProtocols, setWebProtocols] = useState([]);
  const [appProtocols, setAppProtocols] = useState([]);

  const [allProtocols, setAllProtocols] = useState([]);
  const [markedProtocols, setMarkedProtocols] = useState([]);

  const [currentPeriodFiltered, setCurrentPeriodFiltered] = useState([]);
  const [targetedPeriod, setTargetedPeriod] = useState([]);

  const [filterStartMonth, setFilterStartMonth] = useState(0);
  const [filterStartYear, setFilterStartYear] = useState(0);
  const [filterEndMonth, setFilterEndMonth] = useState(0);
  const [filterEndYear, setFilterEndYear] = useState(0);

  const [isUkrainianHospitalsChecked, setIsUkrainianHospitalsChecked] = useState(false);
  const isLoggedIn = useSelector(({ user }) => user.isLoggedIn);
  const environment = useSelector(({ database }) => database.environment);

  // Fetching hospitals data
  useEffect(() => {
    setIsLoadingHospitals(true);
    const getHospitalsSignal = new AbortController();

    if (isLoggedIn && environment) {
      const body = {
        addUkrainianHospitals: isUkrainianHospitalsChecked,
      };
      UseFetch(
        "post",
        `${WEB_SERVICE_DASHBOARD_ROUTE}/hospital/get-hospital-and-patients-sessions/${environment}`,
        getHospitalsSignal,
        body
      )
        .then(({ data }) => {
          data.sort((a, b) => (a.NAME.toLowerCase() < b.NAME.toLowerCase() ? -1 : 1));

          let indexColor = 0;
          data.forEach((hospital) => {
            if (indexColor >= colorsArray.length) indexColor = 0;
            hospital.baseColor = colorsArray[indexColor];
            indexColor++;
          });

          setHospitals(data);
          setIsLoadingHospitals(false);
        })
        .catch((error) => {
          console.log("Get hospitals: ", error);
        });
    }

    return () => {
      setHospitals([]);
      setIsLoadingHospitals(true);
      if (getHospitalsSignal) {
        getHospitalsSignal.abort();
      }
    };
  }, [isLoggedIn, environment, isUkrainianHospitalsChecked]);

  // Fetching patients data
  useEffect(() => {
    setIsLoadingPatients(true);
    const getPatientsSignal = new AbortController();

    if (isLoggedIn && environment) {
      const body = {
        addUkrainianHospitals: isUkrainianHospitalsChecked,
      };
      UseFetch("post", `${WEB_SERVICE_DASHBOARD_ROUTE}/patient/get-patients-with-sessions/${environment}`, getPatientsSignal, body)
        .then(({ data }) => {
          // Setting a new field: first session
          data.forEach((patient) => {
            let dateToCompare = new Date();
            let firstSession;
            patient.sessions.forEach((session) => {
              const currentSessionDate = new Date(moment(`${session.SESSION_YEAR}-${session.SESSION_MONTH}-01`, "YYYY-MM-DD"));
              if (currentSessionDate < dateToCompare) {
                firstSession = {
                  year: session.SESSION_YEAR,
                  month: session.SESSION_MONTH,
                };
                return (dateToCompare = currentSessionDate);
              }
            });
            patient.firstSession = firstSession;
          });
          setPatients(data);
          setIsLoadingPatients(false);
        })
        .catch((error) => {
          console.log("Get patients: ", error);
        });
    }

    return () => {
      setPatients([]);
      setIsLoadingPatients(true);
      if (getPatientsSignal) {
        getPatientsSignal.abort();
      }
    };
  }, [isLoggedIn, environment, isUkrainianHospitalsChecked]);

  // Setting date filter
  useEffect(() => {
    const initialNumberOfMonthsAgo = 5;
    setFilterStartMonth(Number(moment().subtract(initialNumberOfMonthsAgo, "months").format("MM")));
    setFilterStartYear(Number(moment().subtract(initialNumberOfMonthsAgo, "months").format("YYYY")));
    setFilterEndMonth(Number(moment().format("MM")));
    setFilterEndYear(Number(new Date().getFullYear()));
  }, []);

  // Fetching protocols by software
  useEffect(() => {
    const clinicProtocolsSignal = new AbortController();
    const homeProtocolsSignal = new AbortController();
    const webProtocolsSignal = new AbortController();
    const appProtocolsSignal = new AbortController();

    if (isLoggedIn && environment) {
      // Set Clinic protocols
      UseFetch("get", `${WEB_SERVICE_DASHBOARD_ROUTE}/protocol/by-software/${environment}/${applications.clinicId}`, clinicProtocolsSignal)
        .then(({ data }) => {
          data.forEach((protocol) => {
            protocol.SOFTWARE = "clinic";
          });
          setClinicProtocols(data);
        })
        .catch((error) => {
          console.log("Get Clinic protocols: ", error);
        });
      // Set Home protocols
      UseFetch("get", `${WEB_SERVICE_DASHBOARD_ROUTE}/protocol/by-software/${environment}/${applications.homeId}`, homeProtocolsSignal)
        .then(({ data }) => {
          data.forEach((protocol) => {
            protocol.SOFTWARE = "home";
          });
          setHomeProtocols(data);
        })
        .catch((error) => {
          console.log("Get Home protocols: ", error);
        });
      // Set Web protocols
      UseFetch("get", `${WEB_SERVICE_DASHBOARD_ROUTE}/protocol/by-software/${environment}/${applications.webId}`, webProtocolsSignal)
        .then(({ data }) => {
          data.forEach((protocol) => {
            protocol.SOFTWARE = "web";
          });
          setWebProtocols(data);
        })
        .catch((error) => {
          console.log("Get Web protocols: ", error);
        });
      // Set App protocols
      UseFetch("get", `${WEB_SERVICE_DASHBOARD_ROUTE}/protocol/by-software/${environment}/${applications.appId}`, appProtocolsSignal)
        .then(({ data }) => {
          data.forEach((protocol) => {
            protocol.SOFTWARE = "app";
          });
          setAppProtocols(data);
        })
        .catch((error) => {
          console.log("Get App protocols: ", error);
        });
    }

    return () => {
      setHospitals([]);
      setIsLoadingHospitals(true);
      if (clinicProtocolsSignal) {
        clinicProtocolsSignal.abort();
      }
      if (homeProtocolsSignal) {
        homeProtocolsSignal.abort();
      }
      if (webProtocolsSignal) {
        webProtocolsSignal.abort();
      }
      if (appProtocolsSignal) {
        appProtocolsSignal.abort();
      }
    };
  }, [environment, isLoggedIn]);

  // Setting protocols
  useEffect(() => {
    if (clinicProtocols.length && homeProtocols.length && webProtocols.length && appProtocols.length) {
      setAllProtocols([...clinicProtocols, ...homeProtocols, ...webProtocols, ...appProtocols]);
    }
  }, [appProtocols, clinicProtocols, homeProtocols, webProtocols]);

  // Setting current period of months and years
  useEffect(() => {
    setCurrentPeriodOfMonths(filterStartMonth, filterStartYear, filterEndMonth, filterEndYear, setCurrentPeriodFiltered, setTargetedPeriod);
  }, [filterEndMonth, filterEndYear, filterStartMonth, filterStartYear]);

  // Filtering hospitals data
  useEffect(() => {
    const clonedHospitals = JSON.parse(JSON.stringify(markedHospitals.length ? markedHospitals : hospitals));

    // Filtering by protocol
    if (markedProtocols.length) {
      clonedHospitals.forEach((hospital) => {
        hospital.sessionsData = hospital.sessionsData.filter((session) => {
          const matchedProtocols = markedProtocols.filter((markedProtocol) => {
            return markedProtocol.PROTOCOL_ID === session.PROTOCOL_ID && markedProtocol.SOFTWARE === session.SOFTWARE;
          });
          return matchedProtocols.length;
        });
      });
    }

    // Filtering by time period
    let temporalTargetedHospitals = [];
    clonedHospitals.forEach((hospital) => {
      let minutesPerMonth = [];

      currentPeriodFiltered.forEach((date) => {
        const trainedSessionSeconds = hospital.sessionsData.filter(
          (session) => session.SESSION_MONTH === date.month && session.SESSION_YEAR === date.year
        );
        let trainedSeconds = 0;
        trainedSessionSeconds.forEach((session) => {
          trainedSeconds += session.SESSION_SECONDS;
        });
        minutesPerMonth.push(parseInt(trainedSeconds / 60));
      });
      temporalTargetedHospitals.push({
        NAME: hospital.NAME,
        baseColor: hospital.baseColor,
        temporalColor: hospital.temporalColor,
        minutesPerMonth,
      });
    });
    setTargetedHospitals(temporalTargetedHospitals);
  }, [hospitals, markedHospitals, markedProtocols, currentPeriodFiltered]);

  const renderPeriodFilter = () => {
    return (
      <PeriodContainer>
        <div>
          <p>From</p>
          <div>
            <Select value={filterStartMonth} onChange={(e) => setFilterStartMonth(Number(e.target.value))}>
              {renderMonthsOptions()}
            </Select>
            <Select value={filterStartYear} onChange={(e) => setFilterStartYear(Number(e.target.value))}>
              {renderYearsOptions()}
            </Select>
          </div>
        </div>
        <EachPeriodFilterDiv>
          <p>To</p>
          <div>
            <Select value={filterEndMonth} onChange={(e) => setFilterEndMonth(Number(e.target.value))}>
              {renderMonthsOptions()}
            </Select>
            <Select value={filterEndYear} onChange={(e) => setFilterEndYear(Number(e.target.value))}>
              {renderYearsOptions()}
            </Select>
          </div>
        </EachPeriodFilterDiv>
      </PeriodContainer>
    );
  };

  const renderAddUkrainianHospitalsCheckbox = () => {
    const setUkrainianHospitals = () => {
      setIsUkrainianHospitalsChecked(!isUkrainianHospitalsChecked);
    };

    return (
      <SoftwareFilterDiv>
        <CheckboxInput
          type="checkbox"
          id="addUkrainianHospitalsCheckbox"
          checked={isUkrainianHospitalsChecked}
          onChange={setUkrainianHospitals}
        />
        <CheckboxLabel htmlFor="addUkrainianHospitalsCheckbox">Add Ukrainian hospitals</CheckboxLabel>
      </SoftwareFilterDiv>
    );
  };

  const renderHospitalsCheckbox = () => {
    const setSelectedHospitals = (hospital) => {
      const clonedMarkedHospitals = JSON.parse(JSON.stringify(markedHospitals));
      if (clonedMarkedHospitals.some((clonedHospital) => clonedHospital.HOSPITAL_ID === hospital.HOSPITAL_ID)) {
        setDynamicHospitalsColor([...dynamicHospitalsColor, hospital.temporalColor]);
        const filteredMarkedHospitals = clonedMarkedHospitals.filter(
          (hospitalFilter) => hospitalFilter.HOSPITAL_ID !== hospital.HOSPITAL_ID
        );
        setMarkedHospitals(filteredMarkedHospitals);
      } else {
        if (dynamicHospitalsColor.length < 1) {
          let settingHospitalColors = JSON.parse(JSON.stringify(colorsArray));
          hospital.temporalColor = settingHospitalColors[0];
          settingHospitalColors.shift();
          setDynamicHospitalsColor(settingHospitalColors);
        } else {
          hospital.temporalColor = dynamicHospitalsColor[0];
          dynamicHospitalsColor.shift();
        }
        clonedMarkedHospitals.push(hospital);
        setMarkedHospitals(clonedMarkedHospitals);
      }
    };

    const handleClearMarkedHospitals = (e) => {
      e.preventDefault();
      setMarkedHospitals([]);
      setDynamicHospitalsColor(JSON.parse(JSON.stringify(colorsArray)));
    };

    return (
      <>
        <ClearMarkedHospitalsDiv>
          <ClearMarkedHospitalsButton onClick={handleClearMarkedHospitals}>Uncheck hospitals</ClearMarkedHospitalsButton>
        </ClearMarkedHospitalsDiv>
        <HospitalCheckboxesDiv>
          {hospitals.map((hospital) => {
            const hospitalId = hospital.HOSPITAL_ID;
            const hospitalName = hospital.NAME;
            const verifyCheckboxValue = markedHospitals.filter((markedHospital) => markedHospital.HOSPITAL_ID === hospitalId).length > 0;
            return (
              <EachSoftwareFilterDiv key={hospitalId}>
                <CheckboxInput
                  type="checkbox"
                  id={hospitalName}
                  checked={verifyCheckboxValue}
                  onChange={() => setSelectedHospitals(hospital)}
                />
                <CheckboxLabel htmlFor={hospitalName} fontSize="12px">
                  {hospitalName}
                </CheckboxLabel>
              </EachSoftwareFilterDiv>
            );
          })}
        </HospitalCheckboxesDiv>
      </>
    );
  };

  const renderProtocolsCheckbox = () => {
    const setSelectedProtocol = (currentProtocol) => {
      const clonedMarkedProtocols = JSON.parse(JSON.stringify(markedProtocols));
      const filteredMarkedProtocols = clonedMarkedProtocols.filter(
        (markedProtocol) =>
          markedProtocol.PROTOCOL_ID === currentProtocol.PROTOCOL_ID && markedProtocol.SOFTWARE === currentProtocol.SOFTWARE
      );
      if (filteredMarkedProtocols.length > 0) {
        const protocolIndex = clonedMarkedProtocols.findIndex(
          (markedProtocol) =>
            markedProtocol.PROTOCOL_ID === currentProtocol.PROTOCOL_ID && markedProtocol.SOFTWARE === currentProtocol.SOFTWARE
        );
        clonedMarkedProtocols.splice(protocolIndex, 1);
        setMarkedProtocols(clonedMarkedProtocols);
      } else {
        clonedMarkedProtocols.push(currentProtocol);
        setMarkedProtocols(clonedMarkedProtocols);
      }
    };

    const checkIfThereAreAllProtocols = (softwareProtocols) => {
      const clonedMarkedProtocols = JSON.parse(JSON.stringify(markedProtocols));
      let allProtocols = [];
      softwareProtocols.forEach((softwareProtocol) => {
        clonedMarkedProtocols.forEach((markedProtocol) => {
          if (softwareProtocol.PROTOCOL_ID === markedProtocol.PROTOCOL_ID && softwareProtocol.SOFTWARE === markedProtocol.SOFTWARE) {
            allProtocols.push(softwareProtocol);
          }
        });
      });
      return softwareProtocols.length === allProtocols.length;
    };

    const handleSetMarkedProtocols = (softwareProtocols) => {
      const clonedMarkedProtocols = JSON.parse(JSON.stringify(markedProtocols));

      if (checkIfThereAreAllProtocols(softwareProtocols)) {
        let filteredMarkedProtocols = [];
        clonedMarkedProtocols.forEach((markedProtocol) => {
          const matchedProtocol = softwareProtocols.filter((softwareProtocol) => {
            return markedProtocol.SOFTWARE === softwareProtocol.SOFTWARE && markedProtocol.PROTOCOL_ID === softwareProtocol.PROTOCOL_ID;
          });
          if (matchedProtocol.length < 1) {
            filteredMarkedProtocols.push(markedProtocol);
          }
        });
        return setMarkedProtocols(filteredMarkedProtocols);
      }

      const setEachSoftwareProtocol = () => {
        softwareProtocols.forEach((softwareProtocol) => {
          const protocolCheck = clonedMarkedProtocols.filter((markedProtocol) => {
            return markedProtocol.SOFTWARE === softwareProtocol.SOFTWARE && markedProtocol.PROTOCOL_ID === softwareProtocol.PROTOCOL_ID;
          });
          if (protocolCheck.length < 1) {
            clonedMarkedProtocols.push(softwareProtocol);
          }
        });
        return setMarkedProtocols(clonedMarkedProtocols);
      };
      return setEachSoftwareProtocol();
    };

    const renderSoftwareProtocols = (softwareProtocols) => {
      return softwareProtocols.map((protocol) => {
        const protocolId = protocol.PROTOCOL_ID;
        const protocolSoftware = protocol.SOFTWARE;
        const protocolName = returnProtocolTitle(protocolId, protocolSoftware);
        const checkProtocol = markedProtocols.filter(
          (markedProtocol) => markedProtocol.PROTOCOL_ID === protocolId && markedProtocol.SOFTWARE === protocolSoftware
        ).length;
        return (
          <EachProtocolDiv key={protocol.PROTOCOL_ID}>
            <CheckboxInput type="checkbox" id={protocolName} checked={checkProtocol} onChange={() => setSelectedProtocol(protocol)} />
            <CheckboxLabel htmlFor={protocolName} fontSize="12px">
              {protocolName.split(" ").slice(1).join(" ")}
            </CheckboxLabel>
          </EachProtocolDiv>
        );
      });
    };

    const renderSoftwareTitles = () => {
      return (
        <SoftwareTitlesDiv>
          <TitleSectionDiv>
            <SoftwareTitle>RGS Clinic</SoftwareTitle>
          </TitleSectionDiv>
          <TitleSectionDiv>
            <SoftwareTitle>RGS Home</SoftwareTitle>
          </TitleSectionDiv>
          <TitleSectionDiv>
            <SoftwareTitle>RGS App</SoftwareTitle>
          </TitleSectionDiv>
          <TitleSectionDiv>
            <SoftwareTitle>RGS Web</SoftwareTitle>
          </TitleSectionDiv>
        </SoftwareTitlesDiv>
      );
    };

    const renderSoftwareSection = (softwareProtocols) => {
      return (
        <ProtocolsSectionDiv>
          <CheckAllProtocolsDiv>
            <CheckboxAllProtocolsInput
              type="checkbox"
              checked={checkIfThereAreAllProtocols(softwareProtocols)}
              onChange={() => handleSetMarkedProtocols(softwareProtocols)}
            />
          </CheckAllProtocolsDiv>
          <SoftwareProtocolsDiv>{renderSoftwareProtocols(softwareProtocols)}</SoftwareProtocolsDiv>
        </ProtocolsSectionDiv>
      );
    };

    return (
      <>
        {renderSoftwareTitles()}
        <ProtocolsDiv>
          {/* Clinic */}
          {renderSoftwareSection(clinicProtocols)}
          {/* Home */}
          {renderSoftwareSection(homeProtocols)}
          {/* App */}
          {renderSoftwareSection(appProtocols)}
          {/* Web */}
          {renderSoftwareSection(webProtocols)}
        </ProtocolsDiv>
      </>
    );
  };

  const renderHospitalsDatasets = () => {
    let hospitalsDatasetsArray = [];
    targetedHospitals.forEach((hospital) => {
      hospitalsDatasetsArray.push({
        label: hospital.NAME,
        data: hospital.minutesPerMonth,
        backgroundColor: [markedHospitals.length > 0 ? hospital.temporalColor : hospital.baseColor],
        borderColor: [markedHospitals.length > 0 ? hospital.temporalColor : hospital.baseColor],
        fill: false,
      });
    });
    return hospitalsDatasetsArray;
  };

  const renderProtocolsDatasets = () => {
    let protocolsDatasetsArray = [];
    const targetedProtocols = JSON.parse(JSON.stringify(markedProtocols.length > 0 ? markedProtocols : allProtocols));
    const clonedHospitals = JSON.parse(JSON.stringify(markedHospitals.length > 0 ? markedHospitals : hospitals));
    let colorIndex = 0;
    targetedProtocols.forEach((protocol) => {
      let minutesPerMonth = [];
      currentPeriodFiltered.forEach((date) => {
        let trainedSeconds = 0;
        clonedHospitals.forEach((hospital) => {
          hospital.sessionsData.forEach((session) => {
            if (
              session.SESSION_MONTH === date.month &&
              session.SESSION_YEAR === date.year &&
              session.PROTOCOL_ID === protocol.PROTOCOL_ID &&
              session.SOFTWARE === protocol.SOFTWARE
            ) {
              trainedSeconds += session.SESSION_SECONDS;
            }
          });
        });
        minutesPerMonth.push(parseInt(trainedSeconds / 60));
      });
      if (colorIndex >= colorsArray.length) colorIndex = 0;

      protocolsDatasetsArray.push({
        label: returnProtocolTitle(protocol.PROTOCOL_ID, protocol.SOFTWARE),
        data: minutesPerMonth,
        backgroundColor: colorsArray[colorIndex],
        borderColor: colorsArray[colorIndex],
        fill: false,
      });
      colorIndex++;
    });
    return protocolsDatasetsArray;
  };

  const renderActivePatientsDatasets = () => {
    let activePatients = [];
    let patientsCreated3MonthsAgo = [];
    let patientsWithTheirFirstSession = [];

    const targetedHospitals = JSON.parse(JSON.stringify(markedHospitals.length > 0 ? markedHospitals : hospitals));
    const targetedProtocols = JSON.parse(JSON.stringify(markedProtocols.length > 0 ? markedProtocols : allProtocols));
    const clonedPatients = JSON.parse(JSON.stringify(patients));

    const filteredPatientsByHospital = clonedPatients.filter((patient) => {
      return targetedHospitals.find((hospital) => hospital.HOSPITAL_ID === patient.HOSPITAL_ID);
    });

    const filteredPatientsByProtocol = filteredPatientsByHospital.filter((patient) => {
      let verifiedProtocol = false;
      patient.sessions.forEach((session) => {
        targetedProtocols.forEach((protocol) => {
          if (session.PROTOCOL_ID === protocol.PROTOCOL_ID && session.SOFTWARE === protocol.SOFTWARE) {
            verifiedProtocol = true;
          }
        });
      });
      return verifiedProtocol;
    });

    // Running each month
    currentPeriodFiltered.forEach((date) => {
      // Total patients with at least one session
      const activePatientsPerMonth = filteredPatientsByProtocol.filter((patient) => {
        return patient.sessions.find((session) => session.SESSION_MONTH === date.month && session.SESSION_YEAR === date.year);
      });

      // Patients created three months ago
      const limitedDate = new Date(moment(`${date.year}-${date.month}-01`, "YYYY-MM-DD").subtract(5, "months"));
      const patientsCreated6MonthsAgoPerMonth = activePatientsPerMonth.filter((patient) => {
        return new Date(patient.CREATION_TIME) >= limitedDate;
      });

      // Patients with their first session
      const patientsWithTheirFirstSessionPerMonth = activePatientsPerMonth.filter((patient) => {
        return date.year === patient.firstSession.year && date.month === patient.firstSession.month;
      });

      // Adding patients in each period
      activePatients.push(activePatientsPerMonth.length);
      patientsCreated3MonthsAgo.push(patientsCreated6MonthsAgoPerMonth.length);
      patientsWithTheirFirstSession.push(patientsWithTheirFirstSessionPerMonth.length);
    });

    return [
      {
        label: "Pacientes activos",
        data: activePatients,
        backgroundColor: colorsArray[0],
        borderColor: colorsArray[0],
        fill: false,
      },
      {
        label: "Pacientes dentro de los (6) meses",
        data: patientsCreated3MonthsAgo,
        backgroundColor: colorsArray[1],
        borderColor: colorsArray[1],
        fill: false,
      },
      {
        label: "Pacientes con su primera sesión",
        data: patientsWithTheirFirstSession,
        backgroundColor: colorsArray[2],
        borderColor: colorsArray[2],
        fill: false,
      },
    ];
  };

  // Hospitals minutes data
  const hospitalsMinutesData = {
    labels: targetedPeriod,
    datasets: renderHospitalsDatasets(),
  };

  // Protocols minutes data
  const protocolsMinutesData = {
    labels: targetedPeriod,
    datasets: renderProtocolsDatasets(),
  };

  // Patients with at least one session
  const patientsWithAtLeastOneSession = {
    labels: targetedPeriod,
    datasets: renderActivePatientsDatasets(),
  };

  return (
    <>
      <Header />
      <Title>Statistics</Title>
      {renderAddUkrainianHospitalsCheckbox()}
      {renderPeriodFilter()}
      {isLoadingHospitals || isLoadingPatients ? (
        <Loader />
      ) : (
        <>
          {hospitals.length === 0 ? (
            <Title>There are no hospitals</Title>
          ) : (
            <>
              {renderHospitalsCheckbox()}
              {renderProtocolsCheckbox()}
              <Graphic
                Title={"Trained minutes per hospital"}
                Type={Line}
                data={hospitalsMinutesData}
                axisY={"Minutes"}
                axisX={"Months"}
                filterAxisY
              />
              <Graphic
                Title={"Trained minutes per activity"}
                Type={Line}
                data={protocolsMinutesData}
                axisY={"Minutes"}
                axisX={"Months"}
                filterAxisY
              />
              <Graphic
                Title={"Patients with sessions"}
                Type={Line}
                data={patientsWithAtLeastOneSession}
                axisY={"Patients"}
                axisX={"Months"}
                filterAxisY
              />
              <PatientsAdherence
                targetedPeriod={targetedPeriod}
                currentPeriodFiltered={currentPeriodFiltered}
                filterStartMonth={filterStartMonth}
                filterStartYear={filterStartYear}
                filterEndMonth={filterEndMonth}
                filterEndYear={filterEndYear}
                markedHospitals={markedHospitals}
                isUkrainianHospitalsChecked={isUkrainianHospitalsChecked}
              />
            </>
          )}
        </>
      )}
    </>
  );
};

export default Statistics;
