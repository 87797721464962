import React from "react";
// Components
import Patients from "../../components/patients";
import VerifySession from "../../config/verifySession";

const PatientsView = () => (
  <>
    {VerifySession()}
    <Patients />
  </>
);

export default PatientsView;
