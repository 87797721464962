import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
// Redux
import { useSelector } from "react-redux";
// Constants
import { pathHomePage } from "./constants";

const AdminValidation = () => {
  const [redirectToHome, setRedirectToHome] = useState(false);
  const userRole = useSelector(({ user }) => user.userRole);

  useEffect(() => {
    if (userRole !== "ADMIN") {
      setRedirectToHome(true);
    }
  }, [userRole]);
  if (redirectToHome) {
    return <Redirect to={`${pathHomePage}`} />;
  }
};

export default AdminValidation;
