import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import moment from "moment";
// Redux
import { useSelector } from "react-redux";
// Constants
import { WEB_SERVICE_DASHBOARD_ROUTE, colorsArray } from "../../../../config/constants";
// Components
import {
  PatientsDiv,
  SessionsFilterDiv,
  SessionsFilterText,
  SessionsFilterCheckbox,
  PatientsSectionContainer,
  PatientsSectionDiv,
  TitleDiv,
  Title,
  Body,
  CheckAllPatientsDiv,
  AllPatientsCheckbox,
  PatientsCheckboxDiv,
  EachPatientDiv,
  CheckboxInput,
  CheckboxLabel,
} from "./styles";
import Loader from "../../../loader";
import UseFetch from "../../../../config/useFetch";
import Graphic from "../../../graphic";

const PatientsAdherence = ({
  targetedPeriod,
  currentPeriodFiltered,
  filterStartMonth,
  filterStartYear,
  filterEndMonth,
  filterEndYear,
  markedHospitals,
  isUkrainianHospitalsChecked,
}) => {
  const [isLoadingPatients, setIsLoadingPatients] = useState(true);
  const [patients, setPatients] = useState([]);
  const [markedPatients, setMarkedPatients] = useState([]);
  const [patientsPerHospital, setPatientsPerHospital] = useState([]);
  const [targetedPatients, setTargetedPatients] = useState([]);
  const [filterLastMonths, setFilterLastMonths] = useState(true);

  const environment = useSelector(({ database }) => database.environment);

  // Fetching patients
  useEffect(() => {
    const getPatientsAdherenceSignal = new AbortController();

    const adherencePeriod = {
      startingDate: moment(new Date(filterStartYear, filterStartMonth - 1, 1, 10)).format("YYYY-MM-DD hh:mm"),
      endingDate: moment(new Date(filterEndYear, filterEndMonth - 1, 1, 10)).format("YYYY-MM-DD hh:mm"),
      filterLastMonths,
      addUkrainianHospitals: isUkrainianHospitalsChecked,
    };

    UseFetch(
      "post",
      `${WEB_SERVICE_DASHBOARD_ROUTE}/patient/get-patients-adherence-per-month/${environment}`,
      getPatientsAdherenceSignal,
      adherencePeriod
    )
      .then(({ data }) => {
        let indexColor = 0;
        data.forEach((patient) => {
          if (indexColor >= colorsArray.length) indexColor = 0;
          patient.baseColor = colorsArray[indexColor];
          indexColor++;
        });
        setPatients(data);
        setMarkedPatients(
          markedPatients.map((markedPatient) => {
            const replacePatient = data.find((patient) => patient.PATIENT_ID === markedPatient.PATIENT_ID);
            return replacePatient;
          })
        );
        setIsLoadingPatients(false);
      })
      .catch((error) => {
        console.log("Get patients adherence: ", error);
      });

    return () => {
      setPatients([]);
      setMarkedPatients([]);
      setIsLoadingPatients(true);
      if (getPatientsAdherenceSignal) {
        getPatientsAdherenceSignal.abort();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterLastMonths, environment, filterEndMonth, filterEndYear, filterStartMonth, filterStartYear]);

  // Setting patients per hospital
  useEffect(() => {
    setMarkedPatients([]);
    const patientsPerHospital = !markedHospitals.length
      ? patients
      : patients.filter((patient) => {
          const markedHospitalIds = markedHospitals.map((markedHospital) => markedHospital.HOSPITAL_ID);
          return markedHospitalIds.includes(patient.HOSPITAL_ID);
        });
    setPatientsPerHospital(patientsPerHospital);
  }, [markedHospitals, patients]);

  // Setting patients to plot
  useEffect(() => {
    const clonedPatients = JSON.parse(JSON.stringify(markedPatients.length > 0 ? markedPatients : patientsPerHospital));
    // Filtering by time period
    for (const patient of clonedPatients) {
      patient.formattedAdherencePerMonth = patient.adherencePerMonth.map((adherenceObject) => {
        return adherenceObject.adherence;
      });
    }
    setTargetedPatients(clonedPatients);
  }, [currentPeriodFiltered, filterLastMonths, markedPatients, patientsPerHospital]);

  const renderPatientsCheckbox = () => {
    const generalPatients = patientsPerHospital.filter((patient) => patient.PATIENT_TYPE === "GENERAL");
    const cognitivePatients = patientsPerHospital.filter((patient) => patient.PATIENT_TYPE === "COGNITIVE");

    const checkIfThereAreAllPatients = (patients) => {
      const markedPatientIds = new Set(markedPatients.map((patient) => patient.PATIENT_ID));
      const allPatients = patients.filter((patient) => markedPatientIds.has(patient.PATIENT_ID));
      return patients.length === allPatients.length;
    };

    const setAllPatients = (patients) => {
      if (checkIfThereAreAllPatients(patients)) {
        const markedPatientIds = new Set(patients.map((patient) => patient.PATIENT_ID));
        const filteredMarkedPatients = markedPatients.filter((patient) => !markedPatientIds.has(patient.PATIENT_ID));
        setMarkedPatients(filteredMarkedPatients);
      } else {
        const newMarkedPatients = patients.filter(
          (patient) => !markedPatients.some((markedPatient) => markedPatient.PATIENT_ID === patient.PATIENT_ID)
        );
        setMarkedPatients((prevMarkedPatients) => [...prevMarkedPatients, ...newMarkedPatients]);
      }
    };

    const renderPatients = (patients) => {
      const setSelectedPatient = (patient) => {
        setMarkedPatients((prevMarkedPatients) => {
          const isPatientMarked = prevMarkedPatients.some((markedPatient) => markedPatient.PATIENT_ID === patient.PATIENT_ID);

          if (isPatientMarked) {
            return prevMarkedPatients.filter((markedPatient) => markedPatient.PATIENT_ID !== patient.PATIENT_ID);
          } else {
            return [...prevMarkedPatients, patient];
          }
        });
      };
      return patients.map((patient) => {
        const checkPatient = markedPatients.filter((markedPatient) => markedPatient.PATIENT_ID === patient.PATIENT_ID).length > 0;
        return (
          <EachPatientDiv key={patient.PATIENT_ID}>
            <CheckboxInput type="checkbox" id={patient.PATIENT_ID} checked={checkPatient} onChange={() => setSelectedPatient(patient)} />
            <CheckboxLabel htmlFor={patient.PATIENT_ID} fontSize="12px">
              {patient.PATIENT_USER}
            </CheckboxLabel>
          </EachPatientDiv>
        );
      });
    };

    const renderPatientSection = (title, patients) => {
      return (
        <PatientsSectionDiv>
          <TitleDiv>
            <Title>{title}</Title>
          </TitleDiv>
          <Body>
            {!patients.length ? (
              <Title>{"No hay pacientes de este tipo"}</Title>
            ) : (
              <>
                <CheckAllPatientsDiv>
                  <AllPatientsCheckbox
                    type="checkbox"
                    checked={checkIfThereAreAllPatients(patients)}
                    onChange={() => setAllPatients(patients)}
                  />
                </CheckAllPatientsDiv>
                <PatientsCheckboxDiv>{renderPatients(patients)}</PatientsCheckboxDiv>
              </>
            )}
          </Body>
        </PatientsSectionDiv>
      );
    };

    const renderSessionsFilterCheckbox = () => {
      return (
        <SessionsFilterDiv>
          <SessionsFilterText htmlFor="sessionsFilterCheckbox">Filtrar últimos 6 meses de adherencia</SessionsFilterText>
          <SessionsFilterCheckbox
            id="sessionsFilterCheckbox"
            type="checkbox"
            checked={filterLastMonths}
            onChange={() => setFilterLastMonths(!filterLastMonths)}
          />
        </SessionsFilterDiv>
      );
    };

    return (
      <PatientsDiv>
        <PatientsSectionContainer>
          {renderPatientSection("Pacientes Generales", generalPatients)}
          {renderPatientSection("Pacientes Cognitivos", cognitivePatients)}
        </PatientsSectionContainer>
        {renderSessionsFilterCheckbox()}
      </PatientsDiv>
    );
  };

  const renderPatientsDatasets = () => {
    let patientsDatasetsArray = [];
    let patientsAdherencePerMonth = [];
    let currentMonths = 0;
    targetedPatients.forEach((patient) => {
      if (!currentMonths) currentMonths = patient.formattedAdherencePerMonth.length;
      patientsAdherencePerMonth.push(patient.formattedAdherencePerMonth);
      patientsDatasetsArray.push({
        label: patient.PATIENT_USER,
        data: patient.formattedAdherencePerMonth,
        backgroundColor: [patient.baseColor],
        borderColor: [patient.baseColor],
        fill: false,
      });
    });
    // Calculate adherence average
    let globalAdherencePerMonth = [];
    for (let i = 0; i < currentMonths; i++) {
      let valuesDifferentOfNull = 0;
      let currentValues = 0;
      patientsAdherencePerMonth.forEach((adherenceMonth) => {
        if (adherenceMonth[i] !== null) {
          valuesDifferentOfNull++;
          currentValues += adherenceMonth[i];
        }
      });
      globalAdherencePerMonth.push(parseInt(currentValues / valuesDifferentOfNull));
    }
    patientsDatasetsArray.unshift({
      label: "Overall average",
      data: globalAdherencePerMonth,
      backgroundColor: [colorsArray[49]],
      borderColor: [colorsArray[49]],
      fill: false,
    });

    return patientsDatasetsArray;
  };

  // Hospitals minutes data
  const patientsAdherenceData = {
    labels: targetedPeriod,
    datasets: renderPatientsDatasets(),
  };

  return (
    <>
      {isLoadingPatients ? (
        <Loader />
      ) : (
        <>
          {(environment === "strack_production" || environment === "strack_development") && renderPatientsCheckbox()}
          <Graphic
            Title={"Patients Adherence"}
            Type={Line}
            data={patientsAdherenceData}
            axisY={"Adherence (%)"}
            axisX={"Months"}
            filterAxisY
          />
        </>
      )}
    </>
  );
};

export default PatientsAdherence;
