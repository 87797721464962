import React from "react";
// Functions
import { getTrainedMinutesAndSeconds } from "../../config/functions";
// Components
import {
  AdherenceDiv,
  PositionerTimeButtonsDiv,
  TimeButtonsDiv,
  TimeButton,
  SeparatorLine,
  Title,
  GraphicsDiv,
  DataShowedDescriptionText,
} from "./styles";
import AdherenceProgressBar from "./adherenceProgressBar";

const AdherenceWheels = ({ adherenceObject, sessionTimeRange, setSessionTimeRange }) => {
  return (
    <AdherenceDiv>
      <PositionerTimeButtonsDiv>
        <TimeButtonsDiv>
          <TimeButton onClick={() => setSessionTimeRange("WEEK")} active={sessionTimeRange === "WEEK"}>
            Week
          </TimeButton>
          <SeparatorLine />
          <TimeButton onClick={() => setSessionTimeRange("MONTH")} active={sessionTimeRange === "MONTH"}>
            Month
          </TimeButton>
          <SeparatorLine />
          <TimeButton onClick={() => setSessionTimeRange("THREE_MONTHS")} active={sessionTimeRange === "THREE_MONTHS"}>
            3 Months
          </TimeButton>
          <SeparatorLine />
          <TimeButton onClick={() => setSessionTimeRange("ALL_MONTHS")} active={sessionTimeRange === "ALL_MONTHS"}>
            All
          </TimeButton>
        </TimeButtonsDiv>
      </PositionerTimeButtonsDiv>
      <Title>Hospital Adherence</Title>
      <GraphicsDiv>
        {/* Days adherence */}
        <AdherenceProgressBar
          percentage={adherenceObject.daysAdherence}
          prescribedData={adherenceObject.prescribedDays}
          trainedData={adherenceObject.trainedDays}
          prescribedDataTitle={"Prescribed days"}
          trainedDataTitle={"Trained days"}
        />
        {/* Sessions adherence */}
        <AdherenceProgressBar
          percentage={adherenceObject.sessionsAdherence}
          prescribedData={adherenceObject.prescribedSessions}
          trainedData={adherenceObject.trainedSessions}
          prescribedDataTitle={"Prescribed sessions"}
          trainedDataTitle={"Trained sessions"}
        />
        {/* Minutes adherence */}
        <AdherenceProgressBar
          percentage={adherenceObject.minutesAdherence}
          prescribedData={adherenceObject.prescribedMinutes}
          trainedData={getTrainedMinutesAndSeconds(adherenceObject.trainedSeconds)}
          prescribedDataTitle={"Prescribed minutes"}
          trainedDataTitle={"Trained minutes"}
        />
      </GraphicsDiv>
      <DataShowedDescriptionText>
        The data from the sessions carried out today will not be reflected in the adherence percentages until tomorrow
      </DataShowedDescriptionText>
    </AdherenceDiv>
  );
};

export default AdherenceWheels;
