import React from "react";
// Components
import VerifySession from "../../../config/verifySession";
import AdminValidation from "../../../config/adminValidation";
import CreateHospitalComponent from "../../../components/protocolsManagement/sections/createProtocol";

const CreateProtocolView = () => (
  <>
    {VerifySession()}
    {AdminValidation()}
    <CreateHospitalComponent />
  </>
);

export default CreateProtocolView;
