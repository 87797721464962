import React from "react";
import moment from "moment";
// Constants - Functions
import { pathHomePage, AdherenceColorLow, AdherenceColorMedium, AdherenceColorHigh, adherenceLevel } from "../../../../config/constants";
import { getAge } from "../../../../config/functions";
// Components
import {
  PatientsAligner,
  PatientsContainer,
  TableContainer,
  TableTitle,
  PatientContainer,
  PatientInfo,
  PatientLink,
  ExternalLinkImg,
  AdherencePercentage,
} from "./styles";
// Assets
import ExternalLinkIcon from "../../../../assets/external_link_icon.svg";

const RenderPatientData = ({ patients, project }) => {
  const renderTableHeader = () => {
    return (
      <TableContainer>
        <TableTitle width="50px">Nº</TableTitle>
        <TableTitle width="90px">Patient</TableTitle>
        <TableTitle width="250px" align="left">
          Information
        </TableTitle>
        <TableTitle width="70px">Trained days</TableTitle>
        <TableTitle width="70px">Trained sessions</TableTitle>
        <TableTitle width="100px">Days from first assessment session</TableTitle>
        <TableTitle width="100px">Adherence</TableTitle>
      </TableContainer>
    );
  };

  const getAdherenceColor = (patientAdherence) => {
    const formattedPercentage = Number(patientAdherence);
    if (Number(formattedPercentage) < adherenceLevel.lowToMedium) {
      return AdherenceColorLow;
    }
    if (Number(formattedPercentage) >= adherenceLevel.lowToMedium && Number(formattedPercentage) <= adherenceLevel.MediumToHigh) {
      return AdherenceColorMedium;
    }
    if (Number(formattedPercentage) > adherenceLevel.MediumToHigh) {
      return AdherenceColorHigh;
    }
  };

  const renderPatients = () => {
    return patients.map((patient, index) => {
      const patientIndex = index + 1;
      const patientId = patient.PATIENT_ID;
      const patientGender = patient.GENDER === "FEMALE" ? "Female" : "Male";
      const patientAge = getAge(moment(patient.BIRTH_DATE, "DD-MM-YYYY").format("YYYY-MM-DD"));
      const renderPatientInfo = `${patientGender} ${patientAge}, ${patient.PATHOLOGY}`;
      const patientAdherence = patient.LAST_WEEK_ADHERENCE_PERCENTAGE;

      return (
        <PatientContainer key={patientId}>
          <PatientInfo width="50px">{patientIndex}</PatientInfo>
          <PatientInfo width="90px">{patient.PATIENT_USER}</PatientInfo>
          <PatientInfo width="250px" align="left">
            {renderPatientInfo}
          </PatientInfo>
          <PatientInfo width="70px">{patient.trainedDays}</PatientInfo>
          <PatientInfo width="70px">{patient.trainedSessions}</PatientInfo>
          <PatientInfo width="100px">{patient.daysFromFirstDiagnosticSession}</PatientInfo>
          <PatientLink to={`${pathHomePage}/patient-data/${project}/${patientId}`} width="100px">
            <AdherencePercentage color={getAdherenceColor(patientAdherence)}>{patientAdherence}%</AdherencePercentage>
            <ExternalLinkImg src={ExternalLinkIcon} alt="external-ico" />
          </PatientLink>
        </PatientContainer>
      );
    });
  };

  return (
    <PatientsAligner>
      <PatientsContainer>
        {renderTableHeader()}
        {renderPatients()}
      </PatientsContainer>
    </PatientsAligner>
  );
};

export default RenderPatientData;
