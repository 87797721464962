import styled from "styled-components";
import { Link } from "react-router-dom";

export const HospitalContainer = styled.div`
  margin: 5px auto;
  padding: 10px 0;
  width: 800px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 5px 15px rgb(0 0 0 / 10%);
`;
export const HospitalInfo = styled.p`
  width: ${({ width }) => (width ? width : "200px")};
  font-size: 16px;
  text-align: center;
`;
export const HospitalDataLink = styled(Link)`
  outline: none;
  cursor: pointer;
`;
export const ExternalLink = styled.a`
  outline: none;
  text-decoration: none;
  cursor: pointer;
`;
export const ExternalLinkImg = styled.img`
  width: 20px;
  height: 20px;
`;
export const PatientsListButton = styled.button`
  width: ${({ width }) => (width ? width : "200px")};
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
`;
export const PatientsListImg = styled.img`
  width: 20px;
  height: 20px;
`;
