import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
// Redux
import { useSelector } from "react-redux";
// Constants
import { pathHomePage, WEB_SERVICE_DASHBOARD_ROUTE } from "../../../../config/constants";
// Components
import {
  Title,
  CreateHospitalDiv,
  CreateClinicianLink,
  TableContainer,
  TableTitle,
  ClinicianContainer,
  ClinicianUser,
  ClinicianInfo,
  ClinicianLink,
  ClinicianExternalLink,
  ClinicianImg,
} from "./styles";
import Header from "../../../header";
import GoBack from "../../../goBack";
import Loader from "../../../loader";
import Axios from "../../../../config/axios";
// Assets
import EditIcon from "../../../../assets/edit_icon.svg";
import ExternalLinkIcon from "../../../../assets/external_link_icon.svg";

const CliniciansController = () => {
  const [isLoadingClinicians, setIsLoadingClinicians] = useState(true);
  const [clinicians, setClinicians] = useState([]);
  const { hospitalId } = useParams();
  const isLoggedIn = useSelector(({ user }) => user.isLoggedIn);
  const environment = useSelector(({ database }) => database.environment);
  const currentHospitalName = useSelector(({ hospital }) => hospital.currentName);

  // Fetching clinicians by hospital
  useEffect(() => {
    if (isLoggedIn && environment !== "") {
      Axios("get", `${WEB_SERVICE_DASHBOARD_ROUTE}/clinician/get-list/${environment}/${hospitalId}`)
        .then(({ data }) => {
          setClinicians(data);
          setIsLoadingClinicians(false);
        })
        .catch((error) => {
          console.log("Get hospitals: ", error);
        });
    }
  }, [isLoggedIn, environment, hospitalId]);

  const renderNewClinicianButton = () => {
    return (
      <CreateHospitalDiv>
        <CreateClinicianLink to={`${pathHomePage}/create-clinician/${hospitalId}`}>Create new clinician</CreateClinicianLink>
      </CreateHospitalDiv>
    );
  };

  const renderTableHeader = () => {
    return (
      <TableContainer>
        <TableTitle width="250px">User</TableTitle>
        <TableTitle width="80px">Edit</TableTitle>
        <TableTitle width="80px">Go to MIMS</TableTitle>
        <TableTitle width="115px">Role</TableTitle>
        <TableTitle width="180px">Name</TableTitle>
        <TableTitle width="180px">Last name</TableTitle>
        <TableTitle width="180px">Phone</TableTitle>
        <TableTitle>Email</TableTitle>
      </TableContainer>
    );
  };

  const renderClinicians = () => {
    const returnMimsProject = () => {
      switch (environment) {
        case "global_production":
          return "https://rgsmims.eodyne.com/add-patient";
        case "global_beta":
          return "https://rgsmims-beta.eodyne.com/add-patient";
        case "global_development":
          return "https://rgsmims-dev.eodyne.com/add-patient";
        case "strack_production":
          return "https://rgsmims-strack.eodyne.com/add-patient";
        case "strack_development":
          return "https://rgsmims-strack-dev.eodyne.com/add-patient";
        default:
          break;
      }
    };

    return clinicians.map((clinician) => {
      const isNull = (value) => value === null || value === "";
      const clinicianId = clinician.CLINICIAN_ID;
      const urlToMims = `${returnMimsProject()}/${clinician.tokenToMims}`;
      return (
        <ClinicianContainer key={clinicianId}>
          <ClinicianUser>{clinician.CLINICIAN_USER}</ClinicianUser>
          <ClinicianInfo width="80px">
            <ClinicianLink to={`${pathHomePage}/edit-clinician/${hospitalId}/${clinicianId}`}>
              <ClinicianImg src={EditIcon} alt="edit-icon" />
            </ClinicianLink>
          </ClinicianInfo>
          <ClinicianInfo width="80px">
            <ClinicianExternalLink href={urlToMims} target="_blank">
              <ClinicianImg src={ExternalLinkIcon} alt="external-link-icon" />
            </ClinicianExternalLink>
          </ClinicianInfo>
          <ClinicianInfo width="115px">{isNull(clinician.ROLE) ? "n/a" : clinician.ROLE}</ClinicianInfo>
          <ClinicianInfo width="180px">{isNull(clinician.NAME) ? "n/a" : clinician.NAME}</ClinicianInfo>
          <ClinicianInfo width="180px">{isNull(clinician.SURNAME1) ? "n/a" : clinician.SURNAME1}</ClinicianInfo>
          <ClinicianInfo width="180px">{isNull(clinician.PHONE) ? "n/a" : clinician.PHONE}</ClinicianInfo>
          <ClinicianInfo>{isNull(clinician.EMAIL) ? "n/a" : clinician.EMAIL}</ClinicianInfo>
        </ClinicianContainer>
      );
    });
  };

  return (
    <>
      <Header />
      <GoBack path={`${pathHomePage}/intermediate-management/${hospitalId}`} />
      {isLoadingClinicians ? (
        <Loader />
      ) : (
        <>
          <Title>{`Hospital clinicians: ${currentHospitalName}`}</Title>
          {renderNewClinicianButton()}
          {clinicians.length < 1 ? (
            <Title fontSize="20px">There are no clinicians</Title>
          ) : (
            <>
              {renderTableHeader()}
              {renderClinicians()}
            </>
          )}
        </>
      )}
    </>
  );
};

export default CliniciansController;
