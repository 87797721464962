import styled from "styled-components";
import { primaryColor } from "../../config/constants";
import { Link } from "react-router-dom";

export const Title = styled.h2`
  margin: 30px 0 20px;
  font-size: 26px;
  text-align: center;
`;
export const CreateProtocolDiv = styled.div`
  margin: 10px auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const CreateProtocolLink = styled(Link)`
  margin: 10px auto;
  padding: 10px;
  font-size: 14px;
  color: #000;
  border: 2px solid ${primaryColor};
  outline: none;
  text-decoration: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.15s ease-in-out;
  &:hover {
    background-color: ${primaryColor};
    color: #fff;
  }
`;
export const TableContainer = styled.div`
  margin: 0 auto;
  width: 1100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const TableTitle = styled.p`
  margin: 0 10px;
  width: ${({ width }) => (width ? width : "200px")};
  font-size: 16px;
  font-weight: bold;
  text-align: center;
`;
export const ProtocolContainer = styled.div`
  margin: 5px auto;
  padding: 10px 0;
  width: 1100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: rgb(0 0 0 / 15%) 0px 5px 15px;
`;
export const ProtocolName = styled.p`
  margin: 0 10px;
  width: 200px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
`;
export const ProtocolInfo = styled.p`
  width: ${({ width }) => (width ? width : "200px")};
  margin: 0 10px;
  font-size: 14px;
  text-align: center;
`;
export const EditProtocolLink = styled(Link)`
  outline: none;
  text-decoration: none;
  cursor: pointer;
`;
export const EditProtocolImg = styled.img`
  width: 20px;
  height: 18px;
`;
