import React, { useState, useEffect } from "react";
import { useParams, Redirect } from "react-router-dom";
import { toast } from "react-toastify";
// Redux
import { useSelector } from "react-redux";
// Constants
import { pathHomePage, WEB_SERVICE_DASHBOARD_ROUTE } from "../../../../config/constants";
// Components
import {
  Title,
  ProtocolInfoDiv,
  AppsDiv,
  TitleTextDiv,
  TitleApps,
  DataAppsDiv,
  InputPositioner,
  InputDataAppsDiv,
  Label,
  InputCheckbox,
  SubmitButtonDiv,
  SubmitButton,
} from "./styles";
import Header from "../../../header";
import GoBack from "../../../goBack";
import Loader from "../../../loader";
import Axios from "../../../../config/axios";

const ApplicationController = () => {
  const [isLoadingApplications, setIsLoadingApplications] = useState(true);
  const [isLoadingHospitalApplications, setIsLoadingHospitalApplications] = useState(true);
  const [dbSoftwares, setDbSoftwares] = useState([]);
  const [hospitalSoftwares, setHospitalSoftwares] = useState([]);
  const [redirectToIntermediateController, setRedirectToIntermediateController] = useState(false);
  const { hospitalId } = useParams();
  const isLoggedIn = useSelector(({ user }) => user.isLoggedIn);
  const environment = useSelector(({ database }) => database.environment);
  const currentHospitalName = useSelector(({ hospital }) => hospital.currentName);

  // Fetching softwares
  useEffect(() => {
    if (isLoggedIn && environment !== "") {
      Axios("get", `${WEB_SERVICE_DASHBOARD_ROUTE}/software/list/${environment}`)
        .then(({ data }) => {
          setDbSoftwares(data);
          setIsLoadingApplications(false);
        })
        .catch((error) => {
          console.log("endpoint to get softwares: ", error);
        });
    }
  }, [isLoggedIn, environment]);

  // Fetching hospital softwares
  useEffect(() => {
    if (isLoggedIn && environment !== "" && dbSoftwares.length > 0) {
      Axios("get", `${WEB_SERVICE_DASHBOARD_ROUTE}/software/get-software-by-hospital/${environment}/${hospitalId}`)
        .then(({ data }) => {
          setHospitalSoftwares(data);
          setIsLoadingHospitalApplications(false);
        })
        .catch((error) => {
          console.log("endpoint to get softwares: ", error);
        });
    }
  }, [isLoggedIn, dbSoftwares, environment, hospitalId]);

  const handleCreateApplications = (e) => {
    e.preventDefault();
    if (hospitalSoftwares.length > 0) {
      Axios("post", `${WEB_SERVICE_DASHBOARD_ROUTE}/software/add-software-by-hospital/${environment}/${hospitalId}`, hospitalSoftwares)
        .then(({ data }) => {
          if (data.AddedApplications) {
            toast.success("Aplicaciones registradas correctamente");
            setRedirectToIntermediateController(true);
          }
        })
        .catch((error) => {
          toast.error("Hubo un error al enviar los datos, intente de nuevo");
          console.log("endpoint to add applications: ", error);
        });
    }
  };
  if (redirectToIntermediateController) {
    return <Redirect to={`${pathHomePage}/intermediate-management/${hospitalId}`} />;
  }

  const renderApplications = () => {
    const handleSetSoftware = (softwareId) => {
      let clonedHospitalSoftwares = JSON.parse(JSON.stringify(hospitalSoftwares));
      const verifySoftware = hospitalSoftwares.filter((hospitalSoftware) => hospitalSoftware.SOFTWARE_ID === softwareId);
      if (verifySoftware.length > 0) {
        clonedHospitalSoftwares.forEach((hospitalSoftware) => {
          if (hospitalSoftware.SOFTWARE_ID === softwareId) {
            if (hospitalSoftware.EXPIRATION_DATE) {
              delete hospitalSoftware.EXPIRATION_DATE;
            } else {
              hospitalSoftware.EXPIRATION_DATE = new Date();
            }
          }
        });
        setHospitalSoftwares(clonedHospitalSoftwares);
      } else {
        clonedHospitalSoftwares.push({
          SOFTWARE_ID: softwareId,
        });
        setHospitalSoftwares(clonedHospitalSoftwares);
      }
    };
    return (
      <ProtocolInfoDiv>
        <AppsDiv>
          <TitleTextDiv>
            <TitleApps>Aplicaciones RGS del hospital</TitleApps>
          </TitleTextDiv>
          <DataAppsDiv justifyContent={dbSoftwares.length > 1}>
            {dbSoftwares.map((software) => {
              const softwareName = software.NAME;
              const softwareId = software.SOFTWARE_ID;
              const verifySoftware =
                hospitalSoftwares.filter(
                  (hospitalSoftware) => hospitalSoftware.SOFTWARE_ID === software.SOFTWARE_ID && !hospitalSoftware.EXPIRATION_DATE
                ).length > 0;
              return (
                <InputPositioner key={softwareId}>
                  <InputDataAppsDiv>
                    <Label htmlFor={softwareName}>{softwareName}</Label>
                    <InputCheckbox
                      type="checkbox"
                      id={softwareName}
                      checked={verifySoftware}
                      onChange={() => handleSetSoftware(softwareId)}
                    />
                  </InputDataAppsDiv>
                </InputPositioner>
              );
            })}
          </DataAppsDiv>
        </AppsDiv>
        <SubmitButtonDiv>
          <SubmitButton onClick={handleCreateApplications}>Guardar</SubmitButton>
        </SubmitButtonDiv>
      </ProtocolInfoDiv>
    );
  };

  return (
    <>
      <Header />
      <GoBack path={`${pathHomePage}/intermediate-management/${hospitalId}`} />
      {isLoadingApplications || isLoadingHospitalApplications ? (
        <Loader />
      ) : (
        <>
          <Title>{`Aplicaciones del hospital: ${currentHospitalName}`}</Title>
          {renderApplications()}
        </>
      )}
    </>
  );
};

export default ApplicationController;
