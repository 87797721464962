import React from "react";
// Components
import VerifySession from "../../../config/verifySession";
import AdminValidation from "../../../config/adminValidation";
import CliniciansManagement from "../../../components/hospitalsManagement/sections/clinicianController";

const CliniciansManagementView = () => (
  <>
    {VerifySession()}
    {AdminValidation()}
    <CliniciansManagement />
  </>
);

export default CliniciansManagementView;
