import React, { useState, useEffect } from "react";
import { useParams, Redirect } from "react-router-dom";
import { toast } from "react-toastify";
// Redux
import { useSelector } from "react-redux";
// Constants
import { pathHomePage, WEB_SERVICE_DASHBOARD_ROUTE } from "../../../../../config/constants";
// Components
import { Title, HospitalInfoDiv, DataDiv, ColumnDataDiv, InputDataDiv, Label, Input, SubmitButton, SubmitButtonDiv } from "./styles";
import Header from "../../../../header";
import GoBack from "../../../../goBack";
import Loader from "../../../../loader";
import Axios from "../../../../../config/axios";

const EditHospitals = () => {
  const [isLoadingHospital, setIsLoadingHospital] = useState(true);
  const [hospitalName, setHospitalName] = useState("");
  const [hospitalNameError, setHospitalNameError] = useState(false);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [contact, setContact] = useState("");
  const [address, setAddress] = useState("");
  const [redirectToHospitalsManagement, setRedirectToHospitalsManagement] = useState(false);
  const { hospitalId } = useParams();
  const isLoggedIn = useSelector(({ user }) => user.isLoggedIn);
  const environment = useSelector(({ database }) => database.environment);

  // Fetching hospital data by id
  useEffect(() => {
    if (isLoggedIn && environment !== "") {
      Axios("get", `${WEB_SERVICE_DASHBOARD_ROUTE}/hospital/get-hospital-by-id/${environment}/${hospitalId}`)
        .then(({ data }) => {
          const hospital = data[0];
          setHospitalName(hospital.NAME && hospital.NAME);
          setEmail(hospital.EMAIL && hospital.EMAIL);
          setPhone(hospital.PHONE && hospital.PHONE);
          setContact(hospital.CONTACT && hospital.CONTACT);
          setAddress(hospital.ADDRESS && hospital.ADDRESS);
          setIsLoadingHospital(false);
        })
        .catch((error) => {
          console.log("Get hospital by id: ", error);
        });
    }
  }, [isLoggedIn, environment, hospitalId]);

  const onChangeHospitalName = (e) => {
    setHospitalName(e.target.value);
    setHospitalNameError(false);
  };

  const handleEditHospital = (e) => {
    e.preventDefault();
    if (hospitalName === "") {
      setHospitalNameError(true);
      return toast.error("You must enter a hospital name");
    }
    const hospitalData = {
      NAME: hospitalName,
      EMAIL: email === "" ? null : email,
      PHONE: phone === "" ? null : phone,
      CONTACT: contact === "" ? null : contact,
      ADDRESS: address === "" ? null : address,
    };
    Axios("put", `${WEB_SERVICE_DASHBOARD_ROUTE}/hospital/update/${environment}/${hospitalId}`, hospitalData)
      .then(({ data }) => {
        if (data.updatedHospital) {
          toast.success("Hospital successfully updated");
          setRedirectToHospitalsManagement(true);
        }
      })
      .catch((error) => {
        toast.error("Error sending data, try again");
        console.log("Edit hospital: ", error);
      });
  };
  if (redirectToHospitalsManagement) {
    return <Redirect to={`${pathHomePage}/hospital-management`} />;
  }

  const renderEditHospitalForm = () => {
    return (
      <HospitalInfoDiv>
        <DataDiv>
          <ColumnDataDiv>
            <InputDataDiv>
              <Label htmlFor="hospitalName" fontWeight="bold">
                Hospital name *
              </Label>
              <Input
                type="text"
                id="hospitalName"
                value={hospitalName}
                onChange={onChangeHospitalName}
                error={hospitalNameError}
                padding="5px 5px 5px 10px"
              />
            </InputDataDiv>
            <InputDataDiv>
              <Label htmlFor="email" fontWeight="bold">
                Email
              </Label>
              <Input type="text" id="email" value={email} onChange={(e) => setEmail(e.target.value)} padding="5px 5px 5px 10px" />
            </InputDataDiv>
            <InputDataDiv>
              <Label htmlFor="phone" fontWeight="bold">
                Phone
              </Label>
              <Input type="text" id="phone" value={phone} onChange={(e) => setPhone(e.target.value)} padding="5px 5px 5px 10px" />
            </InputDataDiv>
          </ColumnDataDiv>
          <ColumnDataDiv>
            <InputDataDiv>
              <Label htmlFor="contact" fontWeight="bold">
                Contact
              </Label>
              <Input type="text" id="contact" value={contact} onChange={(e) => setContact(e.target.value)} padding="5px 5px 5px 10px" />
            </InputDataDiv>
            <InputDataDiv>
              <Label htmlFor="address" fontWeight="bold">
                Address
              </Label>
              <Input type="text" id="address" value={address} onChange={(e) => setAddress(e.target.value)} padding="5px 5px 5px 10px" />
            </InputDataDiv>
          </ColumnDataDiv>
        </DataDiv>
        <SubmitButtonDiv>
          <SubmitButton onClick={handleEditHospital}>Save</SubmitButton>
        </SubmitButtonDiv>
      </HospitalInfoDiv>
    );
  };

  return (
    <>
      <Header />
      <GoBack path={`${pathHomePage}/hospital-management`} />
      {isLoadingHospital ? (
        <Loader />
      ) : (
        <>
          <Title>Edit hospital</Title>
          {renderEditHospitalForm()}
        </>
      )}
    </>
  );
};

export default EditHospitals;
