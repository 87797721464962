import styled from "styled-components";
import { primaryColor } from "../../../../config/constants";
import { Link } from "react-router-dom";

export const Title = styled.h2`
  margin: 0 0 20px;
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "26px")};
  text-align: center;
`;
export const CreateHospitalDiv = styled.div`
  margin: 10px auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const CreateClinicianLink = styled(Link)`
  margin: 10px auto;
  padding: 10px;
  font-size: 14px;
  color: #000;
  border: 2px solid ${primaryColor};
  outline: none;
  text-decoration: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.15s ease-in-out;
  &:hover {
    background-color: ${primaryColor};
    color: #fff;
  }
`;
export const TableContainer = styled.div`
  margin: 0 auto;
  width: 1400px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const TableTitle = styled.p`
  width: ${({ width }) => (width ? width : "200px")};
  font-size: 16px;
  font-weight: bold;
  text-align: center;
`;
export const ClinicianContainer = styled.div`
  margin: 5px auto;
  padding: 10px 0;
  width: 1400px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 5px 15px rgb(0 0 0 / 10%);
`;
export const ClinicianUser = styled.p`
  width: 250px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
`;
export const ClinicianInfo = styled.p`
  width: ${({ width }) => (width ? width : "200px")};
  font-size: 14px;
  text-align: center;
`;
export const ClinicianLink = styled(Link)`
  outline: none;
  text-decoration: none;
  cursor: pointer;
`;
export const ClinicianExternalLink = styled.a`
  outline: none;
  text-decoration: none;
  cursor: pointer;
`;
export const ClinicianImg = styled.img`
  width: 20px;
  height: 20px;
`;
