import React from "react";
// Components
import VerifySession from "../../config/verifySession";
import Hospitals from "../../components/hospitals";

const HospitalsView = () => (
  <>
    {VerifySession()}
    <Hospitals />
  </>
);

export default HospitalsView;
