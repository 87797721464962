import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
// Constants
import { pathHomePage } from "../config/constants";
// Views
import SignIn from "../views/SignIn";
import Projects from "../views/Projects";
import Hospitals from "../views/Hospitals";
import Patients from "../views/Patients";
import Graphics from "../views/Graphics";
import ActivePatients from "../views/Active-Patients";
import ClinicianActivity from "../views/Clinician-Activity";
import PatientsDevices from "../views/Patients-Devices";
// Protocols management
import ProtocolsManagement from "../views/Protocols-Management";
import CreateProtocol from "../views/Protocols-Management/Create-Protocol";
import EditProtocol from "../views/Protocols-Management/Edit-Protocol";
// Hospitals management
import HospitalsManagement from "../views/Hospitals-Management";
import CreateHospital from "../views/Hospitals-Management/Create-Hospital";
import EditHospital from "../views/Hospitals-Management/Edit-Hospital";
// Intermediate management
import IntermediateManagement from "../views/Hospitals-Management/Intermediate-Management";
// Clinicians management
import CliniciansManagement from "../views/Hospitals-Management/Clinicians-Management";
import CreateClinician from "../views/Hospitals-Management/Clinicians-Management/Create-Clinician";
import EditClinician from "../views/Hospitals-Management/Clinicians-Management/Edit-Clinician";
// Hospital applications management
import ApplicationsManagement from "../views/Hospitals-Management/Applications-Management";
// Hospital protocols management
import HospitalProtocolsManagement from "../views/Hospitals-Management/Protocols-Management";
// Stations management
import StationsManagement from "../views/Hospitals-Management/Stations-Management";
import CreateStation from "../views/Hospitals-Management/Stations-Management/Create-Station";
// Hospitals by project
import HospitalsByProjects from "../views/Hospitals-By-Project";
import HospitalsData from "../views/Hospitals-Data";
import HospitalData from "../views/Hospital-Data";
import PatientData from "../views/Patient-Data";

const Router = () => (
  <BrowserRouter>
    <Switch>
      <Route path="/" component={SignIn} exact />
      <Route path={`${pathHomePage}/home`} component={Projects} />
      <Route path={`${pathHomePage}/hospitals`} component={Hospitals} />
      <Route path={`${pathHomePage}/patients`} component={Patients} />
      <Route path={`${pathHomePage}/active-patients`} component={ActivePatients} />
      <Route path={`${pathHomePage}/clinician-activity`} component={ClinicianActivity} />
      <Route path={`${pathHomePage}/statistics`} component={Graphics} />
      <Route path={`${pathHomePage}/patients-devices`} component={PatientsDevices} />
      {/* Protocols management */}
      <Route path={`${pathHomePage}/protocol-management`} component={ProtocolsManagement} />
      <Route path={`${pathHomePage}/create-protocol`} component={CreateProtocol} />
      <Route path={`${pathHomePage}/edit-protocol/:protocolId`} component={EditProtocol} />
      {/* Hospitals management */}
      <Route path={`${pathHomePage}/hospital-management`} component={HospitalsManagement} />
      <Route path={`${pathHomePage}/create-hospital`} component={CreateHospital} />
      <Route path={`${pathHomePage}/edit-hospital/:hospitalId`} component={EditHospital} />
      {/* Intermediate management */}
      <Route path={`${pathHomePage}/intermediate-management/:hospitalId`} component={IntermediateManagement} />
      {/* Clinicians management */}
      <Route path={`${pathHomePage}/clinicians-by-hospital/:hospitalId`} component={CliniciansManagement} />
      <Route path={`${pathHomePage}/create-clinician/:hospitalId`} component={CreateClinician} />
      <Route path={`${pathHomePage}/edit-clinician/:hospitalId/:clinicianId`} component={EditClinician} />
      {/* Applications management */}
      <Route path={`${pathHomePage}/applications-by-hospital/:hospitalId`} component={ApplicationsManagement} />
      {/* Protocols management */}
      <Route path={`${pathHomePage}/protocols-by-hospital/:hospitalId`} component={HospitalProtocolsManagement} />
      {/* Stations management */}
      <Route path={`${pathHomePage}/stations-by-hospital/:hospitalId`} component={StationsManagement} />
      <Route path={`${pathHomePage}/create-station/:hospitalId`} component={CreateStation} />
      {/* Hospitals by project */}
      <Route path={`${pathHomePage}/hospitals-by-project`} component={HospitalsByProjects} />
      <Route path={`${pathHomePage}/hospitals-data/:project`} component={HospitalsData} />
      <Route path={`${pathHomePage}/hospital-data/:project/:hospitalId`} component={HospitalData} />
      <Route path={`${pathHomePage}/patient-data/:project/:patientId`} component={PatientData} />
      {/* redirect user to SignIn page if route does not exist and user is not authenticated */}
      <Route component={SignIn} />
    </Switch>
  </BrowserRouter>
);

export default Router;
