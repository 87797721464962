import React from "react";
// Components
import VerifySession from "../../config/verifySession";
import HospitalsByProjects from "../../components/hospitalsByProject";

const HospitalsByProjectsView = () => (
  <>
    {VerifySession()}
    <HospitalsByProjects />
  </>
);

export default HospitalsByProjectsView;
