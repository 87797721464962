import styled from "styled-components";

export const Title = styled.h2`
  margin: 20px 0;
  font-size: 26px;
  text-align: center;
`;
export const Select = styled.select`
  min-width: 150px;
  font-size: 16px;
`;
export const PeriodContainer = styled.div`
  margin: 0 auto 20px;
  width: 100%;
  max-width: 900px;
  display: flex;
  align-items: end;
`;
export const EachPeriodFilterDiv = styled.div`
  margin: 0 30px;
`;
export const Table = styled.div`
  padding: 0 40px 0 20px;
  width: fit-content;
  display: flex;
`;
export const TableHeaderField = styled.p`
  width: ${({ width }) => (width ? width : "100px")};
  font-size: 14px;
  font-weight: bold;
  text-align: center;
`;
export const ClinicianActivitiesDiv = styled.div`
  margin: 0 auto 50px;
  padding-left: 200px;
  display: flex;
  flex-direction: column;
`;
export const ClinicianActivityDiv = styled.div`
  margin: 5px 0;
  padding: 10px 0 10px 20px;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #fff;
  box-shadow: rgb(0 0 0 / 15%) 0px 5px 15px;
  border-radius: 5px;
`;
export const ClinicianActivityPerMonth = styled.p`
  width: ${({ width }) => (width ? width : "100px")};
  font-size: 16px;
  text-align: center;
  font-weight: ${({ fontWeight }) => fontWeight};
`;
