import styled from "styled-components";

export const Title = styled.h2`
  margin: 20px 0;
  font-size: 26px;
  text-align: center;
`;
export const SoftwareFilterDiv = styled.div`
  margin: 0 auto 20px;
  width: 100%;
  max-width: 900px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;
export const EachSoftwareFilterDiv = styled.div`
  margin-right: 20px;
`;
export const CheckboxInput = styled.input`
  cursor: pointer;
`;
export const CheckboxLabel = styled.label`
  margin: 0 10px;
`;
export const Select = styled.select`
  min-width: 150px;
  font-size: 16px;
`;
export const Option = styled.option``;
export const PeriodContainer = styled.div`
  margin: 0 auto 20px;
  width: 100%;
  max-width: 900px;
  display: flex;
  align-items: end;
`;
export const EachPeriodFilterDiv = styled.div`
  margin: 0 30px;
`;
export const HospitalsContainer = styled.div`
  margin: 0 auto 50px;
  padding-left: 200px;
  display: flex;
  flex-direction: column;
`;
export const Table = styled.div`
  padding: 0 40px 0 20px;
  width: fit-content;
  display: flex;
`;
export const TableHospitalTitle = styled.button`
  width: 200px;
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  background: transparent;
  border: none;
  cursor: pointer;
`;
export const TableColumnDataButton = styled.button`
  width: 120px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  background: ${({ isMarked }) => (isMarked ? "#939090" : "transparent")};
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;
export const HospitalContainer = styled.div`
  margin: 5px 0;
  padding: 10px 0 10px 20px;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #fff;
  box-shadow: rgb(0 0 0 / 15%) 0px 5px 15px;
  border-radius: 5px;
`;
export const HospitalName = styled.p`
  width: 200px;
  font-size: 16px;
  font-weight: ${({ fontWeight }) => fontWeight};
`;
export const HospitalMonth = styled.p`
  width: 120px;
  font-size: 16px;
  text-align: center;
  font-weight: ${({ fontWeight }) => fontWeight};
`;
