import React from "react";
import { protocols } from "./constants";
import moment from "moment";

export const setProtocolTitle = (protocol) => {
  switch (Number(protocol.PROTOCOL_ID)) {
    // Clinic - Home
    case protocols.clinicAndHome.SPHEROIDS_ID:
      protocol.NAME = "Spheroids";
      break;
    case protocols.clinicAndHome.PINBALL_ID:
      protocol.NAME = "Pinball";
      break;
    case protocols.clinicAndHome.HOCKEY_ID:
      protocol.NAME = "Hockey";
      break;
    case protocols.clinicAndHome.CLEAN_THE_TABLE_ID:
      protocol.NAME = "Clean the table";
      break;
    case protocols.clinicAndHome.CONSTELLATIONS_ID:
      protocol.NAME = "Constellations";
      break;
    case protocols.clinicAndHome.GRASP_AND_PLACE_ID:
      protocol.NAME = "Grasp and place";
      break;
    case protocols.clinicAndHome.FOLLOW_THE_LINE_ID:
      protocol.NAME = "Follow the line";
      break;
    case protocols.clinicAndHome.ROAD_TRIP_ID:
      protocol.NAME = "Road trip";
      break;
    case protocols.clinicAndHome.BRICKS_ID:
      protocol.NAME = "Bricks";
      break;
    case protocols.clinicAndHome.BOAT_ID:
      protocol.NAME = "Boat";
      break;
    case protocols.clinicAndHome.BUBBLES_ID:
      protocol.NAME = "Bubbles";
      break;
    case protocols.clinicAndHome.DEMOLITION_ID:
      protocol.NAME = "Demolition";
      break;
    // App: Basic protocols
    case protocols.app.basic.MEMOSEQ_APP_ID:
      protocol.NAME = "Memoseq App";
      break;
    case protocols.app.basic.PLACE_IT_ID:
      protocol.NAME = "Place it";
      break;
    case protocols.app.basic.GUESS_WHAT_ID:
      protocol.NAME = "Guess what";
      break;
    case protocols.app.basic.PIANOSEQ_ID:
      protocol.NAME = "Pianoseq";
      break;
    case protocols.app.basic.BLOBS_ID:
      protocol.NAME = "Blobs";
      break;
    case protocols.app.basic.SHOPPING_ID:
      protocol.NAME = "Shopping";
      break;
    case protocols.app.basic.POPURRI_ID:
      protocol.NAME = "Popurri";
      break;
    case protocols.app.basic.TWISTER_ID:
      protocol.NAME = "Twister";
      break;
    case protocols.app.basic.MEMORY_ID:
      protocol.NAME = "Memory";
      break;
    case protocols.app.basic.CLEANING_ID:
      protocol.NAME = "Cleaning";
      break;
    case protocols.app.basic.CONSTELLATIONS:
      protocol.NAME = "Constellations";
      break;
    case protocols.app.basic.BALLOONS_BASIC:
      protocol.NAME = "Balloons";
      break;
    case protocols.app.basic.QUALITY_CONTROL_BASIC:
      protocol.NAME = "Quality control";
      break;
    // App: Tensor flow protocols
    case protocols.app.tensorFlow.ARMANOID_ID:
      protocol.NAME = "Armanoid";
      break;
    case protocols.app.tensorFlow.DRIVING_ID:
      protocol.NAME = "Driving";
      break;
    // App: AR protocols
    case protocols.app.ar.TRAINING_ID:
      protocol.NAME = "Training AR";
      break;
    case protocols.app.ar.SHELVES_ID:
      protocol.NAME = "Shelves AR";
      break;
    case protocols.app.ar.BUFFET_ID:
      protocol.NAME = "Buffet AR";
      break;
    case protocols.app.ar.ALPHABET_ID:
      protocol.NAME = "Alphabet AR";
      break;
    case protocols.app.ar.BALLOONS_ID:
      protocol.NAME = "Balloons AR";
      break;
    case protocols.app.ar.DUCKS_ID:
      protocol.NAME = "Ducks AR";
      break;
    case protocols.app.ar.SPHEROIDS_AR_ID:
      protocol.NAME = "Spheroids AR";
      break;
    case protocols.app.ar.HOCKEY_AR_ID:
      protocol.NAME = "Hockey AR";
      break;
    case protocols.app.ar.CIRCLE_AR_ID:
      protocol.NAME = "Vertical circle AR";
      break;
    case protocols.app.ar.WHAC_A_MOLE_AR_ID:
      protocol.NAME = "Whac a mole AR";
      break;
    case protocols.app.ar.TUBES_ID:
      protocol.NAME = "Tubes AR";
      break;
    case protocols.app.ar.CLEANING_AR_ID:
      protocol.NAME = "Cleaning AR";
      break;
    case protocols.app.ar.CLEANING_AR_VERTICAL_ID:
      protocol.NAME = "Cleaning AR Vertical AR";
      break;
    case protocols.app.ar.CIRCLE_HORIZONTAL_ID:
      protocol.NAME = "Horizontal circle AR";
      break;
    case protocols.app.ar.QUALITY_CONTROL_ID:
      protocol.NAME = "Quality control AR";
      break;
    case protocols.app.ar.CONSTELLATIONS_AR:
      protocol.NAME = "Constellations AR";
      break;
    // Web
    case protocols.web.MEMOSEQ_WEB_ID:
      protocol.NAME = "Memoseq Web";
      break;
    case protocols.web.MIRROR_WEB_ID:
      protocol.NAME = "Mirror";
      break;
    case protocols.web.RECYCLING_WEB_ID:
      protocol.NAME = "Recycling";
      break;
    case protocols.web.WHACK_A_MOLE_WEB_ID:
      protocol.NAME = "Whack a mole";
      break;
    case protocols.web.FULL_BODY_ID:
      protocol.NAME = "Full body";
      break;
    case protocols.web.BALANCE_ID:
      protocol.NAME = "Balance";
      break;
    case protocols.web.SEQUENCES_ID:
      protocol.NAME = "Sequences";
      break;
    case protocols.web.WORDS_ID:
      protocol.NAME = "Words";
      break;
    case protocols.web.TWISTER_BUDDY_ID:
      protocol.NAME = "Twister buddy";
      break;
    case protocols.web.COSTUME_PARTY_ID:
      protocol.NAME = "Costume party";
      break;
    case protocols.web.GENTLE_GIANT_ID:
      protocol.NAME = "Gentle giant";
      break;
    default:
      break;
  }
};

export const returnProtocolTitle = (protocolId, software) => {
  if (software === "clinic") {
    if (protocolId === protocols.clinicAndHome.SPHEROIDS_ID) return "Clinic Spheroids";
    if (protocolId === protocols.clinicAndHome.PINBALL_ID) return "Clinic Pinball";
    if (protocolId === protocols.clinicAndHome.HOCKEY_ID) return "Clinic Hockey";
    if (protocolId === protocols.clinicAndHome.CLEAN_THE_TABLE_ID) return "Clinic Clean the table";
    if (protocolId === protocols.clinicAndHome.GRASP_AND_PLACE_ID) return "Clinic Grasp and place";
    if (protocolId === protocols.clinicAndHome.FOLLOW_THE_LINE_ID) return "Clinic Follow the line";
    if (protocolId === protocols.clinicAndHome.ROAD_TRIP_ID) return "Clinic Road trip";
    if (protocolId === protocols.clinicAndHome.BRICKS_ID) return "Clinic Bricks";
    if (protocolId === protocols.clinicAndHome.BOAT_ID) return "Clinic Boat";
    if (protocolId === protocols.clinicAndHome.BUBBLES_ID) return "Clinic Bubbles";
    if (protocolId === protocols.clinicAndHome.DEMOLITION_ID) return "Clinic Demolition";
    if (protocolId === protocols.clinicAndHome.CONSTELLATIONS_ID) return "Clinic Constellations";
    if (protocolId === protocols.clinicAndHome.TWISTER_BUDDY) return "Clinic Twister buddy";
    if (protocolId === protocols.clinicAndHome.GENTLE_GIANT_ID) return "Clinic Gentle giant";
  }
  if (software === "home") {
    if (protocolId === protocols.clinicAndHome.SPHEROIDS_ID) return "Home Spheroids";
    if (protocolId === protocols.clinicAndHome.PINBALL_ID) return "Home Pinball";
    if (protocolId === protocols.clinicAndHome.HOCKEY_ID) return "Home Hockey";
    if (protocolId === protocols.clinicAndHome.CLEAN_THE_TABLE_ID) return "Home Clean the table";
    if (protocolId === protocols.clinicAndHome.GRASP_AND_PLACE_ID) return "Home Grasp and place";
    if (protocolId === protocols.clinicAndHome.FOLLOW_THE_LINE_ID) return "Home Follow the line";
    if (protocolId === protocols.clinicAndHome.ROAD_TRIP_ID) return "Home Road trip";
    if (protocolId === protocols.clinicAndHome.BRICKS_ID) return "Home Bricks";
    if (protocolId === protocols.clinicAndHome.BOAT_ID) return "Home Boat";
    if (protocolId === protocols.clinicAndHome.BUBBLES_ID) return "Home Bubbles";
    if (protocolId === protocols.clinicAndHome.DEMOLITION_ID) return "Home Demolition";
    if (protocolId === protocols.clinicAndHome.CONSTELLATIONS_ID) return "Home Constellations";
    if (protocolId === protocols.clinicAndHome.TWISTER_BUDDY) return "Home Twister buddy";
    if (protocolId === protocols.clinicAndHome.GENTLE_GIANT_ID) return "Home Gentle giant";
  }
  if (software === "app") {
    // Basic protocols
    if (protocolId === protocols.app.basic.MEMOSEQ_APP_ID) return "App Memoseq";
    if (protocolId === protocols.app.basic.PLACE_IT_ID) return "App Place it";
    if (protocolId === protocols.app.basic.GUESS_WHAT_ID) return "App Guess what";
    if (protocolId === protocols.app.basic.PIANOSEQ_ID) return "App Pianoseq";
    if (protocolId === protocols.app.basic.BLOBS_ID) return "App Blobs";
    if (protocolId === protocols.app.basic.SHOPPING_ID) return "App Shopping";
    if (protocolId === protocols.app.basic.POPURRI_ID) return "App Popurri";
    if (protocolId === protocols.app.basic.TWISTER_ID) return "App Twister";
    if (protocolId === protocols.app.basic.MEMORY_ID) return "App Memory";
    if (protocolId === protocols.app.basic.CLEANING_ID) return "App Cleaning";
    if (protocolId === protocols.app.basic.CONSTELLATIONS) return "App Constellations";
    if (protocolId === protocols.app.basic.BALLOONS_BASIC) return "App Balloons";
    if (protocolId === protocols.app.basic.QUALITY_CONTROL_BASIC) return "App Quality control";
    // Tensor flow protocols
    if (protocolId === protocols.app.tensorFlow.ARMANOID_ID) return "App Armanoid";
    if (protocolId === protocols.app.tensorFlow.DRIVING_ID) return "App Driving";
    // AR protocols
    if (protocolId === protocols.app.ar.TRAINING_ID) return "App Training AR";
    if (protocolId === protocols.app.ar.SHELVES_ID) return "App Shelves AR";
    if (protocolId === protocols.app.ar.BUFFET_ID) return "App Buffet AR";
    if (protocolId === protocols.app.ar.ALPHABET_ID) return "App Alphabet AR";
    if (protocolId === protocols.app.ar.BALLOONS_ID) return "App Balloons AR";
    if (protocolId === protocols.app.ar.DUCKS_ID) return "App Ducks AR";
    if (protocolId === protocols.app.ar.SPHEROIDS_AR_ID) return "App Spheroids AR";
    if (protocolId === protocols.app.ar.HOCKEY_AR_ID) return "App Hockey AR";
    if (protocolId === protocols.app.ar.CIRCLE_AR_ID) return "App Circle AR";
    if (protocolId === protocols.app.ar.WHAC_A_MOLE_AR_ID) return "App Whac a mole AR";
    if (protocolId === protocols.app.ar.TUBES_ID) return "App Tubes AR";
    if (protocolId === protocols.app.ar.CLEANING_AR_ID) return "App Cleaning AR";
    if (protocolId === protocols.app.ar.CLEANING_AR_VERTICAL_ID) return "App Cleaning vertical AR";
    if (protocolId === protocols.app.ar.CIRCLE_HORIZONTAL_ID) return "App Horizontal circle AR";
    if (protocolId === protocols.app.ar.QUALITY_CONTROL_ID) return "App Quality control AR";
    if (protocolId === protocols.app.ar.CONSTELLATIONS_AR) return "App Constellations AR";
  }
  // Web
  if (software === "web") {
    if (protocolId === protocols.web.MEMOSEQ_WEB_ID) return "Web Memoseq";
    if (protocolId === protocols.web.MIRROR_WEB_ID) return "Web Mirror";
    if (protocolId === protocols.web.RECYCLING_WEB_ID) return "Web Recycling";
    if (protocolId === protocols.web.WHACK_A_MOLE_WEB_ID) return "Web Whack a mole";
    if (protocolId === protocols.web.FULL_BODY_ID) return "Web Full body";
    if (protocolId === protocols.web.BALANCE_ID) return "Web Balance";
    if (protocolId === protocols.web.SEQUENCES_ID) return "Web Sequences";
    if (protocolId === protocols.web.WORDS_ID) return "Web Words";
    if (protocolId === protocols.web.TWISTER_BUDDY_ID) return "Web Twister buddy";
    if (protocolId === protocols.web.COSTUME_PARTY_ID) return "Web Costume party";
    if (protocolId === protocols.web.GENTLE_GIANT_ID) return "Web Gentle giant";
  }

  return "";
};

export const setMonth = (currentMonth) => {
  switch (Number(currentMonth)) {
    case 1:
      return "Jan";
    case 2:
      return "Feb";
    case 3:
      return "Mar";
    case 4:
      return "Apr";
    case 5:
      return "May";
    case 6:
      return "Jun";
    case 7:
      return "Jul";
    case 8:
      return "Aug";
    case 9:
      return "Sep";
    case 10:
      return "Oct";
    case 11:
      return "Nov";
    case 12:
      return "Dec";
    default:
      break;
  }
};

export const setCurrentPeriodOfMonths = (
  filterStartMonth,
  filterStartYear,
  filterEndMonth,
  filterEndYear,
  setCurrentPeriodFiltered,
  setTargetedPeriod
) => {
  if (filterStartMonth !== 0 && filterStartYear !== 0 && filterEndMonth !== 0 && filterEndYear !== 0) {
    const initialPeriodDate = new Date(filterStartYear, filterStartMonth - 1);
    const endPeriodDate = new Date(filterEndYear, filterEndMonth);
    let currentPeriodArray = [];
    let currentTargetedPeriodArray = [];
    for (let i = initialPeriodDate; i < endPeriodDate; i.setMonth(i.getMonth() + 1)) {
      if (i <= new Date()) {
        currentPeriodArray.push({
          month: Number(moment(i).format("MM")),
          year: Number(moment(i).format("YYYY")),
        });
        currentTargetedPeriodArray.push(`${setMonth(Number(moment(i).format("MM")))} ${moment(i).format("YYYY")}`);
      }
    }
    setCurrentPeriodFiltered(currentPeriodArray);
    if (setTargetedPeriod) setTargetedPeriod(currentTargetedPeriodArray);
  }
};
export const setCurrentPeriodOfMonthsForStrack = (
  filterStartMonth,
  filterStartYear,
  filterEndMonth,
  filterEndYear,
  setCurrentPeriodFiltered,
  setTargetedPeriod
) => {
  if (filterStartMonth !== 0 && filterStartYear !== 0 && filterEndMonth !== 0 && filterEndYear !== 0) {
    const initialPeriodDate = new Date(filterStartYear, filterStartMonth - 1);
    const endPeriodDate = new Date(filterEndYear, filterEndMonth);
    let currentPeriodArray = [];
    let currentTargetedPeriodArray = [];
    const dateOfFirstDataOnDB = new Date(2022, 4, 1);
    for (let i = initialPeriodDate; i < endPeriodDate; i.setMonth(i.getMonth() + 1)) {
      if (i >= dateOfFirstDataOnDB && i <= new Date()) {
        currentPeriodArray.push({
          month: Number(moment(i).format("MM")),
          year: Number(moment(i).format("YYYY")),
        });
        currentTargetedPeriodArray.push(`${setMonth(Number(moment(i).format("MM")))} ${moment(i).format("YYYY")}`);
      }
    }
    setCurrentPeriodFiltered(currentPeriodArray);
    if (setTargetedPeriod) setTargetedPeriod(currentTargetedPeriodArray);
  }
};

export const renderMonthsOptions = () => {
  const months = [
    { value: 1, label: "January" },
    { value: 2, label: "February" },
    { value: 3, label: "March" },
    { value: 4, label: "April" },
    { value: 5, label: "May" },
    { value: 6, label: "June" },
    { value: 7, label: "July" },
    { value: 8, label: "August" },
    { value: 9, label: "September" },
    { value: 10, label: "October" },
    { value: 11, label: "November" },
    { value: 12, label: "December" },
  ];

  return (
    <>
      {months.map((month) => (
        <option key={month.value} value={month.value}>
          {month.label}
        </option>
      ))}
    </>
  );
};

export const renderYearsOptions = () => {
  let listYears = [];
  for (let i = new Date(2022, 1, 1).getFullYear(); i <= new Date().getFullYear(); i++) {
    listYears.unshift(i);
  }
  return listYears.map((year) => {
    return (
      <option key={year} value={year}>
        {year}
      </option>
    );
  });
};

export const passwordValidation = (password) => {
  const regExp = /^(?=.{8,})(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!<>#$%&"'()*+,-./@?])/;
  return regExp.test(password);
};

export const handleGenerateRandomPassword = () => {
  const repetitions = 3;
  const smallLetters = "abcdefghijklmnopqrstuvwxyz";
  const capitalLetters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const numbers = "0123456789";
  const characters = "!@#$%&/.?";
  let retVal = "";
  for (let i = 0; i < repetitions; ++i) {
    retVal += smallLetters.charAt(Math.floor(Math.random() * smallLetters.length));
    retVal += capitalLetters.charAt(Math.floor(Math.random() * capitalLetters.length));
    retVal += numbers.charAt(Math.floor(Math.random() * numbers.length));
    retVal += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return retVal;
};

export const getTrainedMinutesAndSeconds = (trainedSeconds) => {
  const minutes = Math.floor(trainedSeconds / 60);
  const seconds = trainedSeconds - minutes * 60;
  return `${minutes}’ ${seconds}’’`;
};

export const getAge = (birthDateParam) => {
  const now = new Date();
  const birthDate = new Date(birthDateParam);
  const m = now.getMonth() - birthDate.getMonth();
  let age = now.getFullYear() - birthDate.getFullYear();
  if (m < 0 || (m === 0 && now.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};
