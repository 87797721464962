import React, { useState } from "react";
// Constants
import { pathHomePage } from "../../../../config/constants";
// Components
import {
  HospitalContainer,
  HospitalInfo,
  HospitalDataLink,
  ExternalLink,
  ExternalLinkImg,
  PatientsListButton,
  PatientsListImg,
} from "./styles";
import RenderPatientsData from "../renderPatientData";
// Assets
import ExternalLinkIcon from "../../../../assets/external_link_icon.svg";
import ArrowDownIcon from "../../../../assets/ico_arrow_down_blue.svg";
import ArrowUpIcon from "../../../../assets/ico_arrow_up_blue.svg";

const RenderHospitalData = ({ hospital, project, urlToMims }) => {
  const [isShowingPatientsList, setIsShowingPatientsList] = useState(false);
  const [hospitalId] = useState(hospital.HOSPITAL_ID);
  const [patientsNumber] = useState(hospital.patients.length);
  const [patients] = useState(hospital.patients);

  const renderPatientsList = () => {
    return <RenderPatientsData patients={patients} project={project} />;
  };

  return (
    <>
      <HospitalContainer>
        <HospitalInfo width="200px">{hospital.HOSPITAL_NAME}</HospitalInfo>
        <HospitalInfo width="110px">
          {patientsNumber ? (
            <HospitalDataLink to={`${pathHomePage}/hospital-data/${project}/${hospitalId}`}>
              <ExternalLinkImg src={ExternalLinkIcon} alt="external-link-icon" />
            </HospitalDataLink>
          ) : (
            "No patients"
          )}
        </HospitalInfo>
        <HospitalInfo width="120px">
          {hospital.tokenToMims ? (
            <ExternalLink href={urlToMims} target="_blank">
              <ExternalLinkImg src={ExternalLinkIcon} alt="external-link-icon" />
            </ExternalLink>
          ) : (
            "No access"
          )}
        </HospitalInfo>
        <HospitalInfo width="130px">{hospital.patients.length}</HospitalInfo>
        {patientsNumber ? (
          <PatientsListButton width="110px" onClick={() => setIsShowingPatientsList(!isShowingPatientsList)}>
            <PatientsListImg src={isShowingPatientsList ? ArrowUpIcon : ArrowDownIcon} />
          </PatientsListButton>
        ) : (
          <HospitalInfo width="110px">No patients</HospitalInfo>
        )}
      </HospitalContainer>
      {isShowingPatientsList && renderPatientsList()}
    </>
  );
};

export default RenderHospitalData;
