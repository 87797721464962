import React, { useState, useEffect } from "react";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { setCurrentHospitalName } from "../../redux/hospitalDucks";
// Constants
import { pathHomePage, WEB_SERVICE_DASHBOARD_ROUTE } from "../../config/constants";
// Components
import {
  Title,
  CreateHospitalDiv,
  CreateHospitalLink,
  TableContainer,
  TableTitle,
  HospitalContainer,
  HospitalNameLink,
  HospitalInfo,
  EditHospitalLink,
  EditHospitalImg,
} from "./styles";
import Header from "../header";
import Loader from "../loader";
import UseFetch from "../../config/useFetch";
// Assets
import EditIcon from "../../assets/edit_icon.svg";

const HospitalsManagement = () => {
  const [isLoadingHospitals, setIsLoadingHospitals] = useState(true);
  const [hospitals, setHospitals] = useState([]);
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(({ user }) => user.isLoggedIn);
  const environment = useSelector(({ database }) => database.environment);

  // Fetching hospitals data
  useEffect(() => {
    const getHospitalsSignal = new AbortController();

    if (isLoggedIn && environment) {
      UseFetch("get", `${WEB_SERVICE_DASHBOARD_ROUTE}/hospital/get-list/${environment}`, getHospitalsSignal)
        .then(({ data }) => {
          setHospitals(data);
          setIsLoadingHospitals(false);
        })
        .catch((error) => {
          console.log("Get hospitals: ", error);
        });
    }

    return () => {
      setHospitals([]);
      setIsLoadingHospitals(true);
      if (getHospitalsSignal) {
        getHospitalsSignal.abort();
      }
    };
  }, [isLoggedIn, environment]);

  const renderTableHeader = () => {
    return (
      <TableContainer>
        <TableTitle>Hospital</TableTitle>
        <TableTitle width="55px">Edit</TableTitle>
        <TableTitle>Email</TableTitle>
        <TableTitle>Phone</TableTitle>
        <TableTitle>Contact</TableTitle>
        <TableTitle>Address</TableTitle>
      </TableContainer>
    );
  };

  const renderHospitals = () => {
    const handleSetCurrentHospitalName = (hospitalName) => {
      dispatch(setCurrentHospitalName(hospitalName));
    };

    return hospitals.map((hospital) => {
      const isNull = (value) => value === null || value === "";
      const hospitalId = hospital.HOSPITAL_ID;
      return (
        <HospitalContainer key={hospitalId}>
          <HospitalNameLink
            onClick={() => handleSetCurrentHospitalName(hospital.NAME)}
            to={`${pathHomePage}/intermediate-management/${hospitalId}`}
          >
            {hospital.NAME}
          </HospitalNameLink>
          <HospitalInfo width="55px">
            <EditHospitalLink to={`${pathHomePage}/edit-hospital/${hospitalId}`}>
              <EditHospitalImg src={EditIcon} alt="edit-icon" />
            </EditHospitalLink>
          </HospitalInfo>
          <HospitalInfo>{isNull(hospital.EMAIL) ? "n/a" : hospital.EMAIL}</HospitalInfo>
          <HospitalInfo>{isNull(hospital.PHONE) ? "n/a" : hospital.PHONE}</HospitalInfo>
          <HospitalInfo>{isNull(hospital.CONTACT) ? "n/a" : hospital.CONTACT}</HospitalInfo>
          <HospitalInfo>{isNull(hospital.ADDRESS) ? "n/a" : hospital.ADDRESS}</HospitalInfo>
        </HospitalContainer>
      );
    });
  };

  return (
    <>
      <Header />
      {isLoadingHospitals ? (
        <Loader />
      ) : (
        <>
          <Title>Manage hospitals</Title>
          <CreateHospitalDiv>
            <CreateHospitalLink to={`${pathHomePage}/create-hospital`}>Create new hospital</CreateHospitalLink>
          </CreateHospitalDiv>
          {hospitals.length === 0 ? (
            <Title>There are no hospitals</Title>
          ) : (
            <>
              {renderTableHeader()}
              {renderHospitals()}
            </>
          )}
        </>
      )}
    </>
  );
};

export default HospitalsManagement;
