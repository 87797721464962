import React, { useState, useEffect } from "react";
import { useParams, Redirect } from "react-router-dom";
import { toast } from "react-toastify";
// Redux
import { useSelector } from "react-redux";
// Constants - Functions
import { pathHomePage, WEB_SERVICE_DASHBOARD_ROUTE, applications } from "../../../../config/constants";
// Components
import {
  Title,
  CheckboxInput,
  CheckboxLabel,
  TitleSectionDiv,
  SoftwareTitle,
  ProtocolsDiv,
  ProtocolsSectionDiv,
  CheckAllProtocolsDiv,
  CheckboxAllProtocolsInput,
  SoftwareProtocolsDiv,
  EachProtocolDiv,
  SubmitButtonDiv,
  SubmitButton,
} from "./styles";
import Header from "../../../header";
import GoBack from "../../../goBack";
import Loader from "../../../loader";
import Axios from "../../../../config/axios";

const ProtocolController = () => {
  const [isLoadingHospitalApplications, setIsLoadingHospitalApplications] = useState(true);
  const [isLoadingHospitalProtocols, setIsLoadingHospitalProtocols] = useState(true);

  const [hospitalSoftwares, setHospitalSoftwares] = useState([]);
  const [currentHospitalProtocols, setCurrentHospitalProtocols] = useState([]);

  const [clinicProtocols, setClinicProtocols] = useState([]);
  const [homeProtocols, setHomeProtocols] = useState([]);
  const [webProtocols, setWebProtocols] = useState([]);
  const [appProtocols, setAppProtocols] = useState([]);
  const [plusProtocols, setPlusProtocols] = useState([]);

  const [redirectToIntermediateController, setRedirectToIntermediateController] = useState(false);
  const { hospitalId } = useParams();
  const isLoggedIn = useSelector(({ user }) => user.isLoggedIn);
  const environment = useSelector(({ database }) => database.environment);
  const currentHospitalName = useSelector(({ hospital }) => hospital.currentName);

  // Fetching hospital softwares
  useEffect(() => {
    if (isLoggedIn && environment !== "" && hospitalId !== "") {
      Axios("get", `${WEB_SERVICE_DASHBOARD_ROUTE}/software/get-software-by-hospital/${environment}/${hospitalId}`)
        .then(({ data }) => {
          let currentSoftwares = [];
          data.forEach((software) => {
            currentSoftwares.push(software.SOFTWARE_ID);
          });
          setHospitalSoftwares(currentSoftwares);
          setIsLoadingHospitalApplications(false);
        })
        .catch((error) => {
          console.log("endpoint to get softwares: ", error);
        });
    }
  }, [isLoggedIn, environment, hospitalId]);

  // Fetching protocols by softwares
  useEffect(() => {
    if (isLoggedIn && environment !== "" && hospitalSoftwares.length) {
      // Set Clinic protocols
      if (hospitalSoftwares.includes(applications.clinicId)) {
        Axios("get", `${WEB_SERVICE_DASHBOARD_ROUTE}/protocol/by-software/${environment}/${applications.clinicId}`)
          .then(({ data }) => {
            setClinicProtocols(data);
          })
          .catch((error) => {
            console.log("Get Clinic protocols: ", error);
          });
      }
      // Set Home protocols
      if (hospitalSoftwares.includes(applications.homeId)) {
        Axios("get", `${WEB_SERVICE_DASHBOARD_ROUTE}/protocol/by-software/${environment}/${applications.homeId}`)
          .then(({ data }) => {
            setHomeProtocols(data);
          })
          .catch((error) => {
            console.log("Get Home protocols: ", error);
          });
      }
      // Set Web protocols
      if (hospitalSoftwares.includes(applications.webId)) {
        Axios("get", `${WEB_SERVICE_DASHBOARD_ROUTE}/protocol/by-software/${environment}/${applications.webId}`)
          .then(({ data }) => {
            setWebProtocols(data);
          })
          .catch((error) => {
            console.log("Get Web protocols: ", error);
          });
      }
      // Set App protocols
      if (hospitalSoftwares.includes(applications.appId)) {
        Axios("get", `${WEB_SERVICE_DASHBOARD_ROUTE}/protocol/by-software/${environment}/${applications.appId}`)
          .then(({ data }) => {
            setAppProtocols(data);
          })
          .catch((error) => {
            console.log("Get App protocols: ", error);
          });
      }
      // Set Plus protocols
      if (hospitalSoftwares.includes(applications.plusId)) {
        Axios("get", `${WEB_SERVICE_DASHBOARD_ROUTE}/protocol/by-software/${environment}/${applications.plusId}`)
          .then(({ data }) => {
            setPlusProtocols(data);
          })
          .catch((error) => {
            console.log("Get Plus protocols: ", error);
          });
      }
    }
  }, [environment, hospitalSoftwares, isLoggedIn]);

  // Fetching hospital protocols
  useEffect(() => {
    if (isLoggedIn && environment !== "" && hospitalSoftwares.length) {
      Axios("get", `${WEB_SERVICE_DASHBOARD_ROUTE}/protocol/get-protocols-by-hospital/${environment}/${hospitalId}`)
        .then(({ data }) => {
          data.forEach((protocol) => {
            protocol.isFromDatabase = true;
          });
          setCurrentHospitalProtocols(data);
          setIsLoadingHospitalProtocols(false);
        })
        .catch((error) => {
          console.log("endpoint to get protocols by hospital id: ", error);
        });
    }
  }, [isLoggedIn, hospitalSoftwares, environment, hospitalId]);

  const handleAddProtocols = (e) => {
    e.preventDefault();
    let hospitalProtocolsToDatabase = [];

    currentHospitalProtocols.forEach((hospitalProtocol) => {
      if (!hospitalProtocol.isFromDatabase) {
        hospitalProtocolsToDatabase.push(hospitalProtocol);
      }
    });

    if (hospitalProtocolsToDatabase.length > 0) {
      Axios(
        "post",
        `${WEB_SERVICE_DASHBOARD_ROUTE}/protocol/add-protocols-by-hospital/${environment}/${hospitalId}`,
        currentHospitalProtocols
      )
        .then(({ data }) => {
          if (data.AddedProtocols) {
            toast.success("Protocols added successfully");
            setRedirectToIntermediateController(true);
          }
        })
        .catch((error) => {
          toast.error("Error sending data, try again");
          console.log("endpoint to add hospital protocols: ", error);
        });
    }
  };
  if (redirectToIntermediateController) {
    return <Redirect to={`${pathHomePage}/intermediate-management/${hospitalId}`} />;
  }

  const hasTheHospitalPlus = hospitalSoftwares.includes(applications.plusId);

  const renderProtocolsCheckbox = () => {
    const renderSoftwareProtocols = (softwareProtocols) => {
      const handleSetProtocol = (protocolId) => {
        let clonedHospitalProtocols = JSON.parse(JSON.stringify(currentHospitalProtocols));
        const verifyProtocol = currentHospitalProtocols.filter((hospitalProtocol) => hospitalProtocol.PROTOCOL_ID === protocolId);
        if (verifyProtocol.length > 0) {
          clonedHospitalProtocols.forEach((hospitalProtocol) => {
            if (hospitalProtocol.PROTOCOL_ID === protocolId) {
              if (hospitalProtocol.isFromDatabase) {
                delete hospitalProtocol.isFromDatabase;
              }
              if (hospitalProtocol.EXPIRATION_DATE) {
                delete hospitalProtocol.EXPIRATION_DATE;
              } else {
                hospitalProtocol.EXPIRATION_DATE = new Date();
              }
            }
          });
          setCurrentHospitalProtocols(clonedHospitalProtocols);
        } else {
          clonedHospitalProtocols.push({
            PROTOCOL_ID: protocolId,
          });
          setCurrentHospitalProtocols(clonedHospitalProtocols);
        }
      };

      return softwareProtocols.map((softwareProtocol) => {
        const softwareProtocolId = softwareProtocol.PROTOCOL_ID;
        const softwareProtocolName = softwareProtocol.NAME_KEY;

        const checkProtocol = currentHospitalProtocols.some(
          (hospitalProtocol) => hospitalProtocol.PROTOCOL_ID === softwareProtocolId && !hospitalProtocol.EXPIRATION_DATE
        );

        return (
          <EachProtocolDiv key={softwareProtocolId}>
            <CheckboxInput
              type="checkbox"
              id={softwareProtocolId}
              checked={checkProtocol}
              onChange={() => handleSetProtocol(softwareProtocolId)}
            />
            <CheckboxLabel htmlFor={softwareProtocolId} fontSize="16px">
              {softwareProtocolName}
            </CheckboxLabel>
          </EachProtocolDiv>
        );
      });
    };

    const renderSoftwareSection = (title, softwareProtocols) => {
      const checkIfThereAreAllProtocols = (softwareProtocols) => {
        let currentProtocols = [];
        softwareProtocols.forEach((softwareProtocol) => {
          currentHospitalProtocols.forEach((hospitalProtocol) => {
            if (softwareProtocol.PROTOCOL_ID === hospitalProtocol.PROTOCOL_ID && !hospitalProtocol.EXPIRATION_DATE) {
              currentProtocols.push(softwareProtocol.PROTOCOL_ID);
            }
          });
        });
        return softwareProtocols.length === currentProtocols.length;
      };

      const handleSetHospitalProtocols = (softwareProtocols) => {
        const clonedHospitalProtocols = JSON.parse(JSON.stringify(currentHospitalProtocols));

        if (checkIfThereAreAllProtocols(softwareProtocols)) {
          softwareProtocols.forEach((softwareProtocol) => {
            clonedHospitalProtocols.forEach((hospitalProtocol) => {
              if (hospitalProtocol.PROTOCOL_ID === softwareProtocol.PROTOCOL_ID) {
                hospitalProtocol.EXPIRATION_DATE = new Date();
                if (hospitalProtocol.isFromDatabase) {
                  delete hospitalProtocol.isFromDatabase;
                }
              }
            });
          });
          return setCurrentHospitalProtocols(clonedHospitalProtocols);
        }

        const setEachSoftwareProtocol = () => {
          softwareProtocols.forEach((softwareProtocol) => {
            const checkProtocol = clonedHospitalProtocols.filter(
              (hospitalProtocol) => hospitalProtocol.PROTOCOL_ID === softwareProtocol.PROTOCOL_ID
            );
            if (checkProtocol.length < 1) {
              clonedHospitalProtocols.push({ PROTOCOL_ID: softwareProtocol.PROTOCOL_ID });
            }
            clonedHospitalProtocols.forEach((hospitalProtocol) => {
              if (hospitalProtocol.PROTOCOL_ID === softwareProtocol.PROTOCOL_ID && hospitalProtocol.EXPIRATION_DATE) {
                delete hospitalProtocol.EXPIRATION_DATE;
                if (hospitalProtocol.isFromDatabase) {
                  delete hospitalProtocol.isFromDatabase;
                }
              }
            });
          });
          return setCurrentHospitalProtocols(clonedHospitalProtocols);
        };
        return setEachSoftwareProtocol();
      };

      return (
        <ProtocolsSectionDiv>
          <TitleSectionDiv>
            <SoftwareTitle>{title}</SoftwareTitle>
          </TitleSectionDiv>
          <CheckAllProtocolsDiv>
            <CheckboxAllProtocolsInput
              type="checkbox"
              checked={checkIfThereAreAllProtocols(softwareProtocols)}
              onChange={() => handleSetHospitalProtocols(softwareProtocols)}
            />
          </CheckAllProtocolsDiv>
          <SoftwareProtocolsDiv>{renderSoftwareProtocols(softwareProtocols)}</SoftwareProtocolsDiv>
        </ProtocolsSectionDiv>
      );
    };

    return (
      <ProtocolsDiv>
        {/* Clinic & Home */}
        {(Boolean(clinicProtocols.length) || Boolean(homeProtocols.length)) &&
          renderSoftwareSection("RGS Clinic & RGS Home", clinicProtocols.length ? clinicProtocols : homeProtocols)}
        {/* App */}
        {Boolean(appProtocols.length) && !hasTheHospitalPlus && renderSoftwareSection("RGS App", appProtocols)}
        {/* Web */}
        {Boolean(webProtocols.length) && !hasTheHospitalPlus && renderSoftwareSection("RGS Web", webProtocols)}
        {/* Plus */}
        {Boolean(plusProtocols.length) && renderSoftwareSection("RGS +", plusProtocols)}
      </ProtocolsDiv>
    );
  };

  const renderSubmitButton = () => {
    return (
      <SubmitButtonDiv>
        <SubmitButton onClick={handleAddProtocols}>Save</SubmitButton>
      </SubmitButtonDiv>
    );
  };

  return (
    <>
      <Header />
      <GoBack path={`${pathHomePage}/intermediate-management/${hospitalId}`} />
      {isLoadingHospitalApplications || isLoadingHospitalProtocols ? (
        <Loader />
      ) : (
        <>
          {!Boolean(hospitalSoftwares.length) ? (
            <Title>{`There are no applications for this hospital: ${currentHospitalName}`}</Title>
          ) : (
            <>
              <Title>{`Hospital protocols: ${currentHospitalName}`}</Title>
              {renderProtocolsCheckbox()}
              {renderSubmitButton()}
            </>
          )}
        </>
      )}
    </>
  );
};

export default ProtocolController;
