import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./toastifyStyle.css";
// Redux
import { Provider } from "react-redux";
import generateStore from "./redux/store";
// Components
import Routes from "./routes";

function App() {
  const store = generateStore();
  return (
    <Provider store={store}>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        limit={2}
        rtl={false}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Routes />
    </Provider>
  );
}

export default App;
