import React, { useState, useEffect } from "react";
import { useParams, Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import md5 from "md5";
// Redux
import { useSelector } from "react-redux";
// Constants
import { pathHomePage, WEB_SERVICE_DASHBOARD_ROUTE } from "../../../../../../config/constants";
import { passwordValidation, handleGenerateRandomPassword } from "../../../../../../config/functions";
// Components
import {
  Title,
  ClinicianInfoDiv,
  DataDiv,
  ColumnDataDiv,
  InputDataDiv,
  Label,
  Input,
  Select,
  RelativeButton,
  GeneratePasswordButton,
  SubmitButton,
  SubmitButtonDiv,
  ShowDeleteButtonDiv,
  ShowDeleteButton,
  DeleteDiv,
  DeleteButtonsDiv,
} from "./styles";
import Header from "../../../../../header";
import GoBack from "../../../../../goBack";
import Loader from "../../../../../loader";
import Axios from "../../../../../../config/axios";
// Assets
import VisiblePasswordImg from "../../../../../../assets/btn_visible_password.svg";
import HidePasswordImg from "../../../../../../assets/btn_hide_password.svg";
import GeneratePasswordImg from "../../../../../../assets/generate_password.png";

const EditClinician = () => {
  const [isLoadingClinician, setIsLoadingClinician] = useState(true);
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [visiblePassword, setVisiblePassword] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [role, setRole] = useState("");
  const [roleError, setRoleError] = useState(false);
  const [firstLoginToMims, setFirstLoginToMims] = useState("");
  const [firstLoginToMimsError, setFirstLoginToMimsError] = useState(false);
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState(false);
  const [surname1, setSurname1] = useState("");
  const [surname1Error, setSurname1Error] = useState(false);
  const [surname2, setSurname2] = useState("");
  const [surname2Error, setSurname2Error] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState(false);
  const [clinicianVersion, setClinicianVersion] = useState(false);
  const [showDeleteView, setShowDeleteView] = useState(false);

  const [redirectToCliniciansManagement, setRedirectToCliniciansManagement] = useState(false);
  const { hospitalId, clinicianId } = useParams();
  const isLoggedIn = useSelector(({ user }) => user.isLoggedIn);
  const environment = useSelector(({ database }) => database.environment);

  // Fetching clinician data by id
  useEffect(() => {
    if (isLoggedIn && environment !== "") {
      Axios("get", `${WEB_SERVICE_DASHBOARD_ROUTE}/clinician/get-clinician-by-id/${environment}/${clinicianId}`)
        .then(({ data }) => {
          const clinicianData = data[0];
          setUser(clinicianData.CLINICIAN_USER);
          setRole(clinicianData.ROLE);
          setFirstLoginToMims(clinicianData.FIRST_LOGIN_TO_MIMS);
          setName(clinicianData.NAME ? clinicianData.NAME : "");
          setSurname1(clinicianData.SURNAME1 ? clinicianData.SURNAME1 : "");
          setSurname2(clinicianData.SURNAME2 ? clinicianData.SURNAME2 : "");
          setPhone(clinicianData.PHONE ? clinicianData.PHONE : "");
          setEmail(clinicianData.EMAIL ? clinicianData.EMAIL : "");
          setClinicianVersion(clinicianData.VERSION + 1);
          setIsLoadingClinician(false);
        })
        .catch((error) => {
          console.log("endpoint to get clinician by id: ", error);
        });
    }
  }, [isLoggedIn, environment, clinicianId]);

  const handleEditClinician = (e) => {
    e.preventDefault();
    if (password !== "" && !passwordValidation(password)) {
      setPasswordError(true);
      return toast.error(
        "La contraseña debe tener al menos: 8 caracteres, una letra mayúscula, una letra minúscula, un dígito, un carácter especial"
      );
    }
    if (role === "") {
      setRoleError(true);
      return toast.error("Debe seleccionar un rol de usuario");
    }
    if (firstLoginToMims === "") {
      setFirstLoginToMimsError(true);
      return toast.error("Debe seleccionar el cambio de contraseña");
    }
    let clinicianData = {
      ROLE: role,
      NAME: name === "" ? null : name,
      SURNAME1: surname1 === "" ? null : surname1,
      SURNAME2: surname2 === "" ? null : surname2,
      EMAIL: email === "" ? null : email,
      PHONE: phone === "" ? null : phone,
      VERSION: clinicianVersion,
      FIRST_LOGIN_TO_MIMS: firstLoginToMims,
    };
    if (password !== "") {
      clinicianData.PASSWORD = md5(`${user}${password}`);
    }
    Axios("put", `${WEB_SERVICE_DASHBOARD_ROUTE}/clinician/update/${environment}/${clinicianId}`, clinicianData)
      .then(({ data }) => {
        if (data.updatedClinician) {
          toast.success("Profesional editado correctamente");
          setRedirectToCliniciansManagement(true);
        }
      })
      .catch((error) => {
        toast.error("Hubo un error al enviar los datos, intente de nuevo");
        console.log("Endpoint to edit the clinician: ", error);
      });
  };

  const renderEditClinicianForm = () => {
    const handleSetPasswordVisibility = (e) => {
      e.preventDefault();
      setVisiblePassword(!visiblePassword);
    };

    const handleSetGenerateRandomPassword = (e) => {
      e.preventDefault();
      setPassword(handleGenerateRandomPassword());
    };

    const onChangeInput = (e, setValue, setValueError) => {
      setValue(e.target.value);
      setValueError(false);
    };

    return (
      <ClinicianInfoDiv>
        <DataDiv>
          <ColumnDataDiv>
            <InputDataDiv>
              <Label htmlFor="clinicianUser" fontWeight="bold">
                Usuario *
              </Label>
              <Input type="text" id="clinicianUser" value={user} disabled />
            </InputDataDiv>
            <InputDataDiv>
              <Label htmlFor="password" fontWeight="bold">
                Contraseña *
              </Label>
              <Input
                type={visiblePassword ? "text" : "password"}
                placeholder="*******"
                id="password"
                value={password}
                onChange={(e) => onChangeInput(e, setPassword, setPasswordError)}
                error={passwordError}
              />
              <RelativeButton onClick={handleSetPasswordVisibility}>
                <img src={visiblePassword ? HidePasswordImg : VisiblePasswordImg} alt="icon-password" />
              </RelativeButton>
              <GeneratePasswordButton onClick={handleSetGenerateRandomPassword}>
                <img src={GeneratePasswordImg} alt="icon-generate-password" />
              </GeneratePasswordButton>
            </InputDataDiv>
            <InputDataDiv>
              <Label htmlFor="name" fontWeight="bold">
                Nombre
              </Label>
              <Input type="text" id="name" value={name} onChange={(e) => onChangeInput(e, setName, setNameError)} error={nameError} />
            </InputDataDiv>
            <InputDataDiv>
              <Label htmlFor="surname1" fontWeight="bold">
                Primer apellido
              </Label>
              <Input
                type="text"
                id="surname1"
                value={surname1}
                onChange={(e) => onChangeInput(e, setSurname1, setSurname1Error)}
                error={surname1Error}
              />
            </InputDataDiv>
            <InputDataDiv>
              <Label htmlFor="surname2" fontWeight="bold">
                Segundo apellido
              </Label>
              <Input
                type="text"
                id="surname2"
                value={surname2}
                onChange={(e) => onChangeInput(e, setSurname2, setSurname2Error)}
                error={surname2Error}
              />
            </InputDataDiv>
          </ColumnDataDiv>
          <ColumnDataDiv>
            <InputDataDiv>
              <Label htmlFor="role" fontWeight="bold">
                Rol *
              </Label>
              <Select value={role} onChange={(e) => onChangeInput(e, setRole, setRoleError)} error={roleError}>
                <option value={""}></option>
                <option value={"THERAPIST"}>THERAPIST</option>
                <option value={"ADMIN"}>ADMIN</option>
                <option value={"SUPERADMIN"}>SUPER ADMIN</option>
              </Select>
            </InputDataDiv>
            <InputDataDiv>
              <Label htmlFor="role" fontWeight="bold">
                Cambio de contraseña *
              </Label>
              <Select
                value={firstLoginToMims}
                onChange={(e) => onChangeInput(e, setFirstLoginToMims, setFirstLoginToMimsError)}
                error={firstLoginToMimsError}
              >
                <option value={""}></option>
                <option value={0}>No</option>
                <option value={1}>Sí</option>
              </Select>
            </InputDataDiv>
            <InputDataDiv>
              <Label htmlFor="phone" fontWeight="bold">
                Teléfono
              </Label>
              <Input type="text" id="phone" value={phone} onChange={(e) => onChangeInput(e, setPhone, setPhoneError)} error={phoneError} />
            </InputDataDiv>
            <InputDataDiv>
              <Label htmlFor="email" fontWeight="bold">
                Correo
              </Label>
              <Input type="text" id="email" value={email} onChange={(e) => onChangeInput(e, setEmail, setEmailError)} error={emailError} />
            </InputDataDiv>
          </ColumnDataDiv>
        </DataDiv>
        <SubmitButtonDiv>
          <SubmitButton onClick={handleEditClinician}>Guardar</SubmitButton>
        </SubmitButtonDiv>
      </ClinicianInfoDiv>
    );
  };

  const handleSetShowDeleteView = (e) => {
    e.preventDefault();
    setShowDeleteView(!showDeleteView);
  };

  const renderDeleteButton = () => {
    return (
      <ShowDeleteButtonDiv>
        <ShowDeleteButton onClick={handleSetShowDeleteView}>Eliminar profesional</ShowDeleteButton>
      </ShowDeleteButtonDiv>
    );
  };

  const renderDeleteView = () => {
    const handleDeleteClinician = () => {
      Axios("put", `${WEB_SERVICE_DASHBOARD_ROUTE}/clinician/delete/${environment}/${clinicianId}/${clinicianVersion}`)
        .then(({ data }) => {
          if (data.deletedPatient) {
            toast.success("Profesional eliminado correctamente");
            setRedirectToCliniciansManagement(true);
          }
        })
        .catch((error) => {
          toast.error("Hubo un error al enviar los datos, intente de nuevo");
          console.log("endpoint to delete the clinician: ", error);
        });
    };

    return (
      <DeleteDiv>
        <p>¿Estás seguro(a) de que quieres eliminar al profesional?</p>
        <DeleteButtonsDiv>
          <SubmitButton onClick={handleSetShowDeleteView}>Regresar</SubmitButton>
          <ShowDeleteButton onClick={handleDeleteClinician}>Eliminar profesional</ShowDeleteButton>
        </DeleteButtonsDiv>
      </DeleteDiv>
    );
  };

  if (redirectToCliniciansManagement) {
    return <Redirect to={`${pathHomePage}/clinicians-by-hospital/${hospitalId}`} />;
  }

  return (
    <>
      <Header />
      <GoBack path={`${pathHomePage}/clinicians-by-hospital/${hospitalId}`} />
      {isLoadingClinician ? (
        <Loader />
      ) : (
        <>
          <Title>{showDeleteView ? "Eliminar profesional" : "Editar profesional"}</Title>
          {showDeleteView ? (
            renderDeleteView()
          ) : (
            <>
              {renderEditClinicianForm()} {renderDeleteButton()}
            </>
          )}
        </>
      )}
    </>
  );
};

export default EditClinician;
