import styled from "styled-components";

export const Title = styled.h2`
  margin: ${({ margin }) => margin && margin};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "26px")};
  text-align: center;
`;
export const TableContainer = styled.div`
  margin: 0 auto;
  width: 800px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const TableTitle = styled.p`
  width: ${({ width }) => (width ? width : "200px")};
  font-size: 16px;
  font-weight: bold;
  text-align: center;
`;
