import React from "react";
// Components
import VerifySession from "../../config/verifySession";
import HospitalsData from "../../components/hospitalsByProject/sections/hospitalsData";

const HospitalsDataView = () => (
  <>
    {VerifySession()}
    <HospitalsData />
  </>
);

export default HospitalsDataView;
