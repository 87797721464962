import React from "react";
// Components
import VerifySession from "../../../config/verifySession";
import AdminValidation from "../../../config/adminValidation";
import EditHospitalComponent from "../../../components/protocolsManagement/sections/editProtocol";

const EditProtocolView = () => (
  <>
    {VerifySession()}
    {AdminValidation()}
    <EditHospitalComponent />
  </>
);

export default EditProtocolView;
