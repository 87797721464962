import styled from "styled-components";

export const AdherenceProgressBarDiv = styled.div`
  width: 205px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  @media (max-width: 750px) {
    margin-bottom: 20px;
  }
`;
export const Title = styled.p`
  margin: ${({ margin }) => margin};
  font-size: 16px;
  color: #000;
  text-align: center;
  @media (max-width: 910px) {
    font-size: 14px;
  }
  @media (max-width: 490px) {
    font-size: 12px;
  }
`;
export const Value = styled.span`
  font-size: 16px;
  color: #000;
  font-weight: 600;
  text-align: center;
  @media (max-width: 910px) {
    font-size: 14px;
  }
`;
