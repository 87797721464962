import styled from "styled-components";
import { patientCreationPeriodColor, primaryColor } from "../../config/constants";
import ReactTooltip from "react-tooltip";

export const Title = styled.h2`
  margin: 30px 0;
  font-size: 26px;
  text-align: center;
`;
export const Select = styled.select`
  min-width: 150px;
  font-size: 16px;
`;
export const PeriodContainer = styled.div`
  margin: 0 auto 20px;
  width: 100%;
  max-width: 900px;
  display: flex;
  align-items: end;
`;
export const EachPeriodFilterDiv = styled.div`
  margin: 0 30px;
`;
export const Table = styled.div`
  margin: 0 0 10px 200px;
  display: -webkit-inline-box;
`;
export const TableMonth = styled.p`
  margin: 0 10px;
  width: 350px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
`;
export const PatientsContainer = styled.div`
  margin: 0 0 0 200px;
  padding: 10px 0;
  width: max-content;
  display: flex;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: rgb(0 0 0 / 10%) 0px 5px 15px;
`;
export const FilteredPatients = styled.div`
  margin: 0 10px;
  width: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const TotalPatientsPerMonthDiv = styled.div`
  width: 50%;
  display: flex;
  justify-content: space-around;
`;
export const TotalPatientsPerMonthText = styled.p`
  width: 50px;
  font-weight: bold;
  text-align: center;
`;
export const ReactTooltipStyled = styled(ReactTooltip)`
  max-width: 500px;
  border-radius: 5px !important;
  padding: 15px !important;
`;
export const PatientField = styled.p`
  margin: 5px 0;
  width: 100%;
  font-size: 16px;
  text-align: center;
  border-radius: 10px;
  background-color: ${({ isCreationPeriod, isIntoTheTimeWindow }) =>
    isCreationPeriod ? patientCreationPeriodColor : isIntoTheTimeWindow ? primaryColor : "transparent"};
`;
