import React, { useState, useEffect } from "react";
import moment from "moment";
// Redux
import { useSelector } from "react-redux";
// Constants - Functions
import { WEB_SERVICE_DASHBOARD_ROUTE } from "../../config/constants";
// Components
import {
  Title,
  TableHeader,
  TableTitle,
  PatientDevicesContainer,
  PatientDeviceField,
  HospitalsFilterDiv,
  CheckboxInput,
  CheckboxLabel,
} from "./styles";
import Header from "../header";
import Loader from "../loader";
import UseFetch from "../../config/useFetch";

const PatientsDevices = () => {
  const [isLoadingPatientsDevices, setIsLoadingPatientsDevices] = useState(true);
  const [patientsDevices, setPatientsDevices] = useState([]);
  const [isUkrainianHospitalsChecked, setIsUkrainianHospitalsChecked] = useState(false);

  const [isSortedByHospital, setIsFilteredByHospital] = useState(true);
  const [isSortedByPatient, setIsFilteredByPatient] = useState(false);
  const [isSortedByLoginDate, setIsFilteredByLoginDate] = useState(false);
  const [isSortedByPhoneModel, setIsFilteredByPhoneModel] = useState(false);
  const [isSortedByAppVersion, setIsFilteredByAppVersion] = useState(false);
  const [isSortedByDeleteTime, setIsFilteredByDeleteTime] = useState(false);

  const isLoggedIn = useSelector(({ user }) => user.isLoggedIn);
  const environment = useSelector(({ database }) => database.environment);

  // Fetching patients devices
  useEffect(() => {
    setIsLoadingPatientsDevices(true);
    const getPatientsDevicesSignal = new AbortController();

    if (isLoggedIn && environment) {
      const body = {
        addUkrainianHospitals: isUkrainianHospitalsChecked,
      };
      UseFetch("post", `${WEB_SERVICE_DASHBOARD_ROUTE}/patient/get-patients-devices/${environment}`, getPatientsDevicesSignal, body)
        .then(({ data }) => {
          // Filtering by Patient and Phone model
          let filteredPatientsByPhoneModel = [];
          data.forEach((login) => {
            if (login.LOGIN_DATE === null) login.LOGIN_DATE = "N/A";
            if (login.PHONE_MODEL === null) login.PHONE_MODEL = "N/A";
            if (login.APP_VERSION === null) login.APP_VERSION = "N/A";
            if (login.DELETE_TIME === null) login.DELETE_TIME = "Activo";
            const isSomeLogin = filteredPatientsByPhoneModel.find(
              (insertedLogin) => insertedLogin.PATIENT_USER === login.PATIENT_USER && insertedLogin.PHONE_MODEL === login.PHONE_MODEL
            );
            if (!isSomeLogin) filteredPatientsByPhoneModel.push(login);
          });
          // Sorting by hospital
          const sortedPatientsDevicesByHospital = filteredPatientsByPhoneModel.sort((a, b) => {
            return a.HOSPITAL_NAME.toLowerCase() < b.HOSPITAL_NAME.toLowerCase() ? -1 : 1;
          });
          setPatientsDevices(sortedPatientsDevicesByHospital);
          setIsLoadingPatientsDevices(false);
        })
        .catch((error) => {
          console.log("Get patient devices: ", error);
        });
    }

    return () => {
      setPatientsDevices([]);
      setIsLoadingPatientsDevices(true);
      if (getPatientsDevicesSignal) {
        getPatientsDevicesSignal.abort();
      }
    };
  }, [isLoggedIn, environment, isUkrainianHospitalsChecked]);

  const clearFilters = (id) => {
    id !== "hospital" && setIsFilteredByHospital(false);
    id !== "patient" && setIsFilteredByPatient(false);
    id !== "loginDate" && setIsFilteredByLoginDate(false);
    id !== "phoneModel" && setIsFilteredByPhoneModel(false);
    id !== "appVersion" && setIsFilteredByAppVersion(false);
    id !== "deleteTime" && setIsFilteredByDeleteTime(false);
  };

  const handleReversePatientDevices = () => {
    setPatientsDevices([...patientsDevices].reverse());
  };

  const sortByHospital = (e) => {
    // Removing other filters
    clearFilters(e.target.id);
    // Reversing the stored array of patients devices
    if (isSortedByHospital) return handleReversePatientDevices();
    // Setting hospital filter
    setIsFilteredByHospital(true);
    const sortedPatientsDevicesByHospital = patientsDevices.sort((a, b) => {
      return a.HOSPITAL_NAME.toLowerCase() < b.HOSPITAL_NAME.toLowerCase() ? -1 : 1;
    });
    return setPatientsDevices(sortedPatientsDevicesByHospital);
  };

  const sortByPatient = (e) => {
    // Removing other filters
    clearFilters(e.target.id);
    // Reversing the stored array of patients devices
    if (isSortedByPatient) return handleReversePatientDevices();
    // Setting hospital filter
    setIsFilteredByPatient(true);
    const sortedPatientsDevicesByPatient = patientsDevices.sort((a, b) => {
      return a.PATIENT_USER.toLowerCase() < b.PATIENT_USER.toLowerCase() ? -1 : 1;
    });
    return setPatientsDevices(sortedPatientsDevicesByPatient);
  };

  const sortByLoginDate = (e) => {
    // Removing other filters
    clearFilters(e.target.id);
    // Reversing the stored array of patients devices
    if (isSortedByLoginDate) return handleReversePatientDevices();
    // Setting hospital filter
    setIsFilteredByLoginDate(true);
    const sortingByValidLoginDate = patientsDevices.sort((a, b) => {
      return a.LOGIN_DATE.toLowerCase() < b.LOGIN_DATE.toLowerCase() ? -1 : 1;
    });
    const sortingByLoginDate = sortingByValidLoginDate.sort((a, b) => {
      return new Date(a.LOGIN_DATE) > new Date(b.LOGIN_DATE) ? -1 : 0;
    });
    return setPatientsDevices(sortingByLoginDate);
  };

  const sortByPhoneModel = (e) => {
    // Removing other filters
    clearFilters(e.target.id);
    // Reversing the stored array of patients devices
    if (isSortedByPhoneModel) return handleReversePatientDevices();
    // Setting hospital filter
    setIsFilteredByPhoneModel(true);
    const sortingByValidPhoneModel = patientsDevices.sort((a) => {
      return a.PHONE_MODEL !== "N/A" ? -1 : 1;
    });
    const sortingByPhoneModel = sortingByValidPhoneModel.sort((a, b) => {
      return a.PHONE_MODEL.toLowerCase() < b.PHONE_MODEL.toLowerCase() && a.PHONE_MODEL !== "N/A" ? -1 : 0;
    });
    return setPatientsDevices(sortingByPhoneModel);
  };

  const sortByAppVersion = (e) => {
    // Removing other filters
    clearFilters(e.target.id);
    // Reversing the stored array of patients devices
    if (isSortedByAppVersion) return handleReversePatientDevices();
    // Setting hospital filter
    setIsFilteredByAppVersion(true);
    const sortingByValidAppVersion = patientsDevices.sort((a) => {
      return a.PHONE_MODEL !== "N/A" ? -1 : 1;
    });
    const sortingByAppVersion = sortingByValidAppVersion.sort((a, b) => {
      return a.APP_VERSION.toLowerCase() > b.APP_VERSION.toLowerCase() && a.PHONE_MODEL !== "N/A" ? -1 : 0;
    });
    return setPatientsDevices(sortingByAppVersion);
  };

  const sortByDeleteTime = (e) => {
    // Removing other filters
    clearFilters(e.target.id);
    // Reversing the stored array of patients devices
    if (isSortedByDeleteTime) return handleReversePatientDevices();
    // Setting hospital filter
    setIsFilteredByDeleteTime(true);
    const sortingByValidDeleteTime = patientsDevices.sort((a) => {
      return a.DELETE_TIME !== "Activo" ? -1 : 1;
    });
    const sortingByDeleteTime = sortingByValidDeleteTime.sort((a, b) => {
      return new Date(a.DELETE_TIME) > new Date(b.DELETE_TIME) ? -1 : 0;
    });
    return setPatientsDevices(sortingByDeleteTime);
  };

  const renderAddUkrainianHospitalsCheckbox = () => {
    const setUkrainianHospitals = () => {
      setIsUkrainianHospitalsChecked(!isUkrainianHospitalsChecked);
    };

    return (
      <HospitalsFilterDiv>
        <CheckboxInput
          type="checkbox"
          id="addUkrainianHospitalsCheckbox"
          checked={isUkrainianHospitalsChecked}
          onChange={setUkrainianHospitals}
        />
        <CheckboxLabel htmlFor="addUkrainianHospitalsCheckbox">Add Ukrainian hospitals</CheckboxLabel>
      </HospitalsFilterDiv>
    );
  };

  const renderPatientsDevicesHeader = () => {
    return (
      <TableHeader>
        <TableTitle id="hospital" onClick={sortByHospital} isMarked={isSortedByHospital}>
          Hospital
        </TableTitle>
        <TableTitle id="patient" onClick={sortByPatient} isMarked={isSortedByPatient} width="220px">
          Patient
        </TableTitle>
        <TableTitle id="loginDate" onClick={sortByLoginDate} isMarked={isSortedByLoginDate} width="170px">
          Access date
        </TableTitle>
        <TableTitle id="phoneModel" onClick={sortByPhoneModel} isMarked={isSortedByPhoneModel} width="200px">
          Device model
        </TableTitle>
        <TableTitle id="appVersion" onClick={sortByAppVersion} isMarked={isSortedByAppVersion} width="170px">
          RGS app version
        </TableTitle>
        <TableTitle id="deleteTime" onClick={sortByDeleteTime} isMarked={isSortedByDeleteTime} width="170px">
          Date of deletion
        </TableTitle>
      </TableHeader>
    );
  };

  const renderPatientsDevices = () => {
    return patientsDevices.map((patientDevice, i) => {
      const formattedLoginDate = moment(patientDevice.LOGIN_DATE, "YYYY-MM-DD").isValid()
        ? moment(patientDevice.LOGIN_DATE, "YYYY-MM-DD").format("DD-MM-YYYY, HH:mm:ss")
        : patientDevice.LOGIN_DATE;
      const formattedDeleteTime = moment(patientDevice.DELETE_TIME, "YYYY-MM-DD").isValid()
        ? moment(patientDevice.DELETE_TIME).format("DD-MM-YYYY, HH:mm:ss")
        : patientDevice.DELETE_TIME;
      return (
        <PatientDevicesContainer key={i}>
          <PatientDeviceField>{patientDevice.HOSPITAL_NAME}</PatientDeviceField>
          <PatientDeviceField width="220px">{patientDevice.PATIENT_USER}</PatientDeviceField>
          <PatientDeviceField width="170px">{formattedLoginDate}</PatientDeviceField>
          <PatientDeviceField width="200px">{patientDevice.PHONE_MODEL}</PatientDeviceField>
          <PatientDeviceField width="170px">{patientDevice.APP_VERSION}</PatientDeviceField>
          <PatientDeviceField width="170px">{formattedDeleteTime}</PatientDeviceField>
        </PatientDevicesContainer>
      );
    });
  };

  return (
    <>
      <Header />
      <Title>Patient devices</Title>
      {renderAddUkrainianHospitalsCheckbox()}
      {isLoadingPatientsDevices ? (
        <Loader />
      ) : (
        <>
          {patientsDevices.length === 0 ? (
            <Title>There are no patient devices</Title>
          ) : (
            <>
              {renderPatientsDevicesHeader()}
              {renderPatientsDevices()}
            </>
          )}
        </>
      )}
    </>
  );
};

export default PatientsDevices;
