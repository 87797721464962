import React from "react";
// Constants
import { pathHomePage } from "../../config/constants";
// Components
import { ProjectsView, Title, ProjectsContainer, ProjectLink } from "./styles";
import Header from "../header";

const Projects = () => {
  return (
    <>
      <Header />
      <ProjectsView>
        <Title>Projects</Title>
        <ProjectsContainer>
          <ProjectLink to={`${pathHomePage}/hospitals-data/PHRASE`}>PHRASE</ProjectLink>
          <ProjectLink to={`${pathHomePage}/hospitals-data/AISN`}>AISN</ProjectLink>
          <ProjectLink to={`${pathHomePage}/hospitals-data/ROMANIAN_CAMPAIGN`}>Romanian Campaign</ProjectLink>
        </ProjectsContainer>
      </ProjectsView>
    </>
  );
};

export default Projects;
