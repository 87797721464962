import React from "react";
import { useParams } from "react-router-dom";
// Redux
import { useSelector } from "react-redux";
// Constants
import { pathHomePage } from "../../../../config/constants";
// Components
import { Title, LinksDiv, ManageLink } from "./styles";
import Header from "../../../header";
import GoBack from "../../../goBack";
import Loader from "../../../loader";

const IntermediateManagement = () => {
  const { hospitalId } = useParams();
  const currentHospitalName = useSelector(({ hospital }) => hospital.currentName);

  const renderLinks = () => {
    return (
      <LinksDiv>
        <ManageLink to={`${pathHomePage}/clinicians-by-hospital/${hospitalId}`}>Professionals</ManageLink>
        <ManageLink to={`${pathHomePage}/applications-by-hospital/${hospitalId}`}>Applications</ManageLink>
        <ManageLink to={`${pathHomePage}/protocols-by-hospital/${hospitalId}`}>Protocols</ManageLink>
        <ManageLink to={`${pathHomePage}/stations-by-hospital/${hospitalId}`}>Statistics</ManageLink>
      </LinksDiv>
    );
  };

  return (
    <>
      <Header />
      <GoBack path={`${pathHomePage}/hospital-management`} />
      {currentHospitalName === "" ? (
        <Loader />
      ) : (
        <>
          <Title>{`Manage hospital: ${currentHospitalName}`}</Title>
          {renderLinks()}
        </>
      )}
    </>
  );
};

export default IntermediateManagement;
