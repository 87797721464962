import React, { useState, useEffect } from "react";
// Redux
import { useSelector } from "react-redux";
// Constants
import { pathHomePage, WEB_SERVICE_DASHBOARD_ROUTE, applications } from "../../config/constants";
// Components
import {
  Title,
  CreateProtocolDiv,
  CreateProtocolLink,
  TableContainer,
  TableTitle,
  ProtocolContainer,
  ProtocolName,
  ProtocolInfo,
  EditProtocolLink,
  EditProtocolImg,
} from "./styles";
import Header from "../header";
import Loader from "../loader";
import UseFetch from "../../config/useFetch";
// Assets
import EditIcon from "../../assets/edit_icon.svg";

const ProtocolsManagement = () => {
  const [isLoadingProtocols, setIsLoadingProtocols] = useState(true);
  const [protocols, setProtocols] = useState([]);
  const isLoggedIn = useSelector(({ user }) => user.isLoggedIn);
  const environment = useSelector(({ database }) => database.environment);

  // Fetching hospitals data
  useEffect(() => {
    const getProtocolsSignal = new AbortController();

    if (isLoggedIn && environment) {
      UseFetch("get", `${WEB_SERVICE_DASHBOARD_ROUTE}/protocol/list/${environment}`, getProtocolsSignal)
        .then(({ data }) => {
          setProtocols(data);
          setIsLoadingProtocols(false);
        })
        .catch((error) => {
          console.log("Get protocols: ", error);
        });
    }

    return () => {
      setProtocols([]);
      setIsLoadingProtocols(true);
      if (getProtocolsSignal) {
        getProtocolsSignal.abort();
      }
    };
  }, [isLoggedIn, environment]);

  const renderTableHeader = () => {
    return (
      <TableContainer>
        <TableTitle>Protocol</TableTitle>
        <TableTitle width="55px">Edit</TableTitle>
        <TableTitle>Description</TableTitle>
        <TableTitle>Type</TableTitle>
        <TableTitle>Applications</TableTitle>
      </TableContainer>
    );
  };

  const renderProtocolos = () => {
    return protocols.map((protocol) => {
      const protocolId = protocol.PROTOCOL_ID;
      let formattedSoftwares = "";
      protocol.SOFTWARES.forEach((software, softwareIndex) => {
        switch (software.SOFTWARE_ID) {
          case applications.clinicId:
            if (softwareIndex === 0) {
              formattedSoftwares += "RGS Clinic";
            } else {
              formattedSoftwares += " - RGS Clinic";
            }
            break;
          case applications.homeId:
            if (softwareIndex === 0) {
              formattedSoftwares += "RGS Home";
            } else {
              formattedSoftwares += " - RGS Home";
            }
            break;
          case applications.webId:
            if (softwareIndex === 0) {
              formattedSoftwares += "RGS Web";
            } else {
              formattedSoftwares += " - RGS Web";
            }
            break;
          case applications.appId:
            if (softwareIndex === 0) {
              formattedSoftwares += "RGS App";
            } else {
              formattedSoftwares += " - RGS App";
            }
            break;
          case applications.wearId:
            if (softwareIndex === 0) {
              formattedSoftwares += "RGS Wear";
            } else {
              formattedSoftwares += " - RGS Wear";
            }
            break;
          case applications.icuId:
            if (softwareIndex === 0) {
              formattedSoftwares += "RGS ICU";
            } else {
              formattedSoftwares += " - RGS ICU";
            }
            break;
          default:
            break;
        }
      });
      return (
        <ProtocolContainer key={protocolId}>
          <ProtocolName>{protocol.NAME_KEY}</ProtocolName>
          <ProtocolInfo width="55px">
            <EditProtocolLink to={`${pathHomePage}/edit-protocol/${protocolId}`}>
              <EditProtocolImg src={EditIcon} alt="edit-icon" />
            </EditProtocolLink>
          </ProtocolInfo>
          <ProtocolInfo>{protocol.DESCRIPTION_KEY}</ProtocolInfo>
          <ProtocolInfo>{protocol.PROTOCOL_TYPE}</ProtocolInfo>
          <ProtocolInfo>{formattedSoftwares}</ProtocolInfo>
        </ProtocolContainer>
      );
    });
  };

  return (
    <>
      <Header />
      {isLoadingProtocols ? (
        <Loader />
      ) : (
        <>
          <Title>Manage protocols</Title>
          <CreateProtocolDiv>
            <CreateProtocolLink to={`${pathHomePage}/create-protocol`}>Create new protocol</CreateProtocolLink>
          </CreateProtocolDiv>
          {protocols.length === 0 ? (
            <Title>There are no protocols created</Title>
          ) : (
            <>
              {renderTableHeader()}
              {renderProtocolos()}
            </>
          )}
        </>
      )}
    </>
  );
};

export default ProtocolsManagement;
