// ----- State -----
const initialState = {
  environment: "",
};

// ----- Types -----
// LOGIN
const SETTING_DATABASE_ENVIRONMENT = "SETTING_DATABASE_ENVIRONMENT";

// ----- Reducer -----
export default function reducer(state = initialState, action) {
  switch (action.type) {
    // LOGIN
    case SETTING_DATABASE_ENVIRONMENT:
      return {
        ...state,
        environment: action.payload.environment,
      };
    default:
      return state;
  }
}

// ----- Actions -----
// LOGIN
export const setDataBaseEnvironment = (environment) => (dispatch) => {
  dispatch({
    type: SETTING_DATABASE_ENVIRONMENT,
    payload: {
      environment,
    },
  });
};
