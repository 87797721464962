import { createStore, combineReducers, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import persistState from "redux-localstorage";
// Each Reducer
import userReducer from "./userDucks";
import databaseReducer from "./dbDucks";
import hospitalReducer from "./hospitalDucks";

const rootReducer = combineReducers({
  user: userReducer,
  database: databaseReducer,
  hospital: hospitalReducer,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function generateStore() {
  const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk), persistState()));
  return store;
}
