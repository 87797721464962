import styled from "styled-components";
import { primaryColor, primaryColorHover } from "../../../../config/constants";

export const Title = styled.h2`
  margin: 0 0 30px;
  font-size: 26px;
  text-align: center;
`;
export const CheckboxInput = styled.input`
  cursor: pointer;
`;
export const CheckboxLabel = styled.label`
  margin: 0 0 0 10px;
  font-size: ${({ fontSize }) => fontSize};
  cursor: pointer;
`;
export const TitleSectionDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
export const SoftwareTitle = styled.p`
  font-size: 18px;
  font-weight: bold;
`;
export const ProtocolsDiv = styled.div`
  margin: 0 auto;
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;
export const ProtocolsSectionDiv = styled.div`
  margin: 10px;
  padding: 10px 0 10px 10px;
  width: 100%;
  max-width: 485px;
  min-width: 450px;
  max-height: 700px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  background-color: #fff;
  overflow: auto;
  box-shadow: rgb(0 0 0 / 15%) 0px 5px 15px;
`;
export const CheckAllProtocolsDiv = styled.div`
  margin-bottom: 10px;
  width: 100%;
  height: 20px;
`;
export const CheckboxAllProtocolsInput = styled.input`
  width: 20px;
  height: 20px;
  cursor: pointer;
`;
export const SoftwareProtocolsDiv = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;
export const EachProtocolDiv = styled.div`
  margin: 7px 0;
  padding-right: 2px;
  width: 50%;
  height: 18px;
`;
export const SubmitButtonDiv = styled.div`
  margin: 30px 0;
  width: 100%;
  display: flex;
  justify-content: center;
`;
export const SubmitButton = styled.button`
  padding: 10px 20px;
  width: 150px;
  color: #fff;
  background-color: ${primaryColor};
  outline: none;
  border: none;
  text-decoration: none;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: rgb(0 0 0 / 15%) 0px 5px 15px;
  transition: all 0.15s ease-in-out;
  &:hover {
    background-color: ${primaryColorHover};
  }
`;
