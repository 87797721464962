import React, { useState, useEffect, useRef } from "react";
import { Chart, registerables } from "chart.js";
// Components
import {
  GraphicContainer,
  GraphicRotateDiv,
  GraphicTitle,
  AxisAndGraphicDiv,
  AxisYSizeDiv,
  AxisY,
  GraphicSizeDiv,
  AxisX,
  FilterAxisYDiv,
  FilterAxisYInput,
} from "./styles";

const Graphic = ({ Title, Type, data, axisY, axisX, width = false, filterAxisY }) => {
  const [maxAxisYStaticValue, setMaxAxisYStaticValue] = useState(0);
  const [maxAxisYValue, setMaxAxisYValue] = useState(0);
  const chartRef = useRef(null);

  Chart.register(...registerables);

  useEffect(() => {
    if (chartRef.current && filterAxisY) {
      let maxAxisYCount = 0;

      for (const hospital of data.datasets) {
        for (const inputValue of hospital.data) {
          if (inputValue > maxAxisYCount) maxAxisYCount = inputValue;
        }
      }

      const roundedMaxAxisYValue = Math.ceil(maxAxisYCount / 100) * 100;

      setMaxAxisYStaticValue(roundedMaxAxisYValue);
      setMaxAxisYValue(roundedMaxAxisYValue);
    }
  }, [filterAxisY, data]);

  const renderScales = () => {
    let scales = {
      y: {
        beginAtZero: true,
      },
      x: {
        beginAtZero: true,
      },
    };

    if (filterAxisY && maxAxisYValue) scales.y.max = maxAxisYValue;

    return scales;
  };

  const renderFilterAxisY = () => {
    const changeMaxAxisYValue = (e) => {
      const newMaxValue = Number(e.target.value);
      setMaxAxisYValue(newMaxValue ? newMaxValue : 1);
    };

    return (
      <FilterAxisYDiv>
        <p>Limitar eje Y:</p>
        <FilterAxisYInput type="range" value={maxAxisYValue} min={0} max={maxAxisYStaticValue} onChange={changeMaxAxisYValue} />
        <p>{maxAxisYValue}</p>
      </FilterAxisYDiv>
    );
  };

  return (
    <GraphicContainer width={width ? width : "90%"}>
      <GraphicRotateDiv>
        <GraphicTitle>{Title}</GraphicTitle>
        {filterAxisY && renderFilterAxisY()}
        <AxisAndGraphicDiv>
          <AxisYSizeDiv>
            <AxisY>{axisY}</AxisY>
          </AxisYSizeDiv>
          <GraphicSizeDiv>
            <Type
              ref={chartRef}
              data={data}
              options={{
                clip: true,
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    display: true,
                    labels: {
                      usePointStyle: true,
                      pointStyle: "circle",
                    },
                  },
                },
                scales: renderScales(),
              }}
            />
          </GraphicSizeDiv>
        </AxisAndGraphicDiv>
        <AxisX>{axisX}</AxisX>
      </GraphicRotateDiv>
    </GraphicContainer>
  );
};

export default Graphic;
