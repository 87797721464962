import React, { useState, useEffect } from "react";
import moment from "moment";
// Redux
import { useSelector } from "react-redux";
// Constants - Functions
import { WEB_SERVICE_DASHBOARD_ROUTE, monthsToCheckPatients } from "../../config/constants";
import { setMonth, setCurrentPeriodOfMonthsForStrack, renderMonthsOptions, renderYearsOptions } from "../../config/functions";
// Components
import {
  Title,
  Select,
  PeriodContainer,
  EachPeriodFilterDiv,
  Table,
  TableMonth,
  PatientsContainer,
  FilteredPatients,
  TotalPatientsPerMonthDiv,
  TotalPatientsPerMonthText,
  ReactTooltipStyled,
  PatientField,
} from "./styles";
import Header from "../header";
import Loader from "../loader";
import Axios from "../../config/axios";

const ActivePatients = () => {
  const [isLoadingPatients, setIsLoadingPatients] = useState(true);
  const [patients, setPatients] = useState([]);
  const [filterStartMonth, setFilterStartMonth] = useState(0);
  const [filterStartYear, setFilterStartYear] = useState(0);
  const [filterEndMonth, setFilterEndMonth] = useState(0);
  const [filterEndYear, setFilterEndYear] = useState(0);
  const [currentPeriodFiltered, setCurrentPeriodFiltered] = useState([]);
  const environment = useSelector(({ database }) => database.environment);

  // Fetching patients data
  useEffect(() => {
    if (environment) {
      Axios("get", `${WEB_SERVICE_DASHBOARD_ROUTE}/patient/get-active-patients/${environment}`)
        .then(({ data }) => {
          setPatients(data);
          setIsLoadingPatients(false);
        })
        .catch((error) => {
          console.log("Endpoint to get active patients: ", error);
        });
    }
  }, [environment]);

  // Setting date filter
  useEffect(() => {
    const initialNumberOfMonthsAgo = 5;
    setFilterStartMonth(Number(moment().subtract(initialNumberOfMonthsAgo, "months").format("MM")));
    setFilterStartYear(Number(moment().subtract(initialNumberOfMonthsAgo, "months").format("YYYY")));
    setFilterEndMonth(Number(moment().format("MM")));
    setFilterEndYear(Number(moment().format("YYYY")));
  }, []);

  // Setting current period of months and years
  useEffect(() => {
    setCurrentPeriodOfMonthsForStrack(filterStartMonth, filterStartYear, filterEndMonth, filterEndYear, setCurrentPeriodFiltered);
  }, [filterEndMonth, filterEndYear, filterStartMonth, filterStartYear]);

  const renderPeriodFilter = () => {
    return (
      <PeriodContainer>
        <div>
          <p>Desde</p>
          <div>
            <Select value={filterStartMonth} onChange={(e) => setFilterStartMonth(Number(e.target.value))}>
              {renderMonthsOptions()}
            </Select>
            <Select value={filterStartYear} onChange={(e) => setFilterStartYear(Number(e.target.value))}>
              {renderYearsOptions()}
            </Select>
          </div>
        </div>
        <EachPeriodFilterDiv>
          <p>Hasta</p>
          <div>
            <Select value={filterEndMonth} onChange={(e) => setFilterEndMonth(Number(e.target.value))}>
              {renderMonthsOptions()}
            </Select>
            <Select value={filterEndYear} onChange={(e) => setFilterEndYear(Number(e.target.value))}>
              {renderYearsOptions()}
            </Select>
          </div>
        </EachPeriodFilterDiv>
      </PeriodContainer>
    );
  };

  const renderLastMonths = () => {
    return currentPeriodFiltered.map((date, i) => {
      return (
        <TableMonth key={i}>
          {setMonth(date.month)} {date.year}
        </TableMonth>
      );
    });
  };

  const renderActivePatients = () => {
    return (
      <PatientsContainer>
        {currentPeriodFiltered.map((date, i) => {
          // Filtering patients
          const currentPatientsList = patients
            .filter((patient) => {
              const patientCreationDate = moment(patient.CREATION_TIME);
              const patientDeletionDate = patient.DELETE_TIME ? moment(patient.DELETE_TIME) : null;
              const currentMonth = moment(`${date.year}-${date.month}-01`, "YYYY-MM-DD");

              return (
                patientCreationDate.isSameOrBefore(currentMonth, "month") &&
                patientCreationDate.isSameOrBefore(currentMonth, "year") &&
                (patient.DELETE_TIME === null ||
                  (patientDeletionDate.isSameOrAfter(currentMonth, "month") && patientDeletionDate.isSameOrAfter(currentMonth, "year")))
              );
            })
            .sort((a, b) => {
              return a.CREATION_TIME > b.CREATION_TIME ? -1 : 1;
            });

          const limitedDate = new Date(moment(`${date.year}-${date.month}-01`, "YYYY-MM-DD").subtract(monthsToCheckPatients, "months"));

          const renderTotalNumberOfActivePatients = () => {
            const createdPatientsPerMonth = currentPatientsList.filter((patient) => {
              const patientCreationYear = new Date(patient.CREATION_TIME).getFullYear();
              const patientCreationMonth = new Date(patient.CREATION_TIME).getMonth() + 1;
              return date.year === patientCreationYear && date.month === patientCreationMonth;
            });
            const patientsWith6ActiveMonths = currentPatientsList.filter((patient) => new Date(patient.CREATION_TIME) >= limitedDate);
            const currentPatientsListText = "Pacientes activos";
            const patientsWith3ActiveMonthsText = "Pacientes dentro de los (6) meses";
            const createdPatientsPerMonthText = "Pacientes creados este mes";
            return (
              <>
                <ReactTooltipStyled id="currentPatientsList" place="top" type="dark" effect="solid" delayShow={100} delayHide={100} />
                <TotalPatientsPerMonthDiv>
                  <TotalPatientsPerMonthText
                    data-for="currentPatientsList"
                    data-tip={currentPatientsListText}
                  >{`${currentPatientsList.length}`}</TotalPatientsPerMonthText>
                  <TotalPatientsPerMonthText
                    data-for="currentPatientsList"
                    data-tip={patientsWith3ActiveMonthsText}
                  >{` ${patientsWith6ActiveMonths.length}`}</TotalPatientsPerMonthText>
                  <TotalPatientsPerMonthText
                    data-for="currentPatientsList"
                    data-tip={createdPatientsPerMonthText}
                  >{` ${createdPatientsPerMonth.length}`}</TotalPatientsPerMonthText>
                </TotalPatientsPerMonthDiv>
              </>
            );
          };

          return (
            <FilteredPatients key={i}>
              {renderTotalNumberOfActivePatients()}
              {currentPatientsList.map((patient) => {
                const trainedSeconds = patient.sessions
                  .filter((session) => date.year === session.SESSION_YEAR && date.month === session.SESSION_MONTH)
                  .reduce((totalSeconds, session) => totalSeconds + session.SESSION_SECONDS, 0);

                const patientCreationYear = new Date(patient.CREATION_TIME).getFullYear();
                const patientCreationMonth = new Date(patient.CREATION_TIME).getMonth() + 1;
                const isCreationPeriod = date.year === patientCreationYear && date.month === patientCreationMonth;
                const isIntoTheTimeWindow = new Date(patient.CREATION_TIME) > limitedDate;

                const trainedMinutes = trainedSeconds ? Math.ceil(trainedSeconds / 60) : 0;

                return (
                  <PatientField key={patient.PATIENT_ID} isCreationPeriod={isCreationPeriod} isIntoTheTimeWindow={isIntoTheTimeWindow}>
                    {patient.PATIENT_USER} - {trainedMinutes} - {moment(patient.CREATION_TIME).format("DD/MM/YYYY")}
                  </PatientField>
                );
              })}
            </FilteredPatients>
          );
        })}
      </PatientsContainer>
    );
  };

  return (
    <>
      <Header />
      {isLoadingPatients ? (
        <Loader />
      ) : (
        <>
          <Title>Pacientes activos por mes</Title>
          {renderPeriodFilter()}
          <Table>{renderLastMonths()}</Table>
          {renderActivePatients()}
        </>
      )}
    </>
  );
};

export default ActivePatients;
