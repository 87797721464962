import React, { useState } from "react";
import { Redirect } from "react-router-dom";
// Components
import { GoBackDiv, GoBackLink, GoBackImg } from "./styles";
// Assets
import GoBackImgPath from "../../assets/go_back.svg";

const GoBack = ({ path }) => {
  const [redirect, setRedirect] = useState(false);

  if (redirect) {
    return <Redirect to={path} />;
  }

  return (
    <GoBackDiv>
      <GoBackLink onClick={() => setRedirect(true)}>
        <GoBackImg src={GoBackImgPath} alt="go-back-icon" />
      </GoBackLink>
    </GoBackDiv>
  );
};

export default GoBack;
