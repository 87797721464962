import styled from "styled-components";
import { primaryColor } from "../../config/constants";

export const Title = styled.h2`
  margin: 30px 0;
  font-size: 26px;
  text-align: center;
`;
export const TableHeader = styled.div`
  margin: auto;
  padding: 0 10px;
  width: fit-content;
  display: flex;
`;
export const TableTitle = styled.button`
  padding: 5px 0;
  width: ${({ width }) => (width ? width : "280px")};
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  background: ${({ isMarked }) => (isMarked ? primaryColor : "transparent")};
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
`;
export const PatientDevicesContainer = styled.div`
  margin: 10px auto;
  padding: 15px 10px;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: rgb(0 0 0 / 15%) 0px 5px 15px;
`;
export const PatientDeviceField = styled.p`
  width: ${({ width }) => (width ? width : "280px")};
  font-size: 14px;
  text-align: center;
`;
export const HospitalsFilterDiv = styled.div`
  margin: 0 auto 20px;
  width: 100%;
  max-width: 900px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;
export const CheckboxInput = styled.input`
  cursor: pointer;
`;
export const CheckboxLabel = styled.label`
  margin: 0 10px;
`;
